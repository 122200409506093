import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {QuestionResponse} from './interfaces/question-response';
import {QuestionsResponse} from './interfaces/questions-response';
import {AnswersResponse} from '../answers/interfaces/answers-response';
import {GroupStatsResponseResponse} from '../groups/interfaces/group-stats-response';

@Injectable({
  providedIn: 'root'
})
export class QuestionService {

  constructor(private httpClient: HttpClient) {
  }

  question(id: string): Observable<any> {
    return this.httpClient.get<QuestionResponse>(`${environment.baseUrl}/surveys/questions/${id}`);
  }

  questions(): Observable<any> {
    return this.httpClient.get<QuestionsResponse>(`${environment.baseUrl}/surveys/questions`);
  }

  questionAnswers(id: string): Observable<any> {
    return this.httpClient.get<AnswersResponse>(`${environment.baseUrl}/surveys/answers?question=${id}`);
  }
  
  createQuestion(post: any): Observable<any> {
    return this.httpClient.post<QuestionResponse>(`${environment.baseUrl}/surveys/questions`, post);
  }

  updateQuestion(post: any): Observable<any> {
    return this.httpClient.patch<QuestionResponse>(`${environment.baseUrl}/surveys/questions/${post.id}`, post);
  }

  deleteQuestion(id: string): Observable<any> {

    return this.httpClient.delete<QuestionResponse>(`${environment.baseUrl}/surveys/questions/${id}`);
  }

  getQuestionStats(id: string): Observable<any> {
    return this.httpClient.get<GroupStatsResponseResponse>(`${environment.baseUrl}/users/user?question=${id}`);
  }
}
