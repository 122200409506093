import { Component, Input, OnInit, ViewChild, ElementRef } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { UserService } from "../../user.service";
import { Location } from "@angular/common";
import { DialogComponent } from "../../shared/dialog/dialog.component";
import { MatDialog, MatSelectChange } from "@angular/material";
import { GroupService } from "../group.service";
import { RespondentService } from "../../respondents/respondent.service";
import { Group } from "../interfaces/group";
import { Respondent } from "../../respondents/interfaces/respondent";
import { Answer } from "../../answers/interfaces/answer";
import { UserGroup } from "../interfaces/user-group";
import { FormControl } from "@angular/forms";
import { debounceTime, tap, switchMap, finalize } from "rxjs/operators";
import { ExportToCsv } from "export-to-csv";
import { Pagination } from "../../interfaces/pagination";

@Component({
  selector: "app-group",
  templateUrl: "./group.component.html",
  styleUrls: ["./group.component.sass"]
})
export class GroupComponent implements OnInit {
  @Input() group: Group;
  public respondents: Respondent[];
  public _respondents: Respondent[];
  public selected_respondents: Respondent[];
  id: string;
  answers: Answer[];
  selectedAnswer: Answer;
  editing: boolean;
  answer: Answer;
  fetchingGroup: boolean;
  title: string;
  content: string;
  private fetchingRespondents: boolean;
  private user_group: UserGroup;
  addRespondent: boolean;
  private _respondent: Respondent;
  filteredRespondents: any[];
  searchRespondent = new FormControl();
  selectRespondentControl = new FormControl();
  isLoading: boolean;
  selectedRespondents: any[];
  searchTerm = new FormControl();
  page: number = 1;
  size: number = 10;
  previousPage = 1;
  nextPage = 1;
  totalPage = 1;
  numOfPages: number[] = [10, 20, 50, 100];
  isnext: boolean;
  isprevious: boolean;
  csvExporter: ExportToCsv
  private fileTitle = 'Chartext Respondents';
  private fileName = 'chartext_respondents';


  @ViewChild("sl", { static: false }) searchR;
  @ViewChild("respondent", { static: false }) respondentArea: ElementRef;

  options = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    showTitle: true,
    title: this.fileTitle,
    useTextFile: false,
    useBom: true,
    filename: this.fileName,
    useKeysAsHeaders: false,
    // <-- Won't work with useKeysAsHeaders present!
    headers: [
      "First Name",
      "Last Name",
      "Email",
      "Phone Number",
      "Gender",
      "Location",
      "Groups"
    ]
  };
  allSelected: boolean;
  IsIndeterminate: boolean;

  constructor(
    private activatedRoute: ActivatedRoute,
    private restclientService: GroupService,
    private router: Router,
    private userService: UserService,
    private location: Location,
    private dialog: MatDialog,
    private respondent: RespondentService
  ) {
    this.group = <Group>{};
    this.selectedAnswer = <Answer>{};
    this.answer = <Answer>{};
    this.answers = [];
    this.editing = false;
    this.user_group = <UserGroup>{};
    this.addRespondent = false;
    this.respondents = [];
    this._respondents = [];
    this.selected_respondents = [];
    this.selectedRespondents = [];
  }

  changeSize($event) {
    let search = "";

    if (this.searchTerm.value !== null) {
      search = this.searchTerm.value;
    }
    this.page = 1;
    this.size = $event.target.value;
    this.getRespondentsByGroup(this.group.id, this.page, this.size, search);
  }

  getMoreRespondents(type: string) {
    let search = "";

    if (this.searchTerm.value !== null) {
      search = this.searchTerm.value;
    }
    if (type === "previous") {
      this.page = this.getPreviousPage();
      this.getRespondentsByGroup(this.group.id, this.page, this.size, search);
    } else {
      this.page = this.getNextPage();
      this.getRespondentsByGroup(this.group.id, this.page, this.size, search);
    }
  }

  getNextPage() {
    this.nextPage = this.page + 1;
    if (this.nextPage > this.totalPage) {
      this.nextPage = this.totalPage;
    }
    return this.nextPage;
  }

  getPreviousPage() {
    if (this.page === 1) {
      this.previousPage = this.page;
    } else {
      this.previousPage = this.page - 1;
    }
    return this.previousPage;
  }

  pagiationData(data: Pagination) {
    this.page = data.current_page;
    this.totalPage = data.num_pages;
    if (this.page <= 1) {
      this.previousPage = 1;
      this.isprevious = false;
    } else {
      this.previousPage = this.page - 1;
      this.isprevious = true;
    }
    if (this.page < this.totalPage) {
      this.nextPage = this.page + 1;
      this.isnext = true;
    } else {
      this.isnext = false;
    }
  }

  ngOnInit() {
    this.id = this.activatedRoute.snapshot.paramMap.get("id");
    this.csvExporter = new ExportToCsv(this.options);

    if (this.id) {
      this.getGroup(this.id);
    }

    this.respondent.respondents(1, this.size).subscribe(data => {
      this.filteredRespondents = data.data.results.users;
    });
    this.searchRespondent.valueChanges
      .pipe(
        debounceTime(500),
        tap(() => {
          this.filteredRespondents = [];
          this.isLoading = true;
        }),
        switchMap(value =>
          this.respondent.respondentsWithoutPagination(value).pipe(
            finalize(() => {
              this.isLoading = false;
            })
          )
        )
      )
      .subscribe(data => {
        this.filteredRespondents = data.data.users;
        this.setSelectedRespondents();
      });
    this.searchForRespondent();
  }

  searchForRespondent() {
    this.searchTerm.valueChanges
      .pipe(
        debounceTime(500),
        tap(() => {
          this.respondents = [];
        }),
        switchMap(value =>
          this.respondent
            .getRespondentsByGroup(this.group.id, 1, 10, value)
            .pipe()
        )
      )
      .subscribe(data => {
        this.respondents = data.data.results.users;
      });
  }

  openedChange(e) {
    // this.searchRespondent.patchValue('');
  }

  setSelectedRespondents() {
    if (
      this.selectRespondentControl.value &&
      this.selectRespondentControl.value.length > 0
    ) {
      this.selectRespondentControl.value.forEach(e => {
        if (this.selectedRespondents.indexOf(e) == -1) {
          this.selectedRespondents.push(e);
        }
      });
    }
  }

  selectionChange(event) {
    if (event.isUserInput && event.source.selected == false) {
      let index = this.selectedRespondents.indexOf(event.source.value);
      this.selectedRespondents.splice(index, 1);
    }

    if (event.isUserInput && event.source.selected == true) {
      if (
        this.selectedRespondents.length <= 0 ||
        this.selectedRespondents.indexOf(event.source.value) !== -1
      ) {
        this.selectedRespondents.push(event.source.value);
      }
    }
  }

  clearSearch(event) {
    event.stopPropagation();
    this.searchRespondent.patchValue("");
  }

  getGroup(id: string): void {
    this.fetchingGroup = true;
    this.restclientService
      .getGroup(id)
      .pipe(
        finalize(() => {
          this.getRespondentsByGroup(this.group.id, this.page, this.size, "");
        })
      )
      .subscribe(
        res => {
          this.fetchingGroup = false;
          this.group = res.data;
        },
        err => {
          this.fetchingGroup = false;
          this.goBack();
        }
      );
  }

  private fetchRespondents() {
    this.respondent.respondents().subscribe(
      res => {
        this._respondents = res.data.results.users;
      },
      err => {
        this.fetchingRespondents = false;

        if (
          err.statusText === "Unauthorized" ||
          err.status === false ||
          err.message === "Ooops something went Wrong."
        ) {
          this.userService.setIsLoggedOut();
        }
      }
    );
  }

  getRespondentsByGroup(
    id: string,
    page: number = 1,
    size = 10,
    search = ""
  ): void {
    this.fetchingRespondents = true;
    this.respondent
      .getRespondentsByGroup(id, page, size, search)
      .pipe(
        finalize(() => {
          this.fetchingRespondents = false;
        })
      )
      .subscribe(
        res => {
          this.respondents = res.data.results.users;
          this.pagiationData(res.data.pagination);
        },
        err => {
          this.fetchingRespondents = false;
        }
      );
  }

  editGroup(): void {
    this.router.navigate([`groups/edit/${this.id}`]);
  }

  deleteGroup(id: string): void {
    this.fetchingGroup = true;
    this.restclientService.deleteGroup(id).subscribe(
      res => {
        this.goBack();
      },
      err => {
        if (
          err.statusText === "Unauthorized" ||
          err.status === false ||
          err.message === "Ooops something went Wrong."
        ) {
          this.userService.setIsLoggedOut();
        }
      }
    );
  }

  openDeleteGroupDialog(): void {
    this.title = "Delete group below?";
    this.content = this.group.name;
    const dialogRef = this.dialog.open(DialogComponent, {
      width: "350px",
      data: { title: this.title, content: this.content }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.deleteGroup(this.group.id);
      }
    });
  }

  goBack(): void {
    this.location.back();
  }

  openRemoveRespondentFromGroup(respondent: Respondent): void {
    this._respondent = respondent;
    this.user_group.group = this.group.id;
    this.user_group.user = respondent.id;
    this.title = 'Remove respondent from group?';
    this.content = this.group.name;
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '350px',
      data: {title: this.title, content: this.content, label: 'Remove'}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.removeRespondentFromGroup([this.user_group]);
      }
    });
  }

  openRemoveRespondentsFromGroups () {
    this.title = 'Remove Respondents'
    this.content = ' Are you sure you want to remove this respondents'

    const dialogRef = this.dialog.open(DialogComponent, {
      width: '350px',
      data: {title: this.title, content: this.content, label: 'Remove'}
    });


    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const respondents = this.respondents.filter( (respondent) => respondent.isChecked)

        const respondentsToRemove: any[] = []
        respondents.forEach((respondent) => {
          respondentsToRemove.push({'user': respondent.id, 'group' : this.group.id})
        })
        this.removeRespondentFromGroup(respondentsToRemove);
      }
    });
  }

  removeRespondentFromGroup(user_group: any): void {
    this.respondent
      .deleteUserUserGroup(user_group)
      .pipe(
        finalize(() => {
          this.getRespondentsByGroup(this.group.id);
        })
      )
      .subscribe(
        res => {
          // this.respondents = this.filterOutRespondent(this.respondents, this._respondent);
        },
        err => {

        }
      );
  }

  addRespondentToGroup(user_group: UserGroup[]): void {
    this.respondent
      .createUserUserGroup(user_group)
      .pipe(
        finalize(() => {
          this.getRespondentsByGroup(this.group.id);
          this.selectRespondentControl.patchValue("");
          this.searchRespondent.patchValue("");
        })
      )
      .subscribe(
        res => {},
        err => {
          if (
            err.statusText === "Unauthorized" ||
            err.status === false ||
            err.message === "Ooops something went Wrong."
          ) {
            this.userService.setIsLoggedOut();
          }
        }
      );
  }

  actionAddRespondents() {
    let respondents = [];
    this.selectedRespondents = this.selectRespondentControl.value;
    this.searchR.close();
    this.selectedRespondents.forEach(function(respondent) {
      this.user_group.user = respondent.id;
      respondents.push({ user: respondent.id, group: this.group.id });
    }, this);
    this.addRespondentToGroup(respondents);
    this.selectedRespondents = [];
    this.addRespondent = false;
  }

  ExportRespondent() {
    this.respondent
      .respondentsWithoutPagination("", this.group.id)
      .subscribe(data => {
        const respondents = data.data.users;
        this.csvExporter.generateCsv(respondents);
      });
  }

  respondentChanged(respondent, event) {
    respondent.isChecked = event.checked;

    let totalSelected = this.respondents.filter(i => i.isChecked).length;
    if (totalSelected === 0) {
      this.allSelected = false;
      this.IsIndeterminate = false;
    } else if (totalSelected > 0 && totalSelected < this.respondents.length) {
      this.allSelected = false;
      this.IsIndeterminate = true;
    } else if (totalSelected === this.respondents.length) {
      this.allSelected = true;
      this.IsIndeterminate = false;
    }
  }

  toggleSelectAll(event) {
    this.allSelected = event.checked;
    this.respondents.forEach(respondent => {
      respondent.isChecked = event.checked;
    });
  }

}
