import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Location} from '@angular/common';
import {UserService} from '../../user.service';
import {ActivatedRoute} from '@angular/router';
import {SurveyService} from '../survey.service';
import {Survey} from '../interfaces/survey';

@Component({
  selector: 'app-survey-add-edit',
  templateUrl: './survey-add-edit.component.html',
  styleUrls: ['./survey-add-edit.component.sass']
})
export class SurveyAddEditComponent implements OnInit, OnChanges {

  @Output() surveyUpdated: EventEmitter<boolean> = new EventEmitter();

  @Input() survey: Survey;
  surveyToEdit: Survey;
  showSurveyError: boolean;
  addSurvey: boolean;
  surveyForm: FormGroup;
  id: string;
  title: boolean;
  description: boolean;
  subject_matter: boolean;
  period: boolean;
  welcome_message: boolean;
  thank_you_message: boolean;
  private isChild: boolean;

  constructor(private restclientService: SurveyService,
              private userService: UserService,
              private activatedRoute: ActivatedRoute,
              private location: Location) {
    this.showSurveyError = false;
    this.addSurvey = true;
    this.isChild = false;
    this.initializeForm();
  }

  ngOnInit() {
    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    if (this.id != null) {
      this.getSurvey();
    } else {
      this.surveyToEdit = this.survey;
      this.editSurvey(this.survey);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.survey.id !== undefined) {
      this.surveyToEdit = this.survey;
      this.editSurvey(this.survey);
      this.addSurvey = false;
    }
    this.isChild = true;
  }

  getSurvey(): void {
    this.restclientService.survey(this.id)
      .subscribe(res => {
        this.addSurvey = false;
        this.surveyToEdit = res.data;
        this.editSurvey(res.data);
      });
  }


  private initializeForm() {
    this.surveyForm = new FormGroup({
      title: new FormControl('', Validators.required),
      description: new FormControl('', Validators.required),
      subject_matter: new FormControl('', Validators.required),
      period: new FormControl('', Validators.required),
      welcome_message: new FormControl('', Validators.required),
      thank_you_message: new FormControl('', Validators.required)
      // join_keyword: new FormControl('', Validators.required),
      // created_at: new FormControl('', Validators.required),
    });
  }

  editSurvey(survey: Survey): void {
    this.survey = survey;
    if (this.survey !== undefined) {
      this.surveyForm.get('title').setValue(survey.title);
      this.surveyForm.get('description').setValue(survey.description);
      this.surveyForm.get('subject_matter').setValue(survey.subject_matter);
      this.surveyForm.get('period').setValue(survey.period);
      this.surveyForm.get('welcome_message').setValue(survey.welcome_message);
      this.surveyForm.get('thank_you_message').setValue(survey.thank_you_message);
      //  this.surveyForm.get('join_keyword').setValue(survey.join_keyword);
      //  this.surveyForm.get('created_at').setValue(survey.created_at);
    }
  }

  createSurvey(survey: Survey): void {
    console.log(survey);
    this.survey = survey;
    if (this.surveyToEdit.id !== undefined) {
      this.survey.id = this.surveyToEdit.id;
      this.restclientService.updateSurvey(this.survey)
        .subscribe(
          (res) => {
            this.goBack();
          },
          err => {

            if (err.statusText === 'Unauthorized' || err.status === false || err.message === 'Ooops something went Wrong.') {
              this.userService.setIsLoggedOut();
            }
          }
        );
    } else {
      this.restclientService.createSurvey(this.survey)
        .subscribe(
          (res) => {
            this.goBack();
          },
          err => {

            if (err.statusText === 'Unauthorized' || err.status === false || err.message === 'Ooops something went Wrong.') {
              this.userService.setIsLoggedOut();
            }
          }
        );
    }
  }

  goBack(): void {
    this.clearForm();
    if (this.isChild) {
      this.surveyUpdated.emit(true);
    } else {
      this.location.back();
    }
  }

  clearForm() {
    this.surveyForm.get('title').setValue('');
    this.surveyForm.get('description').setValue('');
    this.surveyForm.get('subject_matter').setValue('');
    this.surveyForm.get('period').setValue('');
    this.surveyForm.get('welcome_message').setValue('');
    this.surveyForm.get('thank_you_message').setValue('');
    //  this.surveyForm.get('join_keyword').setValue(');
    //  this.surveyForm.get('created_at').setValue(');
  }
}
