import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild
} from "@angular/core";
import { Chart } from "chart.js";
import { ChartyData } from "./charty-data";

@Component({
  selector: "app-charty",
  templateUrl: "./charty.component.html",
  styleUrls: ["./charty.component.sass"]
})
export class ChartyComponent implements OnInit, OnChanges, AfterViewInit {
  @ViewChild("canvas_pie", { static: false }) canvasRef: ElementRef;
  _data: ChartyData;
  private chartType: string;
  inputData: ChartyData;

  @Input("data")
  set data(d: ChartyData) {
    this.inputData = d;
  }

  get data() {
    return this.inputData;
  }

  private ctx: any;
  graph_colors = [
    "#f44335",
    "#e91e63",
    "#9c27b0",
    "#673ab7",
    "#3f51b5",
    "#2196f3",
    "#03a9f4",
    "#00bcd4",
    "#009688",
    "#f4caf50",
    "#8bc34a",
    "#cddc39",
    "#ffeb3b",
    "#ffc107",
    "#ff9800",
    "#ff5722",
    "#795548",
    "#9e9e9e",
    "#607d8b"
  ];
  private canvasPie: any;

  constructor() {}

  ngOnInit() {}

  ngAfterViewInit(): void {
    this.ctx = this.canvasRef.nativeElement.getContext("2d");
    this.plotData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.inputData.counts !== undefined && this.ctx !== undefined) {
      this.canvasPie.destroy();
      this.plotData();
    }
  }

  dynamicColors() {
    this.graph_colors = this.shuffle(this.graph_colors);
    const color = this.graph_colors[
      Math.floor(Math.random() * this.graph_colors.length)
    ];
    return color;
  }

  shuffle(array) {
    let counter = array.length;

    // While there are elements in the array
    while (counter > 0) {
      // Pick a random index
      const index = Math.floor(Math.random() * counter);

      // Decrease counter by 1
      counter--;

      // And swap the last element with it
      const temp = array[counter];
      array[counter] = array[index];
      array[index] = temp;
    }

    return array;
  }

  plotData() {
    let color: any;
    const counts = this.inputData.counts;
    let labels = this.inputData.labels;

    labels = this.transformLabels(labels, counts);

    const colors = [];

    for (const res of this.inputData.counts) {
      color = this.dynamicColors();
      if (colors.indexOf(color) === -1) {
        colors.push(color);
      }
    }

    if (this.inputData.type === "bar") {
      this.canvasPie = new Chart(this.ctx, {
        type: this.inputData.type,
        data: {
          labels: labels,
          datasets: [
            {
              label: this.inputData.title,
              data: counts,
              backgroundColor: colors
            }
          ]
        },
        options: {
          scales: {
            yAxes: [
              {
                stacked: true,
                gridLines: {
                  display: false
                },
                ticks: {
                  beginAtZero: true,
                  callback: function(value) {
                    if (value % 1 === 0) {
                      return value;
                    }
                  }
                }
              }
            ],
            xAxes: [
              {
                stacked: true,
                gridLines: {
                  display: false
                }
              }
            ]
          }
        }
      });
    } else {
      this.canvasPie = new Chart(this.ctx, {
        type: this.inputData.type,
        data: {
          labels: labels,
          datasets: [
            {
              data: counts,
              backgroundColor: colors
            }
          ]
        }
      });
    }
  }

  doughnut() {
    this.canvasPie.destroy();
    this.inputData.type = "doughnut";
    this.plotData();
  }

  pieChart() {
    this.canvasPie.destroy();
    this.inputData.type = "pie";
    this.plotData();
  }

  barChart() {
    this.canvasPie.destroy();
    this.inputData.type = "bar";
    this.plotData();
  }

  private transformLabels(labels: any, counts: any) {
    let label;
    const localLabels = [];
    let i = 0;
    const localCounts = Object.assign([], counts);

    const sum = localCounts.reduce((a, b) => a + b, 0);
    for (const c of labels) {
      label = `${c} ${Math.round((counts[i] * 100) / sum).toFixed(2)}%`;
      localLabels.push(label);
      i++;
    }
    return localLabels;
  }
}
