import {Component, NgModule, OnInit} from '@angular/core';
import {Router, Routes} from '@angular/router';
// import { RouterModule } from '@angular/router';
import {AuthLoginComponent} from './login/auth.login.component';
import {AuthRegisterComponent} from './register/auth.register.component';

const ROUTES: Routes = [
  {
    path: '', children: [
      {path: 'login', component: AuthLoginComponent},
      {path: 'register', component: AuthRegisterComponent}
    ]
  },
];

@NgModule({
  imports: [
    // RouterModule.forChild(ROUTES)
  ]
})

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.sass']
})

export class AuthComponent implements OnInit {

  constructor(private router: Router) {
  }

  ngOnInit() {
  }

}


