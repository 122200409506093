import { Pipe, PipeTransform } from '@angular/core';
import { Survey } from './interfaces/survey';


@Pipe({name: 'incompleteSurvey', pure: false})
export class IncompleteSurvey implements PipeTransform {
  transform(allSurveys: Survey[], filterObj: Partial<Survey>) {
      if (!allSurveys || !filterObj) {
         return allSurveys
      }

      return allSurveys.filter(survey => survey.incomplete === false)

  }
}
