
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders,
  HttpErrorResponse
} from '@angular/common/http';
import { UserService } from './user.service';
import { Observable } from 'rxjs/Observable';
import { tap } from 'rxjs/operators';
import {CookieService} from 'ngx-cookie-service';

export const InterceptorSkipHeader = 'InterceptorSkipHeader';

export const  getHeaders: HttpHeaders = new HttpHeaders({
  InterceptorSkipHeader: 'InterceptorSkipHeader'
});

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor(private userService: UserService, private cookieService: CookieService) {}
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
   if (!request.headers.has('InterceptorSkipHeader')) {
        request = request.clone({
          setHeaders: {
           'Authorization': `Token ${this.cookieService.get('token')}`
          }
        });
   } else {
     request = request.clone({
        headers: new HttpHeaders({})
     });
   }
    return next.handle(request).pipe(tap((response) => {

    }, (err: any ) => {
      if (err instanceof HttpErrorResponse) {
        if (err.status === 401) {
          // @Todo  add refresh token on another ticket
          this.userService.setIsLoggedOut()
        }
      }
    }));
  }
}

