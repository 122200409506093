import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {RestclientService} from '../../restclient.service';
import {UserService} from '../../user.service';
import {NotificationService} from '../../notification.service';
import {Surveyresponse} from '../interfaces/surveyresponse';
import {Survey} from '../interfaces/survey';
import {ResponsesParams} from './responses-params';

@Component({
  selector: 'app-responses',
  templateUrl: './responses.component.html',
  styleUrls: ['./responses.component.sass']
})
export class ResponsesComponent implements OnInit, OnChanges {
  survey_responses: Surveyresponse[];
  public id: string;
  public fetchingSurvey: boolean;
  public survey: Survey;
  public value: number;
  mode = 'indeterminate';
  loadMsg = 'Fetching responses...';

  @Input('params')
  params: ResponsesParams;

  constructor(private activatedRoute: ActivatedRoute,
              private restclientService: RestclientService,
              private userService: UserService,
              private notificationService: NotificationService) {
    this.fetchingSurvey = true;
    this.survey_responses = [];
    this.survey = <Survey>{};
    this.params = <ResponsesParams>{};
  }

  ngOnInit() {
    this.getResponses(this.params);
  }

  private getResponses(params: any) {
    this.restclientService.responses(params)
      .subscribe(res => {
          this.fetchingSurvey = false;
          this.survey_responses = res.data.results;
        },
        err => {

          this.fetchingSurvey = false;
          this.notificationService.showSnackBar(err.error.data.detail, 'Dismiss', 3000);
          if (err.statusText === 'Unauthorized' || err.status === false || err.message === 'Ooops something went Wrong.') {
            this.userService.setIsLoggedOut();
          }
        });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.params.id) {
      this.getResponses(this.params);
    }
  }
}
