import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {of} from 'rxjs/observable/of';
import {Tile} from './models/tile';
import {TILES} from './models/tiles';
import {ALLTILES} from './models/alltiles';

@Injectable()
export class DashboardtilesService {

  constructor() {
  }

  getTiles(): Observable<Tile[]> {
    return of(TILES);
  }

  getAllTiles(): Observable<Tile[]> {
    return of(ALLTILES);
  }

}
