import {Component, OnInit} from '@angular/core';
import {RestclientService} from '../../restclient.service';
import {UserService} from '../../user.service';
import {Router} from '@angular/router';
import {RespondentService} from '../respondent.service';
import {Respondent} from '../interfaces/respondent';
import {ChartyData} from '../../charty/charty-data';

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.sass']
})
export class StatisticsComponent implements OnInit {

  public respondents: Respondent[];
  private term: string;
  fetchingRespondets: boolean;

  public RespondenseData: ChartyData;
  public RespondensePerChoice: ChartyData;
  public RespondentsPerQuestion: ChartyData;
  public ResponsesByLocation: ChartyData;
  public ResponsesByGender: ChartyData;
  public ResponsesByAgeGroup: ChartyData;

  dataSet: boolean;
  rblDataSet: boolean;
  rbgDataSet: boolean;
  rbagDataSet: boolean;
  showAll: boolean;
  allToggle = 'All';

  constructor(private restclientService: RestclientService,
              private router: Router,
              private respondent: RespondentService,
              private userService: UserService) {
    this.respondents = [];
    this.term = '';

    this.RespondenseData = <ChartyData>{};
    this.RespondentsPerQuestion = <ChartyData>{};
    this.RespondensePerChoice = <ChartyData>{};
    this.ResponsesByLocation = <ChartyData>{};
    this.ResponsesByGender = <ChartyData>{};
    this.ResponsesByAgeGroup = <ChartyData>{};
  }

  ngOnInit() {
    this.respondent.respondents().subscribe(
      res => {
        this.fetchingRespondets = false;

        this.respondents = res.data.results.users;
        const count_by_gender = res.data.results.count_by_gender;
        const count_by_location = res.data.results.count_by_location;
        const count_by_age_group = res.data.results.count_by_age_group;
        const gender_labels = [];
        const gender_counts = [];
        const labels_location = [];
        const counts_location = [];
        const labels_age_group = [];
        const counts_age_group = [];

        for (const c of count_by_gender) {
          gender_labels.push(c.gender);
          gender_counts.push(c.total);
        }

        this.ResponsesByGender.title = 'By Title';
        this.ResponsesByGender.type = 'pie';
        this.ResponsesByGender.labels = gender_labels;
        this.ResponsesByGender.counts = gender_counts;

        for (const c of count_by_location) {
          labels_location.push(c.location);
          counts_location.push(c.total);
        }

        this.ResponsesByLocation.title = 'By Location';
        this.ResponsesByLocation.type = 'pie';
        this.ResponsesByLocation.labels = labels_location;
        this.ResponsesByLocation.counts = counts_location;

        this.RespondenseData = this.ResponsesByLocation;
        this.dataSet = true;

        for (const c of count_by_age_group) {
          labels_age_group.push(c.age_group__name);
          counts_age_group.push(c.total);
        }

        this.ResponsesByAgeGroup.title = 'By Location';
        this.ResponsesByAgeGroup.type = 'pie';
        this.ResponsesByAgeGroup.labels = labels_age_group;
        this.ResponsesByAgeGroup.counts = counts_age_group;

        this.rblDataSet = true;
        this.rbgDataSet = true;
        this.rbagDataSet = true;

      },
      err => {
        this.fetchingRespondets = false;

        if (err.statusText === 'Unauthorized' || err.status === false || err.message === 'Ooops something went Wrong.') {
          this.userService.setIsLoggedOut();
        }
      }
    );

    this.respondent.respondents()
      .subscribe(res => {
        const surveys_completed = res['data']['results']['users'].map(res => res.surveys_completed);
        const surveys_participated = res['data']['results']['users'].map(res => res.surveys_participated);
        const surveys_sent = res['data']['results']['users'].map(res => res.surveys_sent);
        const first_name = res['data']['results']['users'].map(res => res.first_name);
      });
  }

  toggleAll() {
    if (this.showAll) {
      this.showAll = false;
      this.allToggle = 'All';
    } else {
      this.showAll = true;
      this.allToggle = 'Main';
    }
  }

  toggleView(view: string) {
    this.showAll = false;
    this.allToggle = 'All';
    if (view === 'by_location') {
      this.RespondenseData = this.ResponsesByLocation;
    } else if (view === 'by_gender') {
      this.RespondenseData = this.ResponsesByGender;
    } else if (view === 'by_age_group') {
      this.RespondenseData = this.ResponsesByAgeGroup;
    } else {
      this.RespondenseData = this.RespondensePerChoice;
    }
  }

}
