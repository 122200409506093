import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import  { map } from 'rxjs/operators';
import {Headers} from '@angular/http';

@Injectable()
export class WeatherService {

  options: any;

  constructor(private _http: HttpClient) {
    this.options = {};
  }

  setHeaders(headers: Headers): any {
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    headers.append('Access-Control-Allow-Headers', 'application/json, application/json');
    headers.append('Access-Control-Allow-Methods', 'POST, GET');
    headers.append('Access-Control-Allow-Origin', '*');
    return {headers: headers};
  }

  dailyForecast() {
    const headers = new Headers();
    this.options.headers = this.setHeaders(headers);
    console.log(headers);
    return this._http.get(
      'http://samples.openweathermap.org/RespondentsPerQuestion/2.5/history/city?q=Warren,OH&appid=b6907d289e10d714a6e88b30761fae22',
      {headers: {'Access-Control-Allow-Origin': '*'}}
    ).map(result => result);
  }

}
