import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { RestclientService } from "../restclient.service";
import { UserService } from "../user.service";
import { MatDialog } from "@angular/material";
import { DialogComponent } from "../shared/dialog/dialog.component";
import { SurveyService } from "../surveys/survey.service";
import { Survey } from "../surveys/interfaces/survey";

@Component({
  selector: "app-archive",
  templateUrl: "./archive.component.html",
  styleUrls: ["./archive.component.sass"]
})
export class ArchiveComponent implements OnInit {
  fetchingSurveys: boolean;
  _search: string;
  closedSurveys: Survey[];
  user: string;
  title: string;
  content: string;
  private survey: Survey;
  next_page = 1;
  page = 1;
  total_page = 1;
  previous_page = 1;
  size = 10;
  private num_of_pages = [5, 10, 20, 50, 100];
  allSelected: boolean;
  IsIndeterminate: boolean;

  constructor(
    private restclientService: RestclientService,
    private router: Router,
    private dialog: MatDialog,
    private userService: UserService,
    private surveyService: SurveyService
  ) {
    this.closedSurveys = [];
    this._search = "";
  }

  ngOnInit() {
    this.user = this.userService.getUserId();
    this.fetchSurveys();
  }

  onChange($e) {
    if ($e.length > 2) {
      this.page = 1;
      this.restclientService
        .finishedSurveysPerUser(this.user, this.page, this.size, this._search)
        .subscribe(
          res => {
            this.fetchingSurveys = false;
            this.closedSurveys = res.data.results;
            this.total_page = res.data.pagination.num_pages;
            this.page = res.data.pagination.current_page;
            this.getNextPage();
            this.getPreviousPage();
          },
          err => {
            this.fetchingSurveys = false;
            if (
              err.statusText === "Unauthorized" ||
              err.status === false ||
              err.message === "Ooops something went Wrong."
            ) {
              this.userService.setIsLoggedOut();
            }
          }
        );
    } else if ($e.length === 0) {
      this.fetchSurveys();
    }
  }

  private fetchSurveys() {
    this.fetchingSurveys = true;
    this.restclientService
      .finishedSurveysPerUser(this.user, this.page, this.size)
      .subscribe(
        res => {
          this.fetchingSurveys = false;
          this.closedSurveys = res.data.results;
          this.total_page = res.data.pagination.num_pages;
          this.page = res.data.pagination.current_page;
          this.getNextPage();
          this.getPreviousPage();
        },
        err => {
          this.fetchingSurveys = false;
          if (
            err.statusText === "Unauthorized" ||
            err.status === false ||
            err.message === "Ooops something went Wrong."
          ) {
            this.userService.setIsLoggedOut();
          }
        }
      );
  }

  MoreSurveys(event) {
    this.size = event.target.value;
    this.page = 1;
    this.fetchSurveys();
  }

  getMoreSurveys(type: string) {
    if (type === "previous") {
      this.page = this.getPreviousPage();
      this.fetchSurveys();
    } else {
      this.page = this.getNextPage();
      this.fetchSurveys();
    }
  }

  getNextPage() {
    this.next_page = this.page + 1;
    if (this.next_page > this.total_page) {
      this.next_page = this.total_page;
    }
    return this.next_page;
  }

  getPreviousPage() {
    if (this.page === 1) {
      this.previous_page = this.page;
    } else {
      this.previous_page = this.page - 1;
    }
    return this.previous_page;
  }

  openRecycleSurveyDialog(survey: Survey) {
    this.survey = survey;
    this.title = "Clone survey to the Hub...";
    this.content = this.survey.title;
    const dialogRef = this.dialog.open(DialogComponent, {
      width: "350px",
      data: {
        title: this.title,
        content: this.content,
        label: "Clone",
        class: "btn-green"
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.recycleSurvey();
      }
    });
  }

  openDeleteSurveyDialog(survey: Survey) {
    this.survey = survey;
    this.title = "Are you sure you want to delete this survey?";
    this.content = this.survey.title;
    const dialogRef = this.dialog.open(DialogComponent, {
      width: "350px",
      data: {
        title: this.title,
        content: this.content,
        label: "Delete",
        class: "btn-green"
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.deleteSurvey(this.survey.id);
      }
    });
  }

  private recycleSurvey() {
    this.surveyService.recycleSurvey({ survey: this.survey.id }).subscribe(
      res => {
        // this.fetchSurveys();
      },
      err => {
        if (
          err.statusText === "Unauthorized" ||
          err.status === false ||
          err.message === "Ooops something went Wrong."
        ) {
          this.userService.setIsLoggedOut();
        }
      }
    );
  }

  openRecyleandIntiate(survey: Survey) {
    this.survey = survey;
    this.title = "Initiate Survey now...";
    this.content = this.survey.title;
    const dialogRef = this.dialog.open(DialogComponent, {
      width: "350px",
      data: {
        title: this.title,
        content: this.content,
        label: "Initiate",
        class: "btn-green"
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.recycleActivateSurvey();
      }
    });
  }

  private recycleActivateSurvey() {
    this.surveyService
      .recycleSurvey({ survey: this.survey.id, is_initiate: 1 })
      .subscribe(
        res => {
          // this.fetchSurveys();
        },
        err => {
          if (
            err.statusText === "Unauthorized" ||
            err.status === false ||
            err.message === "Ooops something went Wrong."
          ) {
            this.userService.setIsLoggedOut();
          }
        }
      );
  }

  deleteSurvey(survey_id) {
    console.log(survey_id);
    this.surveyService.deleteSurvey(survey_id).subscribe(
      data => {
        alert("Deleted successfully");
      },
      error => {
        alert("Not deleted, please try again later ");
      }
    );
  }
  OnChange($event) {
    console.log($event);
    //MatCheckboxChange {checked,MatCheckbox}
  }

  OnIndeterminateChange($event) {
    console.log($event);
    //true or false
  }
  surveyChanged(survey, event) {
    survey.isChecked = event.checked;

    let totalSelected = this.closedSurveys.filter(i => i.isChecked).length;
    if (totalSelected === 0) {
      this.allSelected = false;
      this.IsIndeterminate = false;
    } else if (totalSelected > 0 && totalSelected < this.closedSurveys.length) {
      this.allSelected = false;
      this.IsIndeterminate = true;
    } else if (totalSelected === this.closedSurveys.length) {
      this.allSelected = true;
      this.IsIndeterminate = false;
    }
  }

  toggleSelectAll(event) {
    this.allSelected = event.checked;
    this.closedSurveys.forEach(survey => {
      survey.isChecked = event.checked;
    });
  }
}
