import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {SurveysResponse} from '../surveys/interfaces/surveys-response';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  constructor(public httpClient: HttpClient) {
  }

  getSurveyresponsePerGroup(survey) {
    return this.httpClient.get<SurveysResponse>(`${environment.baseUrl}/users/user?survey=${survey}`);
  }

  getResponsePerQuestion(question) {
    return this.httpClient.get<SurveysResponse>(`${environment.baseUrl}/users/user?question=${question}`);
  }

  getResponsePerAnswer(answer) {
    return this.httpClient.get<SurveysResponse>(`${environment.baseUrl}/users/user?answer=${answer}`);
  }
}
