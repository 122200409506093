import {Component, OnInit, Inject, ViewChild, ElementRef} from '@angular/core';
import {UserService} from '../user.service';
import {RestclientService} from '../restclient.service';
import {MatSelect, MatSnackBar} from '@angular/material';
import {NotificationService} from '../notification.service';
import {GroupService} from '../groups/group.service';
import {Survey, SuveyObj} from '../surveys/interfaces/survey';
import {Group} from '../groups/interfaces/group';
import {SendSurvey} from './sendsurvey';
import {ScheduleSurvey} from './schedulesurvey';
import {FormControl} from '@angular/forms';
import {tap, map, debounceTime, switchMap, finalize} from 'rxjs/operators';
import {MatSelectChange, MAT_DIALOG_DATA, MatDialogRef, MatDialog} from '@angular/material';
import {Respondent} from '../respondents/interfaces/respondent';
import {RespondentService} from '../respondents/respondent.service';
import {Observable} from 'rxjs/Observable';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {DialogComponent} from '../shared/dialog/dialog.component';
import {SurveyService} from '../surveys/survey.service';


@Component({
  selector: 'app-sendsurvey',
  templateUrl: './sendsurvey.component.html',
  styleUrls: ['./sendsurvey.component.sass']
})
export class SendsurveyComponent implements OnInit {
  groups: Group[];
  surveys: Survey[];
  group: Group;
  selectedGroups: Group[];
  selectedSurvey: Survey;
  _selectSurvey: Observable<Survey>;
  survey: Survey;
  selected_survey: Survey;
  selectedGroup: Group;
  send_survey: SendSurvey;
  schedule_survey: ScheduleSurvey;
  checked: boolean;
  start_at: string;
  date_time: any;
  averageCost: Number = 0;
  total_respondents = 0;
  private _surveys: Survey[];
  groupSearchForm: FormControl;
  groupsearch: boolean;
  surveySearch: FormControl;
  issurveySearch: boolean;
  isrespondentSearch: boolean;
  surveyConductors: any[];
  isModal: boolean;
  respondents: Respondent[];
  selectedRespondents: Respondent[];
  respondentSearch: FormControl;
  isSendingSurvey: boolean

  @ViewChild('respondentSelectView', {static: false}) respondentSelectView: MatSelect;
  @ViewChild('matSelectSurvey', {static: false})  matSelectSurvey: MatSelect;

  constructor(
    public userService: UserService,
    public restclientService: RestclientService,
    public snackBar: MatSnackBar,
    public groupService: GroupService,
    public notificationService: NotificationService,
    public respondentService: RespondentService,
    public dialog: MatDialog,
    public surveyService: SurveyService
  ) {
    this.groups = [];
    this.surveys = [];
    this.group = <Group>{};
    this.selectedGroups = [];
    this.survey = <Survey>{};
    this.send_survey = <SendSurvey>{};
    this.schedule_survey = <ScheduleSurvey>{};
    this.groupSearchForm = new FormControl();
    this.surveySearch = new FormControl();
    this.respondentSearch = new FormControl();
    this.initializeDropDowns();
    this.selectedRespondents = [];
    this.selectedGroups = [];
  }


  private initializeDropDowns() {
    this.selectedGroup = null;
    this.selected_survey = null;
    this.checked = false;
    this.start_at = '';
    this.date_time = null;
  }

  ngOnInit() {
    this.fetchSurveys();
    this.fetchGroups();
    this.fetchRespondents();

    this.groupSearchForm.valueChanges.pipe(
      debounceTime(3000),
      tap(() => this.groupsearch = true),
      switchMap(value => this.groupService.groupsWithoutPagination(value).pipe(
        finalize(() => {
          this.groupsearch = false;
        })
      ))
    ).subscribe((data) => {
      this.groups = data.data;
    });

    /** respondents search **/
    this.respondentSearch.valueChanges.pipe(
      debounceTime(3000),
      tap(() => this.isrespondentSearch = true),
      switchMap(value => this.respondentService.respondentsWithoutPagination(value).pipe(
        finalize(() => {
          this.isrespondentSearch = false;
        })
      ))
    ).subscribe((data) => {
      //console.log(data)
      this.respondents = data.data.users;
    });

    /** survey search **/
    this.surveySearch.valueChanges.pipe(
      debounceTime(3000),
      tap(() => this.issurveySearch = true),
      switchMap(value => this.restclientService.inactiveSurveysWithoutPagination(value).pipe(
        finalize(() => {
          this.issurveySearch = false;
        })
      ))
    ).subscribe((data) => {
      //console.log(data)
      this.surveys = data.data;
    });

  }

  fetchGroups() {
    this.groupService.groups(1, 100).subscribe(
      res => {

        this.groups = res.data.results;
      },
      err => {
        if (
          err.statusText === 'Unauthorized' ||
          err.status === false ||
          err.message === 'Ooops something went Wrong.'
        ) {
          this.userService.setIsLoggedOut();
        }
      }
    );
  }

  fetchSurveys() {
    this.restclientService.inactiveSurveys().subscribe(
      res => {
        this._surveys = res.data.results;
        this.filterSurveys();
      },
      err => {
        if (
          err.statusText === 'Unauthorized' ||
          err.status === false ||
          err.message === 'Ooops something went Wrong.'
        ) {
          this.userService.setIsLoggedOut();
        }
      }
    );
  }

  fetchRespondents() {
    this.respondentService.respondents(1, 20).subscribe((data) => {
      this.respondents = data.data.results.users;
    });
  }

  respondentSelected(event: any) {
    this.selectedRespondents = event.value;
    this.caculateSuveyCostings();
  }


  onSelectSurvey(event: any): void {
    this.selectedSurvey = event.value;
    this.caculateSuveyCostings();

  }

  caculateSuveyCostings() {
    if (!this.selectedSurvey) {
      return this.averageCost = 0;
    }
    const totalSurveyPricing: number = this.selectedSurvey.total_pricing;
    let totalRespondents: number = 0;

    this.selectedGroups.forEach((group) => {
      totalRespondents += group.total_respondents;
    });
    totalRespondents += this.selectedRespondents.length;
    this.approximateCost(totalRespondents, totalSurveyPricing);

  }

  groupSelected(event: any): void {
    this.selectedGroups = event.value;
    this.caculateSuveyCostings();
  }

  approximateCost(numbers, cost) {
    this.averageCost = numbers * cost;
  }

  onToggleChange(event: any): void {
    this.checked = !this.checked;
  }

  sendSurvey(): void {
    this.surveyConductors = [];

    this.selectedGroups.forEach((group) => {
      this.surveyConductors.push({'group': group.id, 'survey': this.selectedSurvey.id});
    });

    this.selectedRespondents.forEach((respondent) => {
      this.surveyConductors.push({'user': respondent.id, 'survey': this.selectedSurvey.id});
    }, this);
    this.isSendingSurvey = true
    this.restclientService.sendSurvey(this.surveyConductors).pipe(
      tap(() => this.isSendingSurvey = true),
      finalize(() => {
        this.fetchSurveys();
        this.fetchGroups();
        this.isSendingSurvey = false
        this.selectedGroups = []
        this.selectedSurvey = {} as Survey
        this.selectedRespondents = []
        this.matSelectSurvey.value = '';
        this.respondentSelectView.value = ''
      })
    )
      .subscribe((data) => {
        this.notificationService.showSnackBar('Survey sent', 'Dismiss', 3000);
      });

  }

  dateTimeChange(event: any): void {
    this.start_at = event.value;
  }

  scheduleSurvey(): void {
    if (
      this.survey.id !== null &&
      this.survey.id !== undefined &&
      this.group.id !== null && this.group.id !== undefined
    ) {
      this.schedule_survey.survey = this.survey.id;
      this.schedule_survey.group = this.group.id;
      this.schedule_survey.start_at = this.start_at;
      this.restclientService.scheduleSurvey(this.schedule_survey).subscribe(
        res => {
          this.snackBar.open(
            'Survey has been scheduled successfully!',
            'Dismiss',
            {
              duration: 2500
            }
          );
          this.initializeDropDowns();
        },
        err => {
          this.notificationService.showSnackBar(
            'Something went wrong.',
            'Dismiss',
            3000
          );
          if (
            err.statusText === 'Unauthorized' ||
            err.status === false ||
            err.message === 'Ooops something went Wrong.'
          ) {
            this.userService.setIsLoggedOut();
          }
        }
      );
    } else {

    }
  }

  private filterSurveys() {
    for (const survey of this._surveys) {
      if (!survey.is_active && !survey.is_scheduled && !survey.is_finished) {
        this.surveys.push(survey);
      }
    }
  }


  scheduleSurveys() {
    this.selectedGroups.forEach(function (group) {
      this.group = group;
      this.scheduleSurvey();
    }, this);
  }

  close() {

  }

  intiateSurveyStatus() {
    if (this.selectedSurvey && (this.selectedGroups.length > 0 || this.selectedRespondents.length > 0)) {
      return false;
    }
    if (!this.selectedSurvey || (JSON.stringify(this.selectedSurvey) === '{}')) {

      return false;
    }
    return true;
  }

  openActivateSurveyDialog() {
    if (!this.selectedSurvey) {
      return false;
    }

    const dialogRef = this.dialog.open(DialogComponent, {
      width: '350px',
      data: {title: 'Intiate Survey:  ' + this.selectedSurvey.title, content: ' Are you sure you want to intiate this survey ?', label: 'Intiate', class: "btn-green"}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.surveyService.activateSurvey({'survey': this.selectedSurvey.id}).pipe(
          finalize(() => {
            this.fetchSurveys();
          })
        ).subscribe((data) => {
          this.notificationService.showSnackBar('Survey Activate', 'dismiss', 3000);
        });

      }
    });
  }
}


@Component({
  selector: 'app-sendsurvey',
  templateUrl: './sendsurvey.component.html',
  styleUrls: ['./sendsurvey.component.sass']
})


export class SendSurveyModalComponent extends SendsurveyComponent implements OnInit {

  constructor(
    userService: UserService,
    restclientService: RestclientService,
    snackBar: MatSnackBar,
    groupService: GroupService,
    notificationService: NotificationService,
    respondentService: RespondentService,
    dialog: MatDialog,
    surveyService: SurveyService,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    public dialogRef: MatDialogRef<SendSurveyModalComponent>
  ) {
    super(userService, restclientService, snackBar, groupService, notificationService, respondentService, dialog, surveyService);
    this.isModal = true;
  }

  ngOnInit() {
    super.ngOnInit();
    if (this.dialogData.hasOwnProperty('groups')) {
      this.selectedGroups = this.dialogData.groups;
    }

    if (this.dialogData.hasOwnProperty('users')) {
      this.selectedRespondents = this.dialogData.users;
    }
  }

  sendSurvey(): void {
    super.sendSurvey();
    this.dialogRef.close();
  }

  close() {
    this.dialogRef.close();
  }


}



