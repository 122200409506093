import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {RestclientService} from '../../restclient.service';
import {Location} from '@angular/common';
import {UserService} from '../../user.service';
import {NotificationService} from '../../notification.service';
import {Survey} from '../../surveys/interfaces/survey';
import {Question} from '../../questions/interfaces/question';

@Component({
  selector: 'app-searchdetail',
  templateUrl: './searchdetail.component.html',
  styleUrls: ['./searchdetail.component.sass']
})
export class SearchdetailComponent implements OnInit {
  @Input() survey: Survey;
  public questions: Question[];
  public fetchingSurvey: boolean;
  mode = 'indeterminate';
  loadMsg = 'Loading the Survey...';
  private id: string;
  private value: number;

  constructor(private activatedRoute: ActivatedRoute,
              private restclientService: RestclientService,
              private userService: UserService,
              private notificationService: NotificationService,
              private location: Location) {
    this.survey = <Survey>{};
    this.questions = [];
  }

  ngOnInit() {
    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    if (this.id) {
      this.getSurvey(this.id);
      this.getQuestions(this.id);
    }
  }

  private getSurvey(id: string): void {
    this.restclientService.survey(id)
      .subscribe(res => {
          this.fetchingSurvey = false;
          this.survey = res.data;
          // this.plotData();
        },
        err => {
          this.mode = 'determinate';
          this.loadMsg = 'Something went wrong while fetching the respondent...';
          this.value = 5;
          this.notificationService.showSnackBar(err.error.data.detail, 'Dismiss', 3000);
          if (err.statusText === 'Unauthorized' || err.status === false || err.message === 'Ooops something went Wrong.') {
            this.userService.setIsLoggedOut();
          }
        });
  }

  private getQuestions(id: string) {
    this.restclientService.surveyQuestions(id).subscribe(
      res => {
        console.log(res);
        this.questions = res.data.results;
      },
      err => {

        if (err.statusText === 'Unauthorized' || err.status === false || err.message === 'Ooops something went Wrong.') {
          this.userService.setIsLoggedOut();
        }
      }
    );
  }

  goBack(): void {
    this.location.back();
  }

}
