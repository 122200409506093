import {AbstractControl} from '@angular/forms';

/**
 *
 * @param control
 *  todo:  make this validator to take params
 */
export function notEqual(control: AbstractControl) {
  if (control.parent && control.parent.get('password')) {
    const passwrd = control.parent.get('password').value;
    const confrim_password = control.parent.get('confirm_password').value;
    if (passwrd !== confrim_password) {
      return {'match': true};
    }
  }
  return null;
}

export function passwordValidator(control: AbstractControl) {
  if (control.value === '') {
    return null;
  }
  const hasNumber = /\d/.test(control.value);
  const hasUpper = /[A-Z]/.test(control.value);
  const hasLower = /[a-z]/.test(control.value);
  const valid = hasNumber && hasUpper && hasLower;
  if (!valid) {
    // return what´s not valid
    return {strong: true};
  }
  return null;
}
