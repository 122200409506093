import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs/Subscription';
import {HttpClient, HttpEvent, HttpRequest, HttpResponse} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {dragMeta} from 'angular-file';
import { Location } from '@angular/common';

const BASE_URL = environment.baseUrl;

@Component({
  selector: 'app-import',
  templateUrl: './import.component.html',
  styleUrls: ['./import.component.sass']
})
export class ImportComponent implements OnInit {
  private id: string;
  private url: string;

  private uploads_urls = {
    respondents: '/users/upload/',
    teamtags: '/users/upload/groups/',
    sms: '/sms/upload/'
  };

  accept = '*';
  maxSize = 2048000;
  validComboDrag = null;
  // dragFiles: File[] = [];
  files: File[] = [];
  lastInvalids = [];
  progress: number;
  hasBaseDropZoneOver = false;
  httpEmitter: Subscription;
  httpEvent: HttpEvent<{}>;
  lastFileAt: Date;

  sendableFormData: FormData;
  dragFiles: dragMeta[];

  // populated via ngfFormData directive

  constructor(private activatedRoute: ActivatedRoute,
              private http: HttpClient, private location: Location) {
  }

  ngOnInit() {
    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    this.url = BASE_URL + this.uploads_urls[this.id];
    console.log(this.url);
  }

  uploadFiles(files: File[]): Subscription {
    const req = new HttpRequest<FormData>('PUT', this.url, this.sendableFormData, {
      reportProgress: true // , responseType: 'text'
    });

    return this.httpEmitter = this.http.request(req)
      .subscribe(
        event => {
          this.httpEvent = event;

          if (event instanceof HttpResponse) {
            delete this.httpEmitter;
            this.location.back();
          }
        },
        error => console.log('Error Uploading', error)
      );

  }

  getDate() {
    return new Date();
  }

  cancel() {
    this.progress = 0;
    if (this.httpEmitter) {
      this.httpEmitter.unsubscribe();
    }
  }

}
