import {Component, Input, OnInit, ViewChild, ElementRef} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../../user.service';
import {Location} from '@angular/common';
import {MatDialog, MatTabChangeEvent} from '@angular/material';
import {DialogComponent} from '../../shared/dialog/dialog.component';
import {QuestionService} from '../question.service';
import {AnswerService} from '../../answers/answer.service';
import {AnalyticsService} from '../../analytics/analytics.service';
import {Question} from '../interfaces/question';
import {Answer} from '../../answers/interfaces/answer';
import {Stats} from '../../groups/interfaces/stats';

@Component({
  selector: 'app-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.sass']
})
export class QuestionComponent implements OnInit {
  @ViewChild('canvas',{ read: true, static: false }) canvasRef: ElementRef;
  @ViewChild('canvasb',{ read: true, static: false }) canvasRefb: ElementRef;
  @ViewChild('canvasc',{ read: true, static: false }) canvasRefc: ElementRef;
  @ViewChild('canvas_per_choice',{ read: true, static: false }) canvasPerAnswer: ElementRef;
  chart = [];
  chartb = [];
  chartc = [];
  private ctx: any;
  private ctxb: any;
  private ctxc: any;
  @Input() question: Question;
  id: string;
  answers: Answer[];
  selectedAnswer: Answer;
  editing: boolean;
  answer: Answer;
  fetchingQuestion: boolean;
  canvas_per_a: any;

  title: string;
  content: string;

  selectedTab: number;
  private anlaytics_data: any;

  private groupStats: Stats;

  constructor(private activatedRoute: ActivatedRoute,
              private restclientService: QuestionService,
              private answerservice: AnswerService,
              private router: Router,
              private userService: UserService,
              private location: Location,
              private dialog: MatDialog,
              private analytics: AnalyticsService) {
    this.question = <Question>{};
    this.selectedAnswer = <Answer>{};
    this.answer = <Answer>{};
    this.answers = [];
    this.editing = false;
    this.selectedTab = 0;
  }

  ngOnInit() {
    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    if (this.id) {
      this.getQuestion(this.id);
      this.getQuestionStats(this.id);
      this.anlaytics_data = this.analytics.getResponsePerQuestion(this.id);
    }
  }

  selectedTabChange(matTabChangeEvent: MatTabChangeEvent) {
    this.selectedTab = matTabChangeEvent.index;
  }

  getQuestion(id: string): void {
    this.fetchingQuestion = true;
    this.restclientService.question(id)
      .subscribe(res => {
        this.fetchingQuestion = false;
        this.question = res.data;
        if (this.question.question_type === 'CLOSED') {
          this.getAnswers(this.id);
        }
      }, err => {
        if (err.statusText === 'Unauthorized' || err.status === false || err.message === 'Ooops something went Wrong.') {
          this.userService.setIsLoggedOut();
        }
      });
  }

  editQuestion(): void {
    this.router.navigate([`questions/edit/${this.id}`]);
   // this.editing = true
  }

  deleteQuestion(): void {
    this.restclientService.deleteQuestion(this.question.id)
      .subscribe(
        (res) => {
          this.router.navigate(['questions']);
        },
        err => {
          if (err.statusText === 'Unauthorized' || err.status === false || err.message === 'Ooops something went Wrong.') {
            this.userService.setIsLoggedOut();
          }
        }
      );
  }

  getAnswers(id: string) {
    this.restclientService.questionAnswers(id).subscribe(
      res => {
        this.answers = res.data.results;
      },
      err => {
        if (err.statusText === 'Unauthorized' || err.status === false || err.message === 'Ooops something went Wrong.') {
          this.userService.setIsLoggedOut();
        }
      }
    );
  }

  addAnswer(): void {
    const choice_value = this.answers.length + 1;
    this.selectedAnswer = <Answer>{}
    this.selectedAnswer.choice_value = choice_value.toString();
    this.selectedAnswer.question = this.question.id
    this.editing = true
  }

  answerSubmittedHandler(answer: Answer): void {
    this.editing = false;
    this.getAnswers(this.id);
  }

  deleteAnswer(answer: Answer): void {
    this.answerservice.deleteAnswer(answer.id)
      .subscribe(
        (res) => {
          this.getAnswers(this.id);
        },
        err => {
          if (err.statusText === 'Unauthorized' || err.status === false || err.message === 'Ooops something went Wrong.') {
            this.userService.setIsLoggedOut();
          }
        }
      );
  }

  goBack(): void {
    this.location.back();
  }

  openQuestionDialog(): void {
    this.title = 'About to delete question below!';
    this.content = this.question.description;
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '350px',
      data: {title: this.title, content: this.content}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.deleteQuestion();
      }
    });
  }

  openAnswerDialog(answer: Answer): void {
    const ans = answer;
    this.title = 'About to delete answer below!';
    this.content = ans.description;
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '350px',
      data: {title: this.title, content: this.content}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.deleteAnswer(ans);
      }
    });
  }


  getQuestionStats(id: string) {
    this.restclientService.getQuestionStats(id)
      .subscribe(res => {
          this.groupStats = res.data;
        },
        err => {
          if (err.statusText === 'Unauthorized' || err.status === false || err.message === 'Ooops something went Wrong.') {
            this.userService.setIsLoggedOut();
          }
        });
  }

  editAnswer(answer: Answer) {
    this.selectedAnswer = answer
    this.editing = true
  }

  questionSubmittedHandler(question: Question): void {
    this.editing = false;
    this.question = question;
    // this.question.survey = this.id;
    this.getQuestion(this.id);
  }
}
