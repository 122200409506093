import {Component, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../../user.service';
import {MatDialog} from '@angular/material';
import {DialogComponent} from '../../shared/dialog/dialog.component';
import {AnswerService} from '../answer.service';
import {Answer} from '../interfaces/answer';

@Component({
  selector: 'app-answer',
  templateUrl: './answer.component.html',
  styleUrls: ['./answer.component.sass']
})
export class AnswerComponent implements OnInit {
  private answer: Answer;
  private id: string;
  fetchingAnswer: boolean;
  private title: string;
  private content: string;

  constructor(private activatedRoute: ActivatedRoute,
              private restclientService: AnswerService,
              private router: Router,
              private answerService: AnswerService,
              private userService: UserService,
              private location: Location,
              private dialog: MatDialog) {
    this.answer = <Answer>{};
  }

  ngOnInit() {
    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    if (this.id) {
      this.getAnswer(this.id);
    }
  }


  getAnswer(id: string): void {
    this.fetchingAnswer = true;
    this.restclientService.answer(id)
      .subscribe(res => {
        this.fetchingAnswer = false;
        this.answer = res.data;
      }, err => {

        if (err.statusText === 'Unauthorized' || err.status === false || err.message === 'Ooops something went Wrong.') {
          this.userService.setIsLoggedOut();
        }
      });
  }

  editAnswer(): void {
    this.router.navigate([`answers/edit/${this.id}`]);
  }

  deleteAnswer(): void {
    this.restclientService.deleteAnswer(this.answer.id)
      .subscribe(
        (res) => {
          this.goBack();
        },
        err => {

          if (err.statusText === 'Unauthorized' || err.status === false || err.message === 'Ooops something went Wrong.') {
            this.userService.setIsLoggedOut();
          }
        }
      );
  }


  openAnswerDialog(): void {
    this.title = 'About to delete answer below!';
    this.content = this.answer.description;
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '350px',
      data: {title: this.title, content: this.content}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.deleteAnswer();
      }
    });
  }

  goBack(): void {
    this.location.back();
  }

}
