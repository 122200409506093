import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {UserService} from '../../user.service';
import {ActivatedRoute, Router} from '@angular/router';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Location} from '@angular/common';
import {AnswerService} from '../answer.service';
import {Answer} from '../interfaces/answer';
import {dashCaseToCamelCase} from '@angular/compiler/src/util';

@Component({
  selector: 'app-add-edit-answer',
  templateUrl: './add-edit-answer.component.html',
  styleUrls: ['./add-edit-answer.component.sass']
})
export class AddEditAnswerComponent implements OnInit, OnChanges {


  @Output() answerSubmitted: EventEmitter<Answer> = new EventEmitter();

  @Input() answer: Answer;
  answerToEdit: Answer;
  showAnswerError: boolean;
  addAnswer: boolean;
  answerForm: FormGroup;
  id: string;
  asChild: boolean;

  constructor(private restclientService: AnswerService,
              private userService: UserService,
              private answerService: AnswerService,
              private activatedRoute: ActivatedRoute,
              private location: Location,
              private router: Router) {
    this.answer = <Answer>{};
    this.answerToEdit = <Answer>{};
    this.showAnswerError = false;
    this.addAnswer = true;
    this.asChild = false;
    this.initializeForm();
  }

  ngOnInit() {
    /**this.id = this.activatedRoute.snapshot.paramMap.get('id');
    if (this.id !== null && this.id !== undefined) {
      this.getAnswer();
    } else {
      this.answerToEdit = this.answer;
      if (this.answer !== undefined) {
        this.editAnswer(this.answer);
      }
    }**/

  }

  ngOnChanges(changes: SimpleChanges): void {
   if(this.answer) {
     this.answerToEdit = this.answer
     console.log(this.answerToEdit)
     this.editAnswer(this.answer)
   }



  }

  getAnswer(): void {
    this.restclientService.answer(this.id)
      .subscribe(res => {
        this.addAnswer = false;
        this.answerToEdit = res.data;
        this.editAnswer(res.data);
      });
  }


  initializeForm() {
    this.answerForm = new FormGroup({
      description: new FormControl('', Validators.required)
    });

    this.answerForm.valueChanges.subscribe(value => {
      // console.log(value);
    });
  }

  editAnswer(answer: Answer): void {
    if (answer.id !== null && answer.id !== undefined) {
      this.addAnswer = false;
    } else {
      this.addAnswer = true;
      this.answerToEdit = answer;
    }
    console.log(this.answer.description)
    this.answerForm.controls['description'].patchValue(this.answer.description)

  }

  createAnswer(answer: Answer): void {

    if (this.answerToEdit.id !== null && this.answerToEdit.id !== undefined) {
      this.answer.id = this.answerToEdit.id;
    }
    this.answer.description = answer.description;

    if (this.answer.id !== null && this.answer.id !== undefined) {
      this.restclientService.updateAnswer(this.answer)
        .subscribe(
          (res) => {
            this.goBack();
          },
          err => {

          }
        );
    } else {

      this.restclientService.createAnswer(this.answer)
        .subscribe(
          (res) => {
             this.goBack();
          }
        );
    }
  }

  goBack() {
    this.answerSubmitted.emit(this.answer);
  }
}
