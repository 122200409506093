import { NgModule } from "@angular/core";
import {
  MatAutocompleteModule,
  MatBadgeModule,
  MatButtonModule,
  MatCardModule,
  MatChipsModule,
  MatDialogModule,
  MatExpansionModule,
  MatIconModule,
  MatInputModule,
  MatMenuModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatSelectModule,
  MatSidenavModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,
  MatCheckboxModule
} from "@angular/material";

@NgModule({
  exports: [
    MatAutocompleteModule,
    MatButtonModule,
    MatToolbarModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatCardModule,
    MatSlideToggleModule,
    MatIconModule,
    MatSelectModule,
    MatProgressBarModule,
    MatTabsModule,
    MatRadioModule,
    MatSidenavModule,
    MatExpansionModule,
    MatDialogModule,
    MatChipsModule,
    MatSnackBarModule,
    MatBadgeModule,
    MatTableModule,
    MatMenuModule,
    MatTooltipModule,
    MatCheckboxModule
  ]
})
export class MaterialModule {}
