import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {RestclientService} from '../../restclient.service';
import {UserService} from '../../user.service';
import {Question} from '../../questions/interfaces/question';
import {Survey} from '../interfaces/survey';
import {NotificationService} from '../../notification.service';

@Component({
  selector: 'app-questionnaire',
  templateUrl: './questionnaire.component.html',
  styleUrls: ['./questionnaire.component.sass']
})
export class QuestionnaireComponent implements OnInit {
  public id: string;
  public questions: Question[];
  public fetchingSurvey: boolean;
  public survey: Survey;
  public value: number;
  mode = 'indeterminate';
  loadMsg = 'Loading the Survey...';

  constructor(
    private activatedRoute: ActivatedRoute,
    private restclientService: RestclientService,
    private userService: UserService,
    private notificationService: NotificationService) {
    this.fetchingSurvey = true;
    this.questions = [];
    this.survey = <Survey>{};
  }

  ngOnInit() {
    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    if (this.id) {
      this.getSurvey(this.id);
      this.getQuestions(this.id);
    }
  }

  getSurvey(id: string): void {
    this.restclientService.survey(id)
      .subscribe(res => {
          this.fetchingSurvey = false;
          this.survey = res.data;
        },
        err => {
          this.mode = 'determinate';
          this.loadMsg = 'Something went wrong while fetching the respondent...';
          this.value = 5;
          this.notificationService.showSnackBar(err.error.data.detail, 'Dismiss', 3000);
          if (err.statusText === 'Unauthorized' || err.status === false || err.message === 'Ooops something went Wrong.') {
            this.userService.setIsLoggedOut();
          }
        });
  }

  private getQuestions(id: string) {
    this.restclientService.surveyQuestions(id).subscribe(
      res => {
        this.questions = res.data.results;
      },
      err => {
        if (err.statusText === 'Unauthorized' || err.status === false || err.message === 'Ooops something went Wrong.') {
          this.userService.setIsLoggedOut();
        }
      }
    );
  }

}
