import { BrowserModule } from "@angular/platform-browser";
import {
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
  NO_ERRORS_SCHEMA
} from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MDBBootstrapModule } from "angular-bootstrap-md";
import { AppComponent } from "./app.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MaterialModule } from "./material.module";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { SettingsComponent } from "./about/settings/settings.component";
import { DashboardtilesService } from "./dashboardtiles.service";
import { AboutComponent } from "./about/about.component";
import { AuthComponent } from "./auth/auth.component";
import { AuthLoginComponent } from "./auth/login/auth.login.component";
import { AuthRegisterComponent } from "./auth/register/auth.register.component";
import { AuthGuard } from "./auth.guard";
import { UserService } from "./user.service";
import { MyNavbarComponent } from "./navbar/my-navbar-component.module";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { RestclientService } from "./restclient.service";
import { CookieService } from "ngx-cookie-service";
import { ProfileComponent } from "./profile/profile.component";
import { AppRoutingModule } from "./app.routing.module";
import { EditProfileComponent } from "./profile/edit-profile/edit-profile.component";
import { AnswersComponent } from "./answers/answers.component";
import { AnalyticsComponent } from "./analytics/analytics.component";
import { MappingComponent } from "./mapping/mapping.component";
import { GroupsComponent } from "./groups/groups.component";
import {SendsurveyComponent, SendSurveyModalComponent} from './sendsurvey/sendsurvey.component';
import { RespondentComponent } from "./respondents/respondent/respondent.component";
import { QuestionsComponent } from "./questions/questions.component";
import { RespondentsComponent } from "./respondents/respondents.component";
import { QuestionComponent } from "./questions/question/question.component";
import { SurveyComponent } from "./surveys/survey/survey.component";
import { SurveysComponent } from "./surveys/surveys.component";
import { GroupAddEditComponent } from "./groups/group-add-edit/group-add-edit.component";
import { RespondentAddEditComponent } from "./respondents/respondent-add-edit/respondent-add-edit.component";
import { SurveyAddEditComponent } from "./surveys/survey-add-edit/survey-add-edit.component";
import {QuestionAddEditComponent, QuestionEditComponent} from './questions/question-add-edit/question-add-edit.component';
import { AddEditAnswerComponent } from "./answers/add-edit-answer/add-edit-answer.component";
import { OwlDateTimeModule, OwlNativeDateTimeModule } from "ng-pick-datetime";
import { GroupComponent } from "./groups/group/group.component";
import { WeatherService } from "./weather.service";
import { AgmCoreModule } from "@agm/core";
import { StatisticsComponent } from "./respondents/statistics/statistics.component";
import { DialogComponent } from "./shared/dialog/dialog.component";
import { SearchComponent } from "./search/search.component";
import { VerifyDialogComponent } from "./profile/verify-dialog/verify-dialog.component";
import { ContactUsComponent } from "./about/contact-us/contact-us.component";
import { AnswerComponent } from "./answers/answer/answer.component";
import { SurveysPipe } from "./pipes/surveys.pipe";
import { QuestionsPipe } from "./pipes/questions.pipe";
import { AnswersPipe } from "./pipes/answers.pipe";
import { RespondentsPipe } from "./pipes/respondents.pipe";
import { GroupsPipe } from "./pipes/groups.pipe";
import { CountdownModule } from "ngx-countdown";
import { PollingAppPipe } from "./polling-app.pipe";
import { ArchiveComponent } from "./archive/archive.component";
import { SearchdetailComponent } from "./search/searchdetail/searchdetail.component";
import { TokenInterceptor } from "./interceptor";
import { ForgotComponent } from "./auth/forgot/fogort.component";
import { ForgotUpdateComponent } from "./auth/forgotupdate/forgotupdate.component";
import { EqualtoDirective } from "./directives/equalto.directive";
import { ChangepassComponent } from "./profile/changepass/changepass.component";
import { SummaryComponent } from "./answers/summary/summary.component";
import { PasswordCheckComponent } from "./shared/passwordcheck/passwordcheck.component";
import { ChartyComponent } from "./charty/charty.component";
import { ResponsesComponent } from "./surveys/responses/responses.component";
import { InvoicesComponent } from "./surveys/invoices/invoices.component";
import { QuestionnaireComponent } from "./surveys/questionnaire/questionnaire.component";
import { SmsComponent } from "./sms/sms.component";
import { SurveyStatisticsComponent } from "./surveys/survey-statistics/survey-statistics.component";
import { QuestionStatisticsComponent } from "./questions/question-statistics/question-statistics.component";
import { DeleteComponent } from "./profile/delete/delete.component";
import {SendSmsComponent, SendSmsModalComponent} from './send-sms/send-sms.component';
import { ngfModule } from "angular-file";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { ImportComponent } from "./import/import.component";
import { GroupsStatsComponent } from "./groups/groups-stats/groups-stats.component";
import { GlobalSearchComponent } from "./search/global-search/global-search.component";
import { FaqComponent } from "./about/faq/faq.component";
import { HomeComponent } from "./about/home/home.component";
import { PricingComponent } from "./about/pricing/pricing.component";
import { FeaturesComponent } from "./about/features/features.component";
import { BenefitsComponent } from './about/benefits/benefits.component';
import { TermsOfServiceComponent } from './about/terms-of-service/terms-of-service.component';
import { PrivacyPolicyComponent } from './about/privacy-policy/privacy-policy.component';
import { AntiSpamPolicyComponent } from './about/anti-spam-policy/anti-spam-policy.component';
import { SmsStatisticsComponent } from './about/sms-statistics/sms-statistics.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import {InactivesurveysComponent} from './surveys/inactivesurveys/inactivesurveys.component';
import { InternationalPhoneNumberModule } from 'ngx-international-phone-number';
import {IncompleteSurvey} from './surveys/pipes';

@NgModule({
  entryComponents: [
    DialogComponent,
    VerifyDialogComponent,
    PasswordCheckComponent,
    DeleteComponent,
    SmsComponent,
    SendSmsComponent,
    SendSmsModalComponent,
    SendSurveyModalComponent
  ],
  declarations: [
    AppComponent,
    DashboardComponent,
    SettingsComponent,
    AboutComponent,
    AuthComponent,
    AuthLoginComponent,
    AuthRegisterComponent,
    MyNavbarComponent,
    ProfileComponent,
    EditProfileComponent,
    SurveysPipe,
    QuestionsPipe,
    PollingAppPipe,
    AnswersPipe,
    RespondentsPipe,
    GroupsPipe,
    AnswersComponent,
    AnalyticsComponent,
    MappingComponent,
    GroupsComponent,
    SendsurveyComponent,
    RespondentsComponent,
    RespondentComponent,
    RespondentAddEditComponent,
    SurveysComponent,
    SurveyComponent,
    SurveyStatisticsComponent,
    QuestionsComponent,
    QuestionComponent,
    QuestionStatisticsComponent,
    GroupAddEditComponent,
    QuestionEditComponent,
    SurveyAddEditComponent,
    QuestionAddEditComponent,
    AddEditAnswerComponent,
    GroupComponent,
    StatisticsComponent,
    DialogComponent,
    VerifyDialogComponent,
    SearchComponent,
    ContactUsComponent,
    AnswerComponent,
    ArchiveComponent,
    SearchdetailComponent,
    ForgotComponent,
    ForgotUpdateComponent,
    EqualtoDirective,
    ChangepassComponent,
    SummaryComponent,
    PasswordCheckComponent,
    ChartyComponent,
    ResponsesComponent,
    InvoicesComponent,
    QuestionnaireComponent,
    SmsComponent,
    DeleteComponent,
    SendSmsComponent,
    ImportComponent,
    GroupsStatsComponent,
    GlobalSearchComponent,
    FaqComponent,
    HomeComponent,
    PricingComponent,
    FeaturesComponent,
    BenefitsComponent,
    TermsOfServiceComponent,
    PrivacyPolicyComponent,
    AntiSpamPolicyComponent,
    SmsStatisticsComponent,
    SendSmsModalComponent,
    SendSurveyModalComponent,
    InactivesurveysComponent,
    IncompleteSurvey,
  ],
  imports: [
    BrowserModule,
    MDBBootstrapModule.forRoot(),
    BrowserAnimationsModule,
    MaterialModule,
    FormsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    CountdownModule,
    ngfModule,
    NgxMatSelectSearchModule,
    InternationalPhoneNumberModule,
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyCLvkofaeJjDvYwAtiP-sBy0Q8sm4gUO7k"
    })
  ],
  schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    CookieService,
    DashboardtilesService,
    AuthGuard,
    UserService,
    RestclientService,
    WeatherService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}

// platformBrowserDynamic().bootstrapModule(AppModule);
