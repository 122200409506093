import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {RestclientService} from '../../restclient.service';

@Component({
  selector: 'app-delete-dialog',
  templateUrl: './delete.component.html'
})
export class DeleteComponent implements OnInit {
  public sendOTP = false;
  public code: string;

  ngOnInit() {
  }

  constructor(
    public dialogRef: MatDialogRef<DeleteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public rest: RestclientService) {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }


  delete() {
    this.rest.deleteUser(this.code).subscribe(
      (data) => {
        this.dialogRef.close({'is_deleted': true});
      },
      (error) => {
        this.dialogRef.close({'is_deleted': false});
      }
    );
  }

}
