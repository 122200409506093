import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {UserService} from '../user.service';
import {QuestionService} from './question.service';
import {Question} from './interfaces/question';

@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.sass']
})
export class QuestionsComponent implements OnInit {
  questions: Question[];
  term: string;
  fetchingQuestions: boolean;

  constructor(private restclientService: QuestionService,
              private router: Router,
              private userService: UserService) {
    this.questions = [];
    this.term = '';
  }

  ngOnInit() {
    this.fetchingQuestions = true;
    this.restclientService.questions().subscribe(
      res => {
        this.fetchingQuestions = false;
        this.questions = res.data.results;
      },
      err => {
        this.fetchingQuestions = false;

        if (err.statusText === 'Unauthorized' || err.status === false || err.message === 'Ooops something went Wrong.') {
          this.userService.setIsLoggedOut();
        }
      }
    );
  }

}
