import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {DialogComponent} from '../../shared/dialog/dialog.component';
import {SurveyService} from '../survey.service';
import {QuestionService} from '../../questions/question.service';
import {Survey} from '../interfaces/survey';
import {NotificationService} from '../../notification.service';
import {SendsurveyComponent} from '../../sendsurvey/sendsurvey.component';
import {MatDialog} from '@angular/material';
import {FormControl} from '@angular/forms';
import {switchMap, debounceTime, finalize} from 'rxjs/operators';
import {Pagination} from '../../interfaces/pagination';

@Component({
  selector: 'app-inactivesurveys',
  templateUrl: './inactivesurveys.component.html',
  styleUrls: ['./inactivesurveys.component.css']
})
export class InactivesurveysComponent implements OnInit {
  surveys: Survey[]
  fetchingSurveys: boolean
  page = 1
  size = 10
  next_page = 0
  previous_page = 0
  total_page = 0
  title = ''
  content = ''
  allinActiveSelected: boolean;
  IsinActiveIndeterminate: boolean;
  searchForm: FormControl;
  survey: Survey;
  isNext : boolean;
  isPrevious: boolean;
  numOfPages = [10, 20, 50, 100, 1000];
  @Output() surveyToEdit = new EventEmitter<Survey>();
  @Output()  inactiveSurveyCount = new EventEmitter<number>();
  @Output()  isNew  = new EventEmitter<boolean>();

  constructor(public surveyService: SurveyService, public dialog: MatDialog, public notification: NotificationService) {
      this.searchForm = new FormControl()
    this.surveys = []
  }

  ngOnInit() {
    this.fetchSurveys()
    this.searchForm.valueChanges.pipe(
      debounceTime(300),
      switchMap(value => this.surveyService.inactiveSurveys(1, this.size, value))
    ).subscribe((data) => {
      console.log(data)
      this.surveys = data.data.results;
      // this.inactiveSurveyCount.emit(this.surveys.length)
    })
  }

  changeSize($event) {
    this.page = 1;
    this.size = $event.target.value;
    this.fetchSurveys();
  }


  fetchSurveys() {
    this.fetchingSurveys = true;
    this.surveyService.inactiveSurveys(this.page, this.size).pipe(
      finalize(() => {
        this.fetchingSurveys = false
      })
    ).subscribe( (res) => {
        console.log(res)
        this.surveys = res.data.results;
        this.inactiveSurveyCount.emit(this.surveys.length)
        this.pagiationData(res.data.pagination)
      }
    );
  }

  pagiationData(data: Pagination) {

    this.page = data.current_page;
    this.total_page = data.num_pages;

    if (this.page <= 1) {
      this.previous_page = 1;
      this.isPrevious = false;
    } else {
      this.previous_page = this.page - 1;
      this.isPrevious = true;
    }

    if (this.page < this.total_page) {
      this.next_page = this.page + 1;
      this.isNext = true;
    } else {
      this.isNext = false;
    }
  }
  getMoreRespondents(type: string) {
    if (type === "previous") {
      this.page = this.getPreviousPage();
      this.fetchSurveys();
    } else {
      this.page = this.getNextPage();
      this.fetchSurveys();
    }
  }

  getNextPage() {
    this.next_page = this.page + 1;
    if (this.next_page > this.total_page) {
      this.next_page = this.total_page;
    }
    return this.next_page;
  }

  getPreviousPage() {
    if (this.page === 1) {
      this.previous_page = this.page;
    } else {
      this.previous_page = this.page - 1;
    }
    return this.previous_page;
  }

  inactivesurveyChanged(survey, event) {
    survey.isChecked = event.checked;

    const totalSelected = this.surveys.filter(
      survey => survey.isChecked
    ).length;
    if (totalSelected === 0) {
      this.allinActiveSelected = false;
      this.IsinActiveIndeterminate = false;
    } else if (
      totalSelected > 0 &&
      totalSelected < this.surveys.length
    ) {
      this.allinActiveSelected = false;
      this.IsinActiveIndeterminate = true;
    } else if (totalSelected === this.surveys.length) {
      this.allinActiveSelected = true;
      this.IsinActiveIndeterminate = false;
    }
  }

  toggleinactiveSelectAll(event) {
    this.allinActiveSelected = event.checked;
    this.surveys.forEach(survey => {
      survey.isChecked = event.checked;
    });
  }

  openDeleteSurveyDialog(survey: Survey): void {
    this.title = 'Delete survey below?';
    this.content = survey.title;
    this.deleteModal([survey]);

  }

  deleteModal(surveys: Survey[]) {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '350px',
      data: {title: this.title, content: this.content}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.deleteSurvey(surveys);
      }
    });
  }

  deleteSurvey(surveys: Survey[]): void {
    this.surveyService.deleteSurveys(surveys).pipe(
      finalize( () => {
        this.fetchSurveys();
      })
    ).subscribe(
      (res) => {
          this.notification.showSnackBar('Deleted successfully', 'dismiss', 300)
      });
  }

  openDeleteSurveys() {
    this.title = 'Delete Surveys';
    this.content = 'Are you sure you want to delete all this surveys';
    const surveysToDelete = this.surveys.filter((survey) => survey.isChecked);
    this.deleteModal(surveysToDelete);
  }

  openActivateSurveyDialog(survey: Survey): void {
    this.survey = survey;
    this.title = 'Initiate survey now...';
    this.content = this.survey.title;
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '350px',
      data: {
        title: this.title,
        content: this.content,
        label: 'Initiate',
        class: 'btn-green'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.activateSurvey();
      }
    });
  }

  activateSurvey() {
    this.survey.is_active = true;
    this.surveyService.activateSurvey({survey: this.survey.id}).pipe(
      finalize(() => {
         this.fetchSurveys()
      })
    ).subscribe( (data) => {
      this.notification.showSnackBar('Activated successfully', 'dismiss', 300)
    });
  }

  editSurvey(survey: Survey) {
    this.surveyToEdit.emit(survey)
  }

  addSurvey() {
    this.isNew.emit(true)
  }

  openActivateSurveysDialog() {
    this.title = 'Initiate survey now...';
    this.content = 'Are you sure you want to initiate this surveys';
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '350px',
      data: {
        title: this.title,
        content: this.content,
        label: 'Initiate',
        class: 'btn-green'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const surveyToIntiate = [];
        this.surveys.filter((survey) => survey.isChecked).forEach((survey) => {
          surveyToIntiate.push({'survey': survey.id});
        });
        this.surveyService.activateSurvey(surveyToIntiate).pipe(
          finalize(() => {
            this.fetchSurveys();
          })).subscribe((data) => {
          this.notification.showSnackBar('Surveys intiated successfully', 'dismiss', 3000);
        });
      }
    })
  }

}
