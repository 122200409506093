import { Component, OnInit } from "@angular/core";
import { UserService } from "../user.service";
import { DialogComponent } from "../shared/dialog/dialog.component";
import { MatDialog } from "@angular/material";
import { SurveyService } from "../surveys/survey.service";
import { Survey } from "../surveys/interfaces/survey";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-search",
  templateUrl: "./search.component.html",
  styleUrls: ["./search.component.sass"]
})
export class SearchComponent implements OnInit {
  surveys: Survey[];
  search: string;
  fetchingSurveys: boolean;
  survey: Survey;
  title: string;
  content: string;
  num_of_pages = [10, 20, 50, 100];
  size = 10;
  page = 1;
  previous_page = 1;
  next_page = 1;
  total_page: number;
  private searchText: string;
  allSelected: boolean;
  IsIndeterminate: boolean;

  constructor(
    private activatedRoute: ActivatedRoute,
    private restclientService: SurveyService,
    private userService: UserService,
    private dialog: MatDialog
  ) {
    this.surveys = [];
    this.search = "";
    this.survey = <Survey>{};
  }

  ngOnInit() {
    this.searchText = this.activatedRoute.snapshot.paramMap.get("s");
    if (this.searchText) {
      this.search = this.searchText;
      this.onChange(this.searchText);
    } else {
      this.fetchSurveys();
    }
  }

  onChange($e) {
    if ($e.length > 2) {
      this.page = 1;
      this.fetchSurveys(this.search);
    } else if ($e.length === 0) {
      this.fetchSurveys();
    }
  }

  private fetchSurveys(searchText: string = "") {
    this.fetchingSurveys = true;
    this.restclientService
      .allSurveys(this.page, this.size, searchText)
      .subscribe(
        res => {
          this.fetchingSurveys = false;
          this.surveys = res.data.results;
          this.total_page = res.data.pagination.num_pages;
          this.page = res.data.pagination.current_page;
          this.getNextPage();
          this.getPreviousPage();
        },
        err => {
          this.fetchingSurveys = false;
          if (
            err.statusText === "Unauthorized" ||
            err.status === false ||
            err.message === "Ooops something went Wrong."
          ) {
            this.userService.setIsLoggedOut();
          }
        }
      );
  }

  MoreSurveys(event) {
    this.size = event.target.value;
    this.page = 1;
  }

  getMoreSurveys(type: string) {
    if (type === "previous") {
      this.page = this.getPreviousPage();
      this.fetchSurveys();
    } else {
      this.page = this.getNextPage();
      this.fetchSurveys();
    }
  }

  getNextPage() {
    this.next_page = this.page + 1;
    if (this.next_page > this.total_page) {
      this.next_page = this.total_page;
    }
    return this.next_page;
  }

  getPreviousPage() {
    if (this.page === 1) {
      this.previous_page = this.page;
    } else {
      this.previous_page = this.page - 1;
    }

    return this.previous_page;
  }

  openRecycleSurveyDialog(survey: Survey) {
    this.survey = survey;
    this.title = "Clone survey to the Hub...";
    this.content = this.survey.title;
    const dialogRef = this.dialog.open(DialogComponent, {
      width: "350px",
      data: {
        title: this.title,
        content: this.content,
        label: "Clone",
        class: "btn-green"
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.recycleSurvey();
      }
    });
  }

  openRecyleandIntiate(survey: Survey) {
    this.survey = survey;
    this.title = "Initiate Survey now...";
    this.content = this.survey.title;
    const dialogRef = this.dialog.open(DialogComponent, {
      width: "350px",
      data: {
        title: this.title,
        content: this.content,
        label: "Initiate",
        class: "btn-green"
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.recycleActivateSurvey();
      }
    });
  }

  private recycleActivateSurvey() {
    this.restclientService
      .recycleSurvey({ survey: this.survey.id, is_initiate: 1 })
      .subscribe(
        res => {
          // this.fetchSurveys();
        },
        err => {
          if (
            err.statusText === "Unauthorized" ||
            err.status === false ||
            err.message === "Ooops something went Wrong."
          ) {
            this.userService.setIsLoggedOut();
          }
        }
      );
  }

  private recycleSurvey() {
    this.restclientService.recycleSurvey({ survey: this.survey.id }).subscribe(
      res => {
        // this.fetchSurveys();
      },
      err => {
        if (
          err.statusText === "Unauthorized" ||
          err.status === false ||
          err.message === "Ooops something went Wrong."
        ) {
          this.userService.setIsLoggedOut();
        }
      }
    );
  }

  OnChange($event) {
    console.log($event);
    //MatCheckboxChange {checked,MatCheckbox}
  }

  OnIndeterminateChange($event) {
    console.log($event);
    //true or false
  }
  surveyChanged(survey, event) {
    survey.isChecked = event.checked;

    let totalSelected = this.surveys.filter(i => i.isChecked).length;
    if (totalSelected === 0) {
      this.allSelected = false;
      this.IsIndeterminate = false;
    } else if (totalSelected > 0 && totalSelected < this.surveys.length) {
      this.allSelected = false;
      this.IsIndeterminate = true;
    } else if (totalSelected === this.surveys.length) {
      this.allSelected = true;
      this.IsIndeterminate = false;
    }
  }

  toggleSelectAll(event) {
    this.allSelected = event.checked;
    this.surveys.forEach(survey => {
      survey.isChecked = event.checked;
    });
  }
}
