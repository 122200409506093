import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {PasswordCheckService} from './passwordcheck.service';

@Component({
  selector: 'app-password',
  templateUrl: './passwordcheck.component.html',
  styleUrls: ['./passwordcheck.component.sass']
})
export class PasswordCheckComponent implements OnInit {
  password: string;
  is_passsword_verified: boolean;

  ngOnInit() {
  }

  constructor(
    public dialogRef: MatDialogRef<PasswordCheckComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public service: PasswordCheckService) {
  }

  close(): void {
    this.dialogRef.close();
  }

  check() {
    this.service.check(this.password).subscribe((data) => {
        this.is_passsword_verified = true;
        this.dialogRef.close({'is_passsword_verified': this.is_passsword_verified});
      },
      (error) => {
        if (error.status === 400) {
          this.dialogRef.close({'is_passsword_verified': this.is_passsword_verified});
        }
      });
  }

}
