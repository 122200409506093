import {Component, OnInit} from '@angular/core';
import {UserService} from '../../user.service';
import {RestclientService} from '../../restclient.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Location} from '@angular/common';
import {notEqual, passwordValidator} from '../../validators/notEqual';
import {RespondentService} from '../../respondents/respondent.service';
import {ChangepasswordService} from './changepass.service';
import {Router} from '@angular/router';
import {User} from '../../auth/interfaces/user';

@Component({
  selector: 'app-change-pass',
  templateUrl: './changepass.component.html',
  styleUrls: ['./changepass.component.sass']
})
export class ChangepassComponent implements OnInit {

  processing: boolean;
  user_id: string;
  user: User;
  changePassForm: FormGroup;
  showdError: boolean;

  constructor(private userService: UserService,
              private restclientService: RestclientService,
              private respondents: RespondentService,
              private location: Location,
              private router: Router,
              private service: ChangepasswordService) {
    this.processing = false;
    this.user = <User>{};
    this.user_id = this.userService.user_id;
  }

  ngOnInit() {
    this.changePassForm = new FormGroup({
      old_password: new FormControl('', Validators.required),
      password: new FormControl('', [Validators.required, Validators.minLength(5), Validators.maxLength(25), passwordValidator]),
      confirm_password: new FormControl('', [Validators.required, notEqual]),
    });
  }

  updatepass(post): void {
    this.service.change(post).subscribe(
      res => {
        alert('password changed succesfully');
        this.router.navigate(['/profile']);
      },
      err => {

        if (err.error.data.old_password) {
          alert('Invalid Old Password');
        }
      });
  }
}
