import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {RestclientService} from '../../restclient.service';
import {UserService} from '../../user.service';
import {DialogComponent} from '../../shared/dialog/dialog.component';
import {MatDialog} from '@angular/material';
import {NotificationService} from '../../notification.service';
import {Location} from '@angular/common';
import {QuestionService} from '../../questions/question.service';
import {AnalyticsService} from '../../analytics/analytics.service';
import {SurveyService} from '../survey.service';
import {ChartyData} from '../../charty/charty-data';
import {Question} from '../../questions/interfaces/question';
import {Survey} from '../interfaces/survey';
import {Surveyresponse} from '../interfaces/surveyresponse';

@Component({
  selector: 'app-survey-statistics',
  templateUrl: './survey-statistics.component.html',
  styleUrls: ['./survey-statistics.component.scss']
})
export class SurveyStatisticsComponent implements OnInit, AfterViewInit {
  @Input() survey: Survey;
  mode = 'indeterminate';
  loadMsg = 'Loading the Survey...';
  id: string;
  chart = [];
  public questions: Question[];
  public selectedQuestion: Question;
  private editing: boolean;
  private question: Question;
  fetchingSurvey: boolean;
  private title: string;
  private content: string;
  public bills: any;
  private analytics_data: any;
  private value: number;
  survey_responses: Surveyresponse[];

  public RespondenseData: ChartyData;
  public RespondensePerChoice: ChartyData;
  public RespondentsPerQuestion: ChartyData;
  public ResponsesByLocation: ChartyData;
  public ResponsesByGender: ChartyData;
  public ResponsesByAgeGroup: ChartyData;

  loaded: boolean;
  dataSet: boolean;
  rpqDataSet: boolean;
  rpcDataSet: boolean;
  rblDataSet: boolean;
  rbgDataSet: boolean;
  rbagDataSet: boolean;
  all_toggle: string;
  private show_all: boolean;


  constructor(private activatedRoute: ActivatedRoute,
              private restclientService: RestclientService,
              private router: Router,
              private userService: UserService,
              private dialog: MatDialog,
              private notificationService: NotificationService,
              private questionService: QuestionService,
              private location: Location,
              private surveyService: SurveyService,
              private analytics: AnalyticsService) {
    this.editing = false;
    this.survey = <Survey>{};
    this.selectedQuestion = <Question>{};
    this.questions = [];
    this.survey_responses = [];
    this.all_toggle = 'All';
    this.loaded = false;
    this.show_all = false;
    this.dataSet = false;
    this.rpqDataSet = false;
    this.rpcDataSet = false;
    this.rblDataSet = false;
    this.rbgDataSet = false;
    this.rbagDataSet = false;
    this.RespondenseData = <ChartyData>{};
    this.RespondentsPerQuestion = <ChartyData>{};
    this.RespondensePerChoice = <ChartyData>{};
    this.ResponsesByLocation = <ChartyData>{};
    this.ResponsesByGender = <ChartyData>{};
    this.ResponsesByAgeGroup = <ChartyData>{};
  }

  ngOnInit() {
    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    if (this.id) {
      this.fetchingSurvey = true;
      this.getSurvey(this.id);
      this.getQuestions(this.id);
      this.getResponses({filter: 'survey', id: this.id});
      this.selectedQuestion.survey = this.id;
      this.analytics_data = this.analytics.getSurveyresponsePerGroup(this.id);
      this.plotLocationData();
    }
  }

  ngAfterViewInit() {
  }

  getSurvey(id: string): void {
    this.restclientService.survey(id)
      .subscribe(res => {
          this.fetchingSurvey = false;
          this.survey = res.data;
          this.plotData();
          this.getBills();
        },
        err => {
          this.mode = 'determinate';
          this.loadMsg = 'Something went wrong while fetching the respondent...';
          this.value = 5;
          this.notificationService.showSnackBar(err.error.data.detail, 'Dismiss', 3000);
          if (err.statusText === 'Unauthorized' || err.status === false || err.message === 'Ooops something went Wrong.') {
            this.userService.setIsLoggedOut();
          }
        });
  }

  editSurvey(): void {
    this.router.navigate([`surveys/edit/${this.id}`]);
  }

  deleteSurvey(): void {
    this.restclientService.deleteSurvey(this.survey.id)
      .subscribe(
        (res) => {
          this.router.navigate(['surveys']);
        },
        err => {
          if (err.statusText === 'Unauthorized' || err.status === false || err.message === 'Ooops something went Wrong.') {
            this.userService.setIsLoggedOut();
          }
        }
      );
  }

  private getQuestions(id: string) {
    this.restclientService.surveyQuestions(id).subscribe(
      res => {
        this.questions = res.data.results;
      },
      err => {
        if (err.statusText === 'Unauthorized' || err.status === false || err.message === 'Ooops something went Wrong.') {
          this.userService.setIsLoggedOut();
        }
      }
    );
  }

  questionSubmittedHandler(question: Question): void {
    this.editing = false;
    this.question = question;
    this.question.survey = this.id;
    this.getQuestions(this.id);
  }

  editQuestion(question: Question): void {
    this.editing = true;
    this.selectedQuestion = question;
  }

  openSurveyDialog(): void {
    this.title = 'Delete survey below?';
    this.content = this.survey.title;
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '350px',
      data: {title: this.title, content: this.content}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.deleteSurvey();
      }
    });
  }

  private plotData() {
    let labels = [];
    let counts = [];

    for (const res of this.survey.total_respondents_per_questions) {
      counts.push(res.total);
      labels.push(res.description);
    }

    this.RespondentsPerQuestion.title = 'Respondents per question';
    this.RespondentsPerQuestion.type = 'pie';
    this.RespondentsPerQuestion.counts = counts;
    this.RespondentsPerQuestion.labels = labels;
    this.rpqDataSet = true;

    labels = [];
    counts = [];
    for (const res of this.survey.responses_per_choices) {
      labels.push(res.answer_id__description);
      counts.push(+res.answer_id__choice_value);
    }

    this.RespondensePerChoice.title = 'Respondense to all questions';
    this.RespondensePerChoice.type = 'pie';
    this.RespondensePerChoice.counts = counts;
    this.RespondensePerChoice.labels = labels;
    this.rpcDataSet = true;

    this.RespondenseData = this.RespondensePerChoice;
    this.dataSet = true;
  }

  private plotLocationData() {
    let labels = [];
    let counts = [];

    // location countounts
    this.analytics_data.subscribe(data => {
      const data_response = data.data;
      if (data_response.count_by_location) {
        for (const i of data_response.count_by_location) {
          counts.push(i.total);
          labels.push(i.location);
        }
      }

      this.ResponsesByLocation.title = 'Responses By Location';
      this.ResponsesByLocation.type = 'pie';
      this.ResponsesByLocation.counts = counts;
      this.ResponsesByLocation.labels = labels;
      this.rblDataSet = true;

      labels = [];
      counts = [];
      if (data_response.count_by_gender) {
        for (const i of data_response.count_by_gender) {
          counts.push(i.total);
          labels.push(i.gender);
        }

        this.ResponsesByGender.title = 'Responses By Gender';
        this.ResponsesByGender.type = 'pie';
        this.ResponsesByGender.counts = counts;
        this.ResponsesByGender.labels = labels;
        this.rbgDataSet = true;
      }

      labels = [];
      counts = [];
      if (data_response.count_by_age_group) {
        for (const i of data_response.count_by_age_group) {
          counts.push(i.total);
          labels.push(i.age_group__name);
        }

        this.ResponsesByAgeGroup.title = 'Responses By Age Group';
        this.ResponsesByAgeGroup.type = 'pie';
        this.ResponsesByAgeGroup.counts = counts;
        this.ResponsesByAgeGroup.labels = labels;
        this.rbagDataSet = true;
      }

    });
  }

  openDeleteQuestionDialog(question: Question): void {
    this.title = 'Delete question below?';
    this.content = question.description;
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '350px',
      data: {title: this.title, content: this.content}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.deleteQuestion(question.id);
      }
    });
  }

  deleteQuestion(id: string): void {
    this.questionService.deleteQuestion(id)
      .subscribe(
        (res) => {
          this.getQuestions(this.id);
        },
        err => {

          if (err.statusText === 'Unauthorized' || err.status === false || err.message === 'Ooops something went Wrong.') {
            this.userService.setIsLoggedOut();
          }
        }
      );
  }

  private getResponses(params: any) {
    this.restclientService.responses(params)
      .subscribe(res => {
          this.survey_responses = res.data.results;
        },
        err => {

          this.notificationService.showSnackBar(err.error.data.detail, 'Dismiss', 3000);
          if (err.statusText === 'Unauthorized' || err.status === false || err.message === 'Ooops something went Wrong.') {
            this.userService.setIsLoggedOut();
          }
        });
  }

  goBack(): void {
    this.location.back();
  }

  getBills() {
    this.surveyService.bills(this.survey.id).subscribe((data) => {
      this.bills = data.data.results;
    });
  }

  toggleAll() {
    if (this.show_all) {
      this.show_all = false;
      this.all_toggle = 'All';
    } else {
      this.show_all = true;
      this.all_toggle = 'Main';
    }
  }

  toggleView(view: string) {
    this.show_all = false;
    this.all_toggle = 'All';
    if (view === 'by_location') {
      this.RespondenseData = this.ResponsesByLocation;
    } else if (view === 'by_gender') {
      this.RespondenseData = this.ResponsesByGender;
    } else if (view === 'by_age_group') {
      this.RespondenseData = this.ResponsesByAgeGroup;
    } else {
      this.RespondenseData = this.RespondensePerChoice;
    }
  }

}
