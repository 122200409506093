import {Component, OnInit} from '@angular/core';
import {Tile} from '../models/tile';
import {DashboardtilesService} from '../dashboardtiles.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.sass']
})
export class DashboardComponent implements OnInit {
  tiles: Tile[];

  constructor(private dashboardtilesService: DashboardtilesService) {
  }

  ngOnInit() {
    this.getTiles();
  }

  getTiles() {
    this.dashboardtilesService.getAllTiles()
      .subscribe(tiles => this.tiles = tiles);
  }
}
