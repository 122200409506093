import { Component, OnInit } from '@angular/core';
import { UserService } from '../user.service';
import { Router } from '@angular/router';
import { DialogComponent } from '../shared/dialog/dialog.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { GroupService } from './group.service';
import { RespondentService } from '../respondents/respondent.service';
import { FileUploader } from 'ng2-file-upload';
import { environment } from '../../environments/environment';
import { Group } from './interfaces/group';
import { HttpClient, HttpEvent } from '@angular/common/http';
import { ExportToCsv } from 'export-to-csv';
import { Subscription } from 'rxjs/Subscription';
import {SendSmsModalComponent }  from '../send-sms/send-sms.component';
import { finalize } from 'rxjs/operators';
import {NotificationService} from '../notification.service';
import {SendSurveyModalComponent} from '../sendsurvey/sendsurvey.component';
import {Survey} from '../surveys/interfaces/survey';
const URL = environment.baseUrl + '/users/upload';

@Component({
  selector: 'app-groups',
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.sass']
})
export class GroupsComponent implements OnInit {
  public groups: Group[];
  public exportGroups: any[];
  private term: string;
  IsChecked: boolean;
  IsIndeterminate: boolean;
  LabelAlign: string;
  IsDisabled: boolean;
  selectedGroup: Group;
  group: Group;
  editing: boolean;
  fetchingGroups: boolean;
  search: string;
  title: string;
  content: string;
  public uploader: FileUploader = new FileUploader({
    url: URL,
    itemAlias: 'csv'
  });
  accept = '*';
  files: File[] = [];
  progress: number;
  url = URL;
  hasBaseDropZoneOver = false;
  httpEmitter: Subscription;
  httpEvent: HttpEvent<{}>;
  lastFileAt: Date;
  sendableFormData: FormData; // populated via ngfFormData directive

  currentPage = 1;
  totalPages = 1;
  nextPage = 1;
  previousPage = 1;
  numOfPages = [10, 20, 50, 100];
  size = 10;
  isNext: boolean;
  isPrevious: boolean;

  page = 1;
  private csvExporter: ExportToCsv;
  private fileName = 'chartext_teams';
  private templateFileName = 'chartext_teams_template';
  private fileTitle = 'Chartext Teams';

  options = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    showTitle: false,
    title: this.fileTitle,
    useTextFile: false,
    useBom: true,
    filename: this.fileName,
    useKeysAsHeaders: false,
    // <-- Won't work with useKeysAsHeaders present!
    headers: ['Team Name', 'Description', 'Total Respondents']
  };
  isVisibleImportGroups: Boolean;
  allSelected: boolean;

  constructor(
    private restclientService: GroupService,
    private router: Router,
    private userService: UserService,
    private dialog: MatDialog,
    private respondents: RespondentService,
    private http: HttpClient,
    public notificationService: NotificationService
  ) {
    this.groups = [];
    this.group = <Group>{};
    this.selectedGroup = this.group;
    this.term = '';
    this.editing = false;
    this.IsChecked = false;
    this.IsIndeterminate = false;
    this.LabelAlign = 'after';
    this.IsDisabled = false;
    this.allSelected = false;
  }

  ngOnInit() {
    this.isVisibleImportGroups = false;
    this.csvExporter = new ExportToCsv(this.options);
    this.fetchGroups();
    this.fetchingGroups = true;
    this.uploader.onAfterAddingFile = file => {
      file.withCredentials = false;
    };
    this.uploader.onCompleteItem = (
      item: any,
      response: any,
      status: any,
      headers: any
    ) => {
      alert('File uploaded successfully');
    };
  }

  onChange($event) {
    this.currentPage = 1;
    this.fetchGroups(this.currentPage, $event);
  }

  changeSize($event) {
    this.currentPage = 1;
    this.size = $event.target.value;
    this.fetchGroups(this.currentPage);
  }

  fetchGroups(page: number = 1, search: string = null) {
    this.restclientService.groups(page, this.size, search).subscribe(
      res => {
        this.fetchingGroups = false;
        this.groups = res.data.results;
        this.exportGroups = this.groups.map(obj => ({ ...obj }));
        this.exportGroups = this.exportGroups.map(function(item) {
          delete item.id;
          return item;
        });
        this.currentPage = res.data.pagination.current_page;
        this.totalPages = res.data.pagination.num_pages;
        this.isNext = false;
        this.isPrevious = false;
        if (this.currentPage === this.totalPages) {
          this.nextPage = this.totalPages;
        } else {
          this.nextPage = this.currentPage + 1;
        }
        if (this.currentPage <= 1) {
          this.previousPage = 1;
        } else {
          this.previousPage = this.currentPage - 1;
        }

        if (this.currentPage === this.totalPages) {
          this.isNext = true;
        }

        if (this.currentPage === 1) {
          this.isPrevious = true;
        }
      },
      err => {
        this.fetchingGroups = false;
        if (
          err.statusText === 'Unauthorized' ||
          err.status === false ||
          err.message === 'Ooops something went Wrong.' ||
          err.message === 'Ooops something went Wrong.'
        ) {
          this.userService.setIsLoggedOut();
        }
      }
    );
  }

  createGroup() {
    this.editing = true;
    this.selectedGroup = <Group>{};
    console.log(this.selectedGroup);
  }

  groupSubmittedHandler(saved: boolean): void {
    if (saved) {
      this.editing = false;
      this.fetchGroups();
    } else {
      this.editing = true;
    }
  }

  ExportGroups() {
    this.csvExporter.generateCsv(this.exportGroups);
  }

  ExportGroupsTemplate() {
    this.options.showTitle = false;
    this.options.headers = ['Team Name', 'Description'];
    this.options.filename = this.templateFileName;
    this.csvExporter = new ExportToCsv(this.options);
    const exportGroups = [
      {
        name: '',
        descriptions: ''
      }
    ];
    this.csvExporter.generateCsv(exportGroups);
  }

  openDeleteGroupDialog(group: Group): void {
    this.title = 'Delete group below?';
    this.content = group.name;
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '350px',
      data: { title: this.title, content: this.content }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.deleteGroup(group.id);
      }
    });
  }

  openDeleteGroups() {
    const groupsToDelete = this.groups.filter( group => group.isChecked);
    this.title = 'Delete group';
    this.content = 'Are you sure you want to delete this group';
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '350px',
      data: { title: this.title, content: this.content }
    });
    dialogRef.afterClosed().subscribe( result => {
      if (result) {
        this.restclientService.deleteGroups(groupsToDelete).pipe(
          finalize(() => {
            this.fetchGroups();
          })).subscribe((data) => {
          this.notificationService.showSnackBar('Groups deleted', 'Dismiss', 3000);
        });

      }
    });

  }

  deleteGroup(id: string): void {
    this.fetchingGroups = true;
    this.restclientService.deleteGroup(id).subscribe(
      res => {
        this.fetchGroups();
      },
      err => {
        if (
          err.statusText === 'Unauthorized' ||
          err.status === false ||
          err.message === 'Ooops something went Wrong.'
        ) {
          this.userService.setIsLoggedOut();
        }
      }
    );
  }


  getMoreGroups(page: string) {
    if (page === 'previous') {
      this.fetchGroups(this.previousPage);
    } else {
      this.fetchGroups(this.nextPage);
    }
  }

  cancel() {
    this.progress = 0;
    if (this.httpEmitter) {

      this.httpEmitter.unsubscribe();
    }
  }

  groupChanged(group, event) {
    group.isChecked = event.checked;

    const totalSelected = this.groups.filter( group => group.isChecked).length;
    if (totalSelected === 0) {
      this.allSelected = false;
      this.IsIndeterminate = false;
    } else if (totalSelected > 0 && totalSelected < this.groups.length) {
      this.allSelected = false;
      this.IsIndeterminate = true;
    } else if (totalSelected === this.groups.length) {
      this.allSelected = true;
      this.IsIndeterminate = false;
    }
  }

  toggleSelectAll(event) {
    this.allSelected = event.checked;
    this.groups.forEach(group => {
      group.isChecked = event.checked;
    });
  }


  smsGroups(): void {
    const selectedGroups = this.groups.filter(group => group.isChecked);
    this.smsModal(selectedGroups);
  }

  smsGroup(group) {
    const selectedGroups: Group[] = [group];
    this.smsModal(selectedGroups);
  }

  /*** Send sms Dialog ***/
  smsModal (selectedGroups: Group[]) {
    const dialogRef = this.dialog.open(SendSmsModalComponent, {
      width: '750px',
      data: {'groups': selectedGroups}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  sendSurveysToGroups () {
    const groups  = this.groups.filter( group => group.isChecked);
    this.surveyModal(groups);
  }

  sendSurveyToGroup (group: Group) {
    const groups = [group];
    this.surveyModal(groups);

  }

  surveyModal(groups: Group[]) {
    const options  = {
      width: '750px',
      data: {'groups': groups}
    };
    this.dialog.open(SendSurveyModalComponent, options);
  }

}
