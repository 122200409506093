import { Tile } from './tile';

export const TILES: Tile[] = [
  {
    icon: 'apps',
    label: 'Dashboard',
    link: 'dashboard'
  },
  {
    icon: 'share',
    label: 'Send survey',
    link: 'sendsurvey'
  },
  {
    icon: 'forum',
    label: 'Send SMS',
    link: 'bulk-sms'
  },
  {
    icon: 'cloud_upload',
    label: 'Import SMS',
    link: 'import/sms'
  },
  {
    icon: 'ballot',
    label: 'Survey Hub',
    link: 'surveys'
  },
  {
    icon: 'poll',
    label: 'Statistics',
    link: 'analytics'
  },
  {
    icon: 'group',
    label: 'Contacts',
    link: 'respondents'
  },
  {
    icon: 'local_offer',
    label: 'Groups',
    link: 'groups'
  },
  {
    icon: 'search',
    label: 'Search',
    link: 'search'
  },
  {
    icon: 'archive',
    label: 'Reports',
    link: 'archive'
  },
  {
    icon: 'person',
    label: 'Profile',
    link: 'profile'
  },
  /* {
     icon: 'settings',
     label: 'Settings',
     link: 'settings'
   },*/
  {
    icon: 'contact_phone',
    label: 'Contact us',
    link: 'contact-us'
  }
];
