import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {RestclientService} from '../../restclient.service';
import {UserService} from '../../user.service';
import {DialogComponent} from '../../shared/dialog/dialog.component';
import {MatDialog} from '@angular/material';
import {NotificationService} from '../../notification.service';
import {Location} from '@angular/common';
import {QuestionService} from '../../questions/question.service';
import {AnalyticsService} from '../../analytics/analytics.service';
import {SurveyService} from '../survey.service';
import {Question} from '../../questions/interfaces/question';
import {Survey} from '../interfaces/survey';
import {Surveyresponse} from '../interfaces/surveyresponse';

@Component({
  selector: 'app-survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.scss']
})
export class SurveyComponent implements OnInit, AfterViewInit {
  @Input() survey: Survey;
  mode = 'indeterminate';
  loadMsg = 'Loading the Survey...';
  id: string;
  public questions: Question[];
  public selectedQuestion: Question;
  private editing: boolean;
  private question: Question;
  fetchingSurvey: boolean;
  private title: string;
  private content: string;
  private analytics_data: any;
  private value: number;
  survey_responses: Surveyresponse[];

  constructor(private activatedRoute: ActivatedRoute,
              private restclientService: RestclientService,
              private router: Router,
              private userService: UserService,
              private dialog: MatDialog,
              private notificationService: NotificationService,
              private questionService: QuestionService,
              private location: Location,
              private surveyService: SurveyService,
              private analytics: AnalyticsService) {
    this.editing = false;
    this.survey = <Survey>{};
    this.selectedQuestion = <Question>{};
    this.questions = [];
    this.survey_responses = [];
  }

  ngOnInit() {
    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    if (this.id) {
      this.fetchingSurvey = true;
      this.getSurvey(this.id);
      this.getQuestions(this.id);
      this.getResponses({filter: 'survey', id: this.id});
      this.selectedQuestion.survey = this.id;
      this.analytics_data = this.analytics.getSurveyresponsePerGroup(this.id);
    }
  }

  ngAfterViewInit() {
  }

  getSurvey(id: string): void {
    this.restclientService.survey(id)
      .subscribe(res => {
          this.fetchingSurvey = false;
          this.survey = res.data;
        },
        err => {
          this.mode = 'determinate';
          this.loadMsg = 'Something went wrong while fetching the respondent...';
          this.value = 5;
          this.notificationService.showSnackBar(err.error.data.detail, 'Dismiss', 3000);
          if (err.statusText === 'Unauthorized' || err.status === false || err.message === 'Ooops something went Wrong.') {
            this.userService.setIsLoggedOut();
          }
        });
  }

  editSurvey(): void {
    this.router.navigate([`surveys/edit/${this.id}`]);
  }

  deleteSurvey(): void {
    this.restclientService.deleteSurvey(this.survey.id)
      .subscribe(
        (res) => {
          this.router.navigate(['surveys']);
        },
        err => {
          if (err.statusText === 'Unauthorized' || err.status === false || err.message === 'Ooops something went Wrong.') {
            this.userService.setIsLoggedOut();
          }
        }
      );
  }

  private getQuestions(id: string) {
    this.restclientService.surveyQuestions(id).subscribe(
      res => {
        this.questions = res.data.results;
      },
      err => {
        if (err.statusText === 'Unauthorized' || err.status === false || err.message === 'Ooops something went Wrong.') {
          this.userService.setIsLoggedOut();
        }
      }
    );
  }

  questionSubmittedHandler(question: Question): void {
    this.editing = false;
    this.question = question;
    this.question.survey = this.id;
    this.getQuestions(this.id);
  }

  addQuestion(): void {
    this.selectedQuestion = <Question>{};
    this.selectedQuestion.survey = this.id;
    this.editing = true;
  }

  editQuestion(question: Question) {
    this.selectedQuestion = question
    this.editing = true
  }
  
  openSurveyDialog(): void {
    this.title = 'About to delete survey below!';
    this.content = this.survey.title;
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '350px',
      data: {title: this.title, content: this.content}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.deleteSurvey();
      }
    });
  }

  openDeleteQuestionDialog(question: Question): void {
    this.title = 'About to delete question below!';
    this.content = question.description;
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '350px',
      data: {title: this.title, content: this.content}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.deleteQuestion(question.id);
      }
    });
  }

  deleteQuestion(id: string): void {
    this.questionService.deleteQuestion(id)
      .subscribe(
        (res) => {
          this.getQuestions(this.id);
        },
        err => {
          if (err.statusText === 'Unauthorized' || err.status === false || err.message === 'Ooops something went Wrong.') {
            this.userService.setIsLoggedOut();
          }
        }
      );
  }

  getResponses(params: any) {
    this.restclientService.responses(params)
      .subscribe(res => {
          this.survey_responses = res.data.results;
        },
        err => {

          this.notificationService.showSnackBar(err.error.data.detail, 'Dismiss', 3000);
          if (err.statusText === 'Unauthorized' || err.status === false || err.message === 'Ooops something went Wrong.') {
            this.userService.setIsLoggedOut();
          }
        });
  }

  goBack(): void {
    this.location.back();
  }

}
