import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {UserService} from '../../user.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {QuestionService} from '../question.service';
import {AnswerService} from '../../answers/answer.service';
import {Question} from '../interfaces/question';

@Component({
  selector: 'app-question-add-edit',
  templateUrl: './question-add-edit.component.html',
  styleUrls: ['./question-add-edit.component.sass']
})
export class QuestionAddEditComponent implements OnInit, OnChanges {

  @Output() questionSubmitted: EventEmitter<Question> = new EventEmitter();

  @Input() question: Question;
  questionToEdit: Question;
  showQuestionError: boolean;
  addQuestion: boolean;
  questionForm: FormGroup;
  id: string;
  private asChild: boolean;

  constructor(public restclientService: QuestionService,
              public answers: AnswerService,
              public userService: UserService,
              public activatedRoute: ActivatedRoute,
              public location: Location,
              public router: Router) {
    this.question = <Question>{};
    this.questionToEdit = <Question>{};
    this.showQuestionError = false;
    this.addQuestion = true;
    this.asChild = false;
    this.initializeForm();
  }

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges): void {
    this.questionToEdit = this.question;
    this.asChild = true;
    this.editQuestion(this.question);
  }

  getQuestion(): void {
    this.restclientService.question(this.id)
      .subscribe(res => {
        this.addQuestion = false;
        this.questionToEdit = res.data;
        this.editQuestion(res.data);
      });
  }


  private initializeForm() {
    this.questionForm = new FormGroup({
      description: new FormControl('', Validators.required),
      question_type: new FormControl('', Validators.required)
    });

    this.questionForm.valueChanges.subscribe(value => {
      // console.log(value);
    });
  }

  editQuestion(question: Question): void {
    if (question.id !== null && question.id !== undefined) {
      this.addQuestion = false;
    } else {
      this.addQuestion = true;
      this.questionToEdit = question;
    }
    this.questionForm.get('description').setValue(question.description);
    this.questionForm.get('question_type').setValue(question.question_type);
  }

  createQuestion(question: Question): void {

    if (this.questionToEdit.id !== null && this.questionToEdit.id !== undefined) {
      this.question.id = this.questionToEdit.id;
    }
    this.question.description = question.description;
    this.question.question_type = question.question_type;

    if (this.question.id !== null && this.question.id !== undefined) {
      this.restclientService.updateQuestion(this.question)
        .subscribe(
          (res) => {
            this.goBack();
          },
          err => {

            if (err.statusText === 'Unauthorized' || err.status === false || err.message === 'Ooops something went Wrong.') {
              this.userService.setIsLoggedOut();
            }
          }
        );
    } else {
      this.restclientService.createQuestion(this.question)
        .subscribe(
          (res) => {
            this.goBack();
          },
          err => {

            if (err.statusText === 'Unauthorized' || err.status === false || err.message === 'Ooops something went Wrong.') {
              this.userService.setIsLoggedOut();
            }
          }
        );
    }
  }

  goBack(): void {
    if (!this.asChild) {
      this.location.back();
    } else {
      this.questionSubmitted.emit(this.question);
    }
  }

}


@Component({
  selector: 'app-question-edit',
  templateUrl: './question-add-edit.component.html',
  styleUrls: ['./question-add-edit.component.sass']
})
export class QuestionEditComponent extends QuestionAddEditComponent implements OnInit, OnChanges {


  constructor(public restclientService: QuestionService,
              public answers: AnswerService,
              public userService: UserService,
              public activatedRoute: ActivatedRoute,
              public location: Location,
              public router: Router) {
    super(restclientService, answers, userService, activatedRoute, location, router);
  }
  ngOnInit() {
    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    if (this.id !== null && this.id !== undefined) {
      this.getQuestion();
    } else {
      this.questionToEdit = this.question;
      this.editQuestion(this.question);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
  }
}
