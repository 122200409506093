import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'respondentsFilter'
})
export class RespondentsPipe implements PipeTransform {

  transform(pollingAppModels: any, term?: any): any {
    if (term === null) {
      return pollingAppModels;
    }

    const t = term.toLowerCase();

    return pollingAppModels.filter(function (pollingAppModel) {
      if (pollingAppModel.first_name === null) {
        pollingAppModel.first_name = '';
      }

      if (pollingAppModel.last_name === null) {
        pollingAppModel.last_name = '';
      }

      if (pollingAppModel.phone_number === null) {
        pollingAppModel.phone_number = '';
      }
      return pollingAppModel.first_name.toLowerCase().includes(t) ||
        pollingAppModel.last_name.toLowerCase().includes(t) ||
        pollingAppModel.phone_number.toLowerCase().includes(t);
    });
  }

}
