import {AfterViewInit, Component, OnInit, Output} from '@angular/core';
import {UserService} from './user.service';
import {DashboardtilesService} from './dashboardtiles.service';
import {Tile} from './models/tile';
import {CookieService} from 'ngx-cookie-service';
import {RestclientService} from './restclient.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})

export class AppComponent implements OnInit, AfterViewInit {
  tiles: Tile[];
  _subscription: any;
  sideNavMenu: boolean;
  today: number = Date.now();
  @Output() user: any;
  user_id: any;
  isLogged : boolean;
  constructor(private userService: UserService, private cookieService: CookieService,
              private dashboardtilesService: DashboardtilesService, private restclientservice: RestclientService) {
  }


  ngOnInit() {
    this.getTiles();
    this._subscription = this.userService.authNavMenu.subscribe((value) => {
      this.sideNavMenu = value;
    });

    if(this.cookieService.check('user_id')) {
      this.user = this.cookieService.get('user_id')
    }

    this.userService.userAuth.subscribe( (user) => {
      if(user.first_name) {
        this.isLogged = true
      }
    })
  }

  ngAfterViewInit () {


  }


  getTiles() {
    this.dashboardtilesService.getTiles()
      .subscribe(tiles => this.tiles = tiles);
  }



  logout(): void {
    this.userService.setIsLoggedOut();
  }


}
