import {Component, CUSTOM_ELEMENTS_SCHEMA, Input, NgModule, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {UserService} from '../user.service';
import {Router} from '@angular/router';
import {RestclientService} from '../restclient.service';
import {CookieService} from 'ngx-cookie-service';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';

@NgModule({
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})

@Component({
  selector: 'app-navbar',
  templateUrl: './my-navbar-component.module.html',
  styleUrls: ['./navbar.component.sass']
})
export class MyNavbarComponent implements OnInit, OnChanges {
  authNavMenu: boolean;
  private isLoggedIn: boolean;
  private username: string;
  private user;
  private user_id: string;
  _subscription: any;
  userSubscription: any;
  @Input()  Userid: any;

  constructor(private userService: UserService,
              private router: Router,
              private cookie: CookieService,
              private restclientService: RestclientService,
              private http : HttpClient) {

  }

  ngOnInit() {
    this.isLoggedIn = this.userService.getIsLoggedIn();
    this.setUp()
  }

  ngOnChanges(changes: SimpleChanges): void {
    //this.user_id = changes.Userid.currentValue
    //this.setUp();
  }

  logout(): void {
    this.userService.setIsLoggedOut();
  }

  profile(): void {
    this.router.navigate(['profile']);
  }

  private setUp() {
    this.authNavMenu = this.userService.authNavMenuVisibility;
    this._subscription = this.userService.authNavMenu.subscribe((value) => {
      this.authNavMenu = value;
    });

   this.userService.userAuth.subscribe((value) => {
     this.user = value
   })

  }
}
