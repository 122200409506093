import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {UserService} from '../user.service';
import {AnswerService} from './answer.service';
import {Answer} from './interfaces/answer';

@Component({
  selector: 'app-answers',
  templateUrl: './answers.component.html',
  styleUrls: ['./answers.component.sass']
})
export class AnswersComponent implements OnInit {
  answers: Answer[];
  term: string;
  fetchingAnswers: boolean;

  constructor(private restclientService: AnswerService,
              private router: Router,
              private userService: UserService) {
    this.answers = [];
    this.term = '';
    this.fetchingAnswers = false;
  }

  ngOnInit() {
    this.fetchingAnswers = true;
    this.restclientService.answers().subscribe(
      res => {
        this.fetchingAnswers = false;
        this.answers = res.data.results;
      },
      err => {

        this.fetchingAnswers = false;
        if (err.statusText === 'Unauthorized' || err.status === false || err.message === 'Ooops something went Wrong.' ) {
          this.userService.setIsLoggedOut();
        }
      }
    );
  }

}
