import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {getHeaders} from '../../interceptor';

@Injectable({
  providedIn: 'root'
})
export class ForgotPasswordService {

  constructor(private httpClient: HttpClient) {
  }

  sendPassword(post): Observable<any> {
    return this.httpClient.post(`${environment.baseUrl}/users/confirmreset/`, post, {headers: getHeaders});
  }
}
