import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'groupsFilter'
})
export class GroupsPipe implements PipeTransform {

  transform(pollingAppModels: any, term?: any): any {
    if (term === null) {
      return pollingAppModels;
    }

    return pollingAppModels.filter(function (pollingAppModel) {

      if (pollingAppModel.descriptions === null) {
        pollingAppModel.descriptions = '';
      }

      return pollingAppModel.name.toLowerCase().includes(term.toLowerCase()) ||
        pollingAppModel.descriptions.toLowerCase().includes(term.toLowerCase());
    });
  }

}
