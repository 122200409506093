import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {UserService} from '../../user.service';
import {HttpClient} from '@angular/common/http';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import 'rxjs/add/operator/map';
import {RestclientService} from '../../restclient.service';
import {CookieService} from 'ngx-cookie-service';
import {User} from '../interfaces/user';

@Component({
  templateUrl: './auth.login.component.html',
  styleUrls: ['./auth.login.component.sass']
})

export class AuthLoginComponent implements OnInit {

  loginForm: FormGroup;

  processing: boolean;

  phone_number: string;
  password: string;
  user: User;
  token: string;
  user_id: string;
  expires_in: number;
  showLoginError: boolean;
  private base_url: string;
  name = 'Angular';
  model: any = {
  };

  ngOnInit() {
    this.loginForm = new FormGroup({
      phone_number: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required])
    });
  }

  constructor(private router: Router,
              private userService: UserService,
              private http: HttpClient,
              private formBuilder: FormBuilder,
              private restclientService: RestclientService,
              private cookieService: CookieService) {
    this.processing = false;
    if (this.userService.getIsLoggedIn()) {
      this.router.navigate(['dashboard']);
    } else {
      this.userService.setIsLoggedOut();
      this.showLoginError = false;

      this.loginForm = formBuilder.group({
        'phone_number': [null, Validators.compose([Validators.required])],
        'password': [null, Validators.compose([Validators.required, Validators.min(4)])]
      });
    }
  }

  login(post): void {
    this.processing = true;
    this.phone_number = post.phone_number;
    this.password = post.password;
    const body = {
      'phone_number': this.phone_number,
      'password': this.password
    };

    this.showLoginError = false;

    this.restclientService.authenticateUser(post).subscribe(
      res => {
        this.user = res.data.user;
        this.token = res.data.token;
        this.expires_in = res.data.expires_in;
        this.userService.setIsLoggedIn(res.data);
        this.router.navigate(['dashboard']);
      },
      err => {
        // Remove line below to activate auth
        // this.router.navigate(['dashboard']);

        this.showLoginError = true;
        this.processing = false;

      }
    );
  }
}
