import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'questionsFilter'
})
export class QuestionsPipe implements PipeTransform {

  transform(pollingAppModels: any, term?: any): any {
    if (term === null) {
      return pollingAppModels;
    }

    return pollingAppModels.filter(function (pollingAppModel) {
      return pollingAppModel.description.toLowerCase().includes(term.toLowerCase());
    });
  }

}
