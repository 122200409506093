import {Directive, Input} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, Validator} from '@angular/forms';

@Directive({
  selector: '[appEqualTo]',
  providers: [{provide: NG_VALIDATORS, useExisting: EqualtoDirective, multi: true}]
})

export class EqualtoDirective implements Validator {
  @Input() appEqualTo: string;

  validate(control: AbstractControl) {
    const controltoCompate = control.parent.get(this.appEqualTo);
    if (controltoCompate && controltoCompate.value !== control.value) {
      return {'notEqual': true};
    }
    return null;
  }


}
