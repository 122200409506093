import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {RestclientService} from '../../restclient.service';

@Component({
  selector: 'app-verify-dialog',
  templateUrl: './verify-dialog.component.html'
})
export class VerifyDialogComponent implements OnInit {
  public sendOTP = false;
  public code: string;

  ngOnInit() {
  }

  constructor(
    public dialogRef: MatDialogRef<VerifyDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public rest: RestclientService) {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  sendCode() {
    let data = {};
    if (this.data.is_mobile === true) {
      data = {'is_mobile': true};
    }

    if (this.data.is_email === true) {
      data = {'is_email': true};
    }

    this.rest.sendCode(data).subscribe((res) => {
      this.sendOTP = true;
    });
  }

  verifyCode() {
    let data = {};
    if (this.data.is_mobile === true) {
      data = {'is_mobile': true, 'code': this.code};
    }

    if (this.data.is_email === true) {
      data = {'is_email': true, 'code': this.code};
    }

    this.rest.verifyCode(data).subscribe((res) => {
      if (this.data.is_mobile === true) {
        this.dialogRef.close({'is_mobile': true});
      }

      if (this.data.is_email === true) {
        this.dialogRef.close({'is_email': true});
      }
    });
  }

}
