/// <reference types="@types/googlemaps" />
import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Chart} from 'chart.js';
import {WeatherService} from '../weather.service';
import {RestclientService} from '../restclient.service';
import {GroupService} from '../groups/group.service';
import {RespondentService} from '../respondents/respondent.service';

@Component({
  selector: 'app-mapping',
  templateUrl: './mapping.component.html',
  styleUrls: ['./mapping.component.sass']
})
export class MappingComponent implements OnInit {
  @ViewChild('canvas',{ read: true, static: false }) canvasRef: ElementRef;
  @ViewChild('canvasb',{ read: true, static: false }) canvasRefb: ElementRef;
/*  @ViewChild('gmap') gmapElement: any;
  map: google.maps.Map;*/

  // latitude: number;
  // longitude: number;

  chart = [];
  private ctx: any;
  private ctxb: any;
  chartb = [];

  latitude = 45.464685;
  longitude = -73.845289;
  locationChosen = false;

  // This will hold our chart info

  constructor(private _weather: WeatherService,
              private restclientService: RestclientService,
              private groupservice: GroupService,
              private respondentservice: RespondentService) {
  }


  ngOnInit() {
    // this.initializeMap();
    /*this._weather.dailyForecast()
      .subscribe(res => {
        console.log(res);
        const temp_max = res['list'].map(res => res.main.temp_max);
        const temp_min = res['list'].map(res => res.main.temp_min);
        const alldates = res['list'].map(res => res.dt);

        const weatherDates = [];
        alldates.forEach((res) => {
          const jsdate = new Date(res * 1000);
          weatherDates.push(jsdate.toLocaleTimeString('en', {year: 'numeric', month: 'short', day: 'numeric'}));
        });

        this.chart = new Chart('canvas', {
          type: 'line',
          RespondentsPerQuestion: {
            labels: weatherDates,
            datasets: [
              {
                RespondentsPerQuestion: temp_max,
                borderColor: '#3cba9f',
                fill: false
              },
              {
                RespondentsPerQuestion: temp_min,
                borderColor: '#ffcc00',
                fill: false
              },
            ]
          },
          options: {
            legend: {
              display: false
            },
            scales: {
              xAxes: [{
                display: true
              }],
              yAxes: [{
                display: true
              }],
            }
          }
        });
      });*/

    this.respondentservice.respondents()
      .subscribe(res => {
        console.log('users');
        console.log(res);

        const surveys_completed = res['data']['users'].map(res => {
          return res.surveys_completed;
        });
        const surveys_participated = res['data']['users'].map(res => res.surveys_participated);
        const surveys_sent = res['data']['users'].map(res => res.surveys_sent);
        const first_name = res['data']['users'].map(res => res.first_name);


        this.ctx = this.canvasRef.nativeElement.getContext('2d');
        this.chart = new Chart(this.ctx, {
          type: 'line',
          data: {
            labels: first_name,
            datasets: [
              {
                data: [1, 2.6, 2.8, 1.9, 2, 3],
                borderColor: '#3cba9f',
                fill: false
              },
              {
                data: surveys_completed,
                borderColor: '#3cba9f',
                fill: false
              },
              {
                data: surveys_participated,
                borderColor: '#ffcc00',
                fill: false
              },
              {
                data: surveys_sent,
                borderColor: '#cacaca',
                fill: false
              }
            ]
          },
          options: {
            legend: {
              display: false
            },
            scales: {
              xAxes: [{
                display: true
              }],
              yAxes: [{
                display: true
              }],
            }
          }
        });

        this.ctxb = this.canvasRefb.nativeElement.getContext('2d');
        this.chartb = new Chart(this.ctxb, {
          type: 'bar',
          data: {
            labels: first_name,
            datasets: [
              {
                data: [1, 2.6, 2.8, 1.9, 2, 3],
                borderColor: '#3cba9f',
                fill: false
              },
              {
                data: surveys_completed,
                borderColor: '#3cba9f',
                fill: false
              },
              {
                data: surveys_participated,
                borderColor: '#ffcc00',
                fill: false
              },
              {
                data: surveys_sent,
                borderColor: '#cacaca',
                fill: false
              }
            ]
          },
          options: {
            legend: {
              display: false
            },
            scales: {
              xAxes: [{
                display: true
              }],
              yAxes: [{
                display: true
              }],
            }
          }
        });

      });
  }


  /*
    initializeMap() {
      const mapProp = {
        center: new google.maps.LatLng(45.464685, -73.845289),
        zoom: 15,
        mapTypeId: google.maps.MapTypeId.ROADMAP
      };
      this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProp);
    }

    setMapType(mapTypeId: string) {
      this.map.setMapTypeId(mapTypeId);
    }


    setCenter(e: any) {
      e.preventDefault();
      this.map.setCenter(new google.maps.LatLng(this.latitude, this.longitude));
    }
  */


  onChoseLocation(event: any) {
    console.log(event);
    this.latitude = event.coords.lat;
    this.longitude = event.coords.lng;
    this.locationChosen = true;
  }

}
