import {Component, Input, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../../user.service';
import {MatDialog} from '@angular/material';
import {AnswerService} from '../answer.service';
import {AnalyticsService} from '../../analytics/analytics.service';
import {ChartyData} from '../../charty/charty-data';

@Component({
  selector: 'app-answer-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.sass']
})
export class SummaryComponent implements OnInit {
  @Input() answer: String;
  private analytics_data: any;
  public dataResponse: any;
  public dataLength: boolean;

  public ResponsesByLocation: ChartyData;
  public ResponsesByGender: ChartyData;
  public ResponsesByAgeGroup: ChartyData;

  rbl_data_set: boolean;
  rbg_data_set: boolean;
  rbag_data_set: boolean;

  constructor(private activatedRoute: ActivatedRoute,
              private restclientService: AnswerService,
              private router: Router,
              private answerService: AnswerService,
              private userService: UserService,
              private location: Location,
              private dialog: MatDialog,
              private analytics: AnalyticsService) {
    this.rbl_data_set = false;
    this.rbg_data_set = false;
    this.rbag_data_set = false;
    this.ResponsesByLocation = <ChartyData>{};
    this.ResponsesByGender = <ChartyData>{};
    this.ResponsesByAgeGroup = <ChartyData>{};
  }

  ngOnInit() {
    this.analytics_data = this.analytics.getResponsePerAnswer(this.answer);
    this.analytics_data.subscribe(data => {
      const data_response = data.data;
      if (data_response.users && data_response.users.length > 0) {
        this.dataLength = true;
        this.processData(data_response);
      }
    });
  }


  processData(data_response: any) {
    let labels = [];
    let counts = [];

    for (const gender_stat of data_response.count_by_gender) {
      labels.push(gender_stat.gender);
      counts.push(gender_stat.total);
    }

    this.ResponsesByGender.title = 'Responses By Gender';
    this.ResponsesByGender.type = 'pie';
    this.ResponsesByGender.counts = counts;
    this.ResponsesByGender.labels = labels;
    this.rbg_data_set = true;

    labels = [];
    counts = [];
    for (const location_stat of data_response.count_by_location) {
      labels.push(location_stat.location);
      counts.push(location_stat.total);
    }

    this.ResponsesByLocation.title = 'Responses By Location';
    this.ResponsesByLocation.type = 'pie';
    this.ResponsesByLocation.counts = counts;
    this.ResponsesByLocation.labels = labels;
    this.rbl_data_set = true;

    labels = [];
    counts = [];

    for (const age_group_stat of data_response.count_by_age_group) {
      labels.push(age_group_stat.age_group__name);
      counts.push(age_group_stat.total);
    }

    this.ResponsesByAgeGroup.title = 'Responses By Age Group';
    this.ResponsesByAgeGroup.type = 'pie';
    this.ResponsesByAgeGroup.counts = counts;
    this.ResponsesByAgeGroup.labels = labels;
    this.rbag_data_set = true;

  }

}
