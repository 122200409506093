import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';

import {RestclientService} from '../../restclient.service';
import {PasswordValidation} from '../../profile/edit-profile/password-validation';
import {Admin} from './interfaces/admin';
import {switchMap, filter, tap } from 'rxjs/operators';
import {User} from '../interfaces/user';
import {NotificationService} from '../../notification.service';


@Component({
  templateUrl: './auth.register.component.html',
  styleUrls: ['./auth.register.component.sass']
})

export class AuthRegisterComponent implements OnInit {
  processing: boolean;

  signupForm: FormGroup;
  password: string;
  name: string;
  first_name: string;
  email: string;
  phone_number: string;
  showSignupError: boolean;
  showSignupSuccess: boolean;
  private base_url: string;
  user: Admin;
  model: any = {};

  ngOnInit() {
    this.signupForm.controls['phone_number'].valueChanges.pipe(
      tap(() => {
         this.signupForm.controls['email'].reset()
         this.signupForm.controls['last_name'].reset()
         this.signupForm.controls['first_name'].reset()
      }),
      filter(value => value.length > 12),
      switchMap(value => this.restclientService.searchUser(value))
    ).subscribe((data) => {
      if(data.data.results) {
        if(data.data.results.length === 1) {
           const user: User = data.data.results[0]
           if (user.is_survey_owner) {
              this.notification.showSnackBar('User with that phone number exist', 'Dismiss', 3000);
              // this.signupForm.reset();
              return;
           }
           if (user.email) {
             this.signupForm.controls['email'].setValue(user.email)
           }

          if (user.last_name) {
            this.signupForm.controls['last_name'].setValue(user.last_name)
          }
          if(user.first_name) {
            this.signupForm.controls['first_name'].setValue(user.first_name)
          }

        }
      }
    });
  }

  constructor(private router: Router,
              private httpClient: HttpClient,
              private formBuilder: FormBuilder,
              private  notification: NotificationService,
              private restclientService: RestclientService) {
    this.processing = false;
    this.showSignupError = false;
    this.showSignupSuccess = false;
    this.base_url = 'https://pintime.pesamax.com/';
    this.initializeForm();
  }

  signup(post: Admin): void {
    post.is_survey_owner = true;
    post.confirm_password = post.password;
    this.processing = true;
    // this.httpClient.post('https://pintime.pesamax.com/users/', '{"name": "{{name}}"}').subscribe();
    this.restclientService.createUser(post).subscribe(res => {
        this.user = res.data;
        this.showSignupSuccess = true;
        this.processing = false;
      },
      err => {
        this.processing = false;
        this.showSignupError = true;
        this.showSignupSuccess = false;

      });
  }


  private initializeForm() {
    this.signupForm = new FormGroup({
        first_name: new FormControl('', Validators.minLength(2)),
        last_name: new FormControl('', Validators.minLength(2)),
        email: new FormControl('', Validators.email),
        phone_number: new FormControl('', Validators.minLength(8)),
        password: new FormControl('', Validators.minLength(4)),
        confirmPassword: new FormControl('', Validators.minLength(4))
      },
      {
        validators: PasswordValidation.MatchPassword
      });
  }

}
