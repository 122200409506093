/// <reference types="@types/googlemaps" />
import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { UserService } from '../user.service';
import {
  MatDialog,
  MatDialogConfig,
  MatTabChangeEvent
} from '@angular/material';
import 'rxjs/add/operator/map';
import { GroupService } from '../groups/group.service';
import { DialogComponent } from '../shared/dialog/dialog.component';
import { RespondentService } from './respondent.service';
import { FileUploader } from 'ng2-file-upload';
import { environment } from '../../environments/environment';
import { Respondent } from './interfaces/respondent';
import { SmsComponent } from '../sms/sms.component';
import { HttpClient, HttpEventType, HttpResponse } from '@angular/common/http';
import { ExportToCsv } from 'export-to-csv';
import { Pagination } from '../interfaces/pagination';
import * as __ from 'lodash';
import { debounceTime, tap, switchMap, finalize } from 'rxjs/operators';
import { SendSmsModalComponent } from '../send-sms/send-sms.component';
import {group} from '@angular/animations';
import {User} from '../auth/interfaces/user';
import {NotificationService} from '../notification.service';
import {SendSurveyModalComponent} from '../sendsurvey/sendsurvey.component';

const URL = environment.baseUrl + '/users/upload/';

@Component({
  selector: 'app-respondents',
  templateUrl: './respondents.component.html',
  styleUrls: ['./respondents.component.sass']
})
export class RespondentsComponent implements OnInit {
  latitude = 45.464685;
  longitude = -73.845289;
  locationChosen = false;
  page = 1;
  size = 10;
  previous_page = 1;
  total_page = 1;
  next_page = 1;
  is_next: boolean;
  is_previous: boolean;
  percentDone: number;
  uploadSuccess: boolean;
  isVisibleImportRespondents = false;
  numOfPages = [10, 20, 50, 100, 1000, 10000,];
  public uploader: FileUploader = new FileUploader({
    url: URL,
    itemAlias: 'csv'
  });
  public respondents: Respondent[];
  public respondentsToFilter: Respondent[];
  public exportRespondents: any[];
  term: string;
  fetchingRespondents: boolean;
  selectedTab: any;
  private user: Respondent;
  private index: number;
  private title: string;
  private content: string;
  private csvExporter: ExportToCsv;
  private fileName = 'chartext_respondents';
  private templateFileName = 'chartext_respondents_template';
  private fileTitle = 'Chartext Respondents';
  IsChecked: boolean;
  IsIndeterminate: boolean;
  LabelAlign: string;
  IsDisabled: boolean;
  _search: string;

  interval;
  options = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    showTitle: true,
    title: this.fileTitle,
    useTextFile: false,
    useBom: true,
    filename: this.fileName,
    useKeysAsHeaders: false,
    // <-- Won't work with useKeysAsHeaders present!
    headers: [
      'First Name',
      'Last Name',
      'Email',
      'Phone Number',
      'Gender',
      'Location',
      'Groups'
    ]
  };

  maxSize = 1048576;
  allSelected: boolean;
  selectedUsers: Respondent[];

  constructor(
    private restclientService: RespondentService,
    private router: Router,
    private userService: UserService,
    private groupservice: GroupService,
    private dialog: MatDialog,
    private location: Location,
    private http: HttpClient,
    private notificationService: NotificationService
  ) {
    this.respondents = [];
    this.term = '';
    this.IsChecked = false;
    this.IsIndeterminate = false;
    this.LabelAlign = 'after';
    this.IsDisabled = false;
  }

  /***sendSmsDialog(user) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    // dialogConfig.height = '250px';
    dialogConfig.width = "350px";
    dialogConfig.data = {
      type: "phone_number",
      phone_number: user.phone_number,
      user: user
    };

    const dialogRef = this.dialog.open(SmsComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(data => {});
  }**/

  sendBulksms() {
    const users = this.respondents.filter(i => i.isChecked);
    this.smsModal(users);
  }

  sendSms(user: Respondent) {
    this.smsModal([user]);
  }


  smsModal(users: Respondent[]) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '750px';
    dialogConfig.data = {
      'users': users
  };
    const dialogRef = this.dialog.open(SendSmsModalComponent, dialogConfig);
  }

  sendSurveyModal(users: Respondent[]) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '750px';
    dialogConfig.data = {
      'users': users
    };
    const dialogRef = this.dialog.open(SendSurveyModalComponent, dialogConfig);
  }

  sendSurveys () {
    const respondents = this.respondents.filter( (respondent) => respondent.isChecked);
    this.sendSurveyModal(respondents);
  }

  sendSurvey(respondent: Respondent) {
    this.sendSurveyModal([respondent]);
  }

  ngOnInit() {
    this.csvExporter = new ExportToCsv(this.options);
    this.fetchRespondents(this.page, this.size);
    this.uploader.onAfterAddingFile = file => {
      file.withCredentials = false;
    };
    this.uploader.onCompleteItem = (
      item: any,
      response: any,
      status: any,
      headers: any
    ) => {
      alert('File uploaded successfully');
    };
  }

  onChange($e) {
    if ($e.length >= 2) {
      this.fetchRespondents(this.page, this.size, this.term);
    }
  }
  handleFileInput(files: File[]) {
    this.uploadAndProgress(files);
  }

  uploadAndProgress(files: File[]) {
    const formData = new FormData();
    Array.from(files).forEach(f => formData.append('file', f));
    this.http
      .put(URL, formData, { reportProgress: true, observe: 'events' })
      .subscribe(
        event => {
          if (event.type === HttpEventType.UploadProgress) {
            this.percentDone = Math.round((100 * event.loaded) / event.total);
          } else if (event instanceof HttpResponse) {
            this.uploadSuccess = true;
            this.page = 1;
            this.fetchRespondents(this.page, this.size);
          }
        },
        error => {
          alert('Something went wrong try again later');
        }
      );
  }

  pagiationData(data: Pagination) {
    this.page = data.current_page;
    this.total_page = data.num_pages;
    if (this.page <= 1) {
      this.previous_page = 1;
      this.is_previous = true;
    } else {
      this.previous_page = this.page - 1;
      this.is_previous = false;
    }
    if (this.page < this.total_page) {
      this.next_page = this.page + 1;
      this.is_next = true;
    } else {
      this.is_next = false;
    }
  }

  private fetchRespondents(page = 1, size = 10, search: string = '') {
    this.fetchingRespondents = true;
    this.restclientService.respondents(page, size, search).subscribe(
      res => {
        this.fetchingRespondents = false;
        this.respondents = res.data.results.users;
        this.respondentsToFilter = this.respondents.map(obj => ({ ...obj }));
        // this.respondentsToFilter = JSON.parse(JSON.stringify( this.respondents ));

        this.exportRespondents = this.respondentsToFilter.map(function(item) {
          delete item.id;
          delete item.location_lat;
          delete item.location_lng;
          delete item.surveys_completed;
          delete item.surveys_participated;
          delete item.surveys_sent;
          return item;
        });
        this.total_page = res.data.pagination.num_pages;
        this.pagiationData(res.data.pagination);
      },
      err => {
        this.fetchingRespondents = false;
      }
    );
  }

  changeSize($event) {
    this.page = 1;
    this.size = $event.target.value;
    this.fetchRespondents(this.page, this.size, '');
  }

  getMoreRespondents(type: string) {
    if (type === 'previous') {
      this.page = this.getPreviousPage();
      this.fetchRespondents(this.page, this.size, '');
    } else {
      this.page = this.getNextPage();
      this.fetchRespondents(this.page, this.size, '');
    }
  }

  getNextPage() {
    this.next_page = this.page + 1;
    if (this.next_page > this.total_page) {
      this.next_page = this.total_page;
    }
    return this.next_page;
  }

  getPreviousPage() {
    if (this.page === 1) {
      this.previous_page = this.page;
    } else {
      this.previous_page = this.page - 1;
    }
    return this.previous_page;
  }

  selectedTabChange(matTabChangeEvent: MatTabChangeEvent) {
    this.selectedTab = matTabChangeEvent.index;
  }

  onChoseLocation(event: any) {
    this.latitude = event.coords.lat;
    this.longitude = event.coords.lng;
    this.locationChosen = true;
  }

  openRespondentDialog(user: Respondent): void {
    this.user = user;
    this.title = 'Confirm delete respondent...';
    this.content =
      this.user.first_name +
      ' ' +
      this.user.last_name +
      ' ' +
      this.user.phone_number;
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '350px',
      data: {title: this.title, content: this.content}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.deleteUser(user);
      }
    });
  }

  openDeleteRespondents() {
    /**
     *   Delete respondents
     * **/

    this.selectedUsers = this.respondents.filter( respondent => respondent.isChecked);
    this.title = 'Confirm deleting Respondents';

    this.content  = 'Are you sure you want to remove this respondents';

    const dialogRef = this.dialog.open(DialogComponent, {
      width: '350px',
      data: {title: this.title, content: this.content}
    });

    dialogRef.afterClosed().subscribe( result => {
      if (result) {
        this.restclientService.remmoveRespondents(this.selectedUsers).pipe(
          finalize( () => {
            this.fetchRespondents(this.page, this.size);
          })
        ).subscribe((data) => {
          this.notificationService.showSnackBar('Respondents removed successfully.', 'Dismiss', 3000);

        });
      }

    });
 }

  deleteUser(user): void {
    const data = { id: user.id, user: user };
    this.restclientService
      .removeRespondent(this.user.id, data)
      .pipe(
        finalize(() => {
          this.fetchRespondents(this.page, this.size);
        })
      )
      .subscribe(
        res => {
          // this.router.navigate(['users']);
          // this.respondents.splice(this.index,
        },
        err => {
          if (
            err.statusText === 'Unauthorized' ||
            err.status === false ||
            err.message === 'Ooops something went Wrong.'
          ) {
            this.userService.setIsLoggedOut();
          }
        }
      );
  }

  ExportRespondent() {
    const respondents = [];
    this.exportRespondents.forEach(function(item) {
      const groups = [];
      item.usergroups.forEach(function(group) {
        groups.push(__.pick(group, ['name'])['name']);
      });
      item.usergroups = groups.join(', ');
      respondents.push(
        __.pick(item, [
          'first_name',
          'last_name',
          'email',
          'phone_number',
          'gender',
          'location',
          'usergroups'
        ])
      );
    });
    this.csvExporter.generateCsv(respondents);
  }

  ExportRespondentTemplate() {
    this.options.showTitle = false;
    this.options.filename = this.templateFileName;
    this.csvExporter = new ExportToCsv(this.options);
    const exportRespondents = [
      {
        first_name: '',
        last_name: '',
        email: '',
        phone_number: '',
        group: '',
        age_group: '',
        gender: '',
        location: ''
      }
    ];
    this.csvExporter.generateCsv(exportRespondents);
  }


  respondentChanged(respondent, event) {
    respondent.isChecked = event.checked;

    const totalSelected = this.respondents.filter(i => i.isChecked).length;
    if (totalSelected === 0) {
      this.allSelected = false;
      this.IsIndeterminate = false;
    } else if (totalSelected > 0 && totalSelected < this.respondents.length) {
      this.allSelected = false;
      this.IsIndeterminate = true;
    } else if (totalSelected === this.respondents.length) {
      this.allSelected = true;
      this.IsIndeterminate = false;
    }
  }

  toggleSelectAll(event) {
    this.allSelected = event.checked;
    this.respondents.forEach(respondent => {
      respondent.isChecked = event.checked;
    });
  }
}
