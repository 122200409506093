import {Component, OnInit, Inject} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {RespondentService} from '../respondents/respondent.service';
import {UserService} from '../user.service';
import {NotificationService} from '../notification.service';
import {Group} from '../groups/interfaces/group';
import {GroupService} from '../groups/group.service';
import {RestclientService} from '../restclient.service';
import {Respondent} from '../respondents/interfaces/respondent';
import {MatSelectChange , MAT_DIALOG_DATA, MatDialogRef  } from '@angular/material';
import {User} from '../auth/interfaces/user';
import {debounceTime, map, finalize, tap, switchMap } from 'rxjs/operators';
import { ExportToCsv } from 'export-to-csv';

@Component({
  selector: 'app-send-sms',
  templateUrl: './send-sms.component.html',
  styleUrls: ['./send-sms.component.scss']
})

export class SendSmsComponent implements OnInit {
  checked: boolean;
  start_at: string;
  date_time: any;
  message: string;
  sendTextForm: FormGroup;
  fetching_recipient: boolean;
  id: string;
  user: Respondent;
  group: Group;
  name: string;
  error: string;
  showError: boolean;
  type: string;
  currentPage = 1;
  totalPages = 1;
  nextPage = 1;
  previousPage = 1;
  numOfPages = [10, 20, 50, 100];
  size = 20;
  isNext: boolean;
  isPrevious: boolean;
  groups: Group[];
  selectGroups: Group[];
  btn_label = 'Send';
  loadMsg = '';
  users: User[];
  isgroupSearch: boolean;
  groupSearch: FormControl;
  isUserSearch: boolean;
  userSearch: FormControl;
  selectUsers: User[];
  isModal: boolean;
  fileName = 'chartext_sms_respondents';
  fileTitle = 'chartext sms template';
  options = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    showTitle: true,
    title: this.fileTitle,
    useTextFile: false,
    useBom: true,
    filename: this.fileName,
    useKeysAsHeaders: false,
    // <-- Won't work with useKeysAsHeaders present!
    headers: [
      'Phone Number',
      'Message'
    ]
  };

  csvExporter: ExportToCsv;

  constructor(public  formBuilder: FormBuilder,
              public  route: ActivatedRoute,
              public router: Router,
              public  restClientService: RespondentService,
              public groupService: GroupService,
              public rest: RestclientService,
              public userService: UserService,
              public notificationService: NotificationService
  ) {
    this.user = <Respondent>{};
    this.group = <Group>{};
    this.groups = [];
    this.selectGroups = [];
    this.selectUsers = [];
    this.groupSearch = new FormControl();
    this.userSearch = new FormControl();
    this.sendTextForm = new FormGroup({
      smsText: new FormControl('', [Validators.required]),
      scheduleDate: new FormControl('', [])
    });
  }

  ExportRespondentTemplate() {
    this.options.showTitle = false;
    this.options.filename = this.fileName;
    this.csvExporter = new ExportToCsv(this.options);
    const exportRespondents = [
      {
        phone_number: '',
        message: '',

      }
    ];
    this.csvExporter.generateCsv(exportRespondents);
  }
  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');

   /**this.route.queryParams.subscribe(params => {
      if (params['type'] === 'respondent') {
        this.type = 'respondent';
        this.getUser(this.id);
      } else if (params['type'] === 'group') {
        this.type = 'group';
        this.getGroup(this.id);
      } else {
        this.type = 'groups';
        this.fetchGroups();
      }
    }, err => {
      this.type = 'groups';
      this.fetchGroups();
    });**/

    this.fetchGroups();
    this.fetchUsers();

    /** user search **/
    this.userSearch.valueChanges.pipe(
      debounceTime(3000),
      tap(() => this.isUserSearch = true),
      switchMap(value => this.restClientService.respondentsWithoutPagination(value).pipe(
        finalize ( () => {
           this.isUserSearch = false;
        })
      ))
    ).subscribe((data) => {
      this.users = data.data.users;
    });

    /*** group ***/

    this.groupSearch.valueChanges.pipe(
      debounceTime(3000),
      tap(() => this.isgroupSearch = true),
      switchMap(value => this.groupService.groupsWithoutPagination(value).pipe(
        finalize ( () => {
          this.isgroupSearch = false;
        })
      ))
    ).subscribe((data) => {
      this.groups = data.data;
    });
  }

  getUser(id: string): void {
    this.fetching_recipient = true;
    if (this.id != null) {
      this.restClientService.getRespondent(id)
        .subscribe(res => {
            this.fetching_recipient = false;
            this.user = res.data;
            this.name = this.user.first_name + ' ' + this.user.last_name;
          },
          err => {
            this.fetching_recipient = false;
            this.loadMsg = 'Something went wrong while fetching the recipient(s)...';
            this.notificationService.showSnackBar(err.error.data.detail, 'Dismiss', 3000);
            this.error = err.error.data.detail;
            this.showError = true;
            if (err.statusText === 'Unauthorized' || err.status === false || err.message === 'Oops something went Wrong.') {
              this.userService.setIsLoggedOut();
            }
          });
    }
  }

  getGroup(id: string): void {
    this.fetching_recipient = true;
    this.groupService.getGroup(id)
      .subscribe(res => {
          this.fetching_recipient = false;
          this.group = res.data;
          this.name = this.group.name;
        },
        err => {
          this.fetching_recipient = false;
          this.loadMsg = 'Something went wrong while fetching the recipient(s)...';
          this.notificationService.showSnackBar(err.error.data.detail, 'Dismiss', 3000);
          this.error = err.error.data.detail;
          this.showError = true;
          if (err.statusText === 'Unauthorized' || err.status === false || err.message === 'Oops something went Wrong.') {
            this.userService.setIsLoggedOut();
          }
        });
  }

  fetchGroups(page: number = 1, search: string = null) {
    this.groupService.groups(page, this.size, search).subscribe(
      res => {
        this.fetching_recipient = false;
        this.groups = res.data.results;
        this.currentPage = res.data.pagination.current_page;
        this.totalPages = res.data.pagination.num_pages;
        this.isNext = false;
        this.isPrevious = false;
        if (this.currentPage === this.totalPages) {
          this.nextPage = this.totalPages;
        } else {
          this.nextPage = this.currentPage + 1;
        }
        if (this.currentPage <= 1) {
          this.previousPage = 1;
        } else {
          this.previousPage = this.currentPage - 1;
        }

        if (this.currentPage === this.totalPages) {
          this.isNext = true;
        }

        if (this.currentPage === 1) {
          this.isPrevious = true;
        }

      },
      err => {
        this.fetching_recipient = false;
        if (err.statusText === 'Unauthorized' || err.status === false || err.message === 'Oops something went Wrong.') {
          this.userService.setIsLoggedOut();
        }
      }
    );
  }

  fetchUsers() {
    this.restClientService.respondents(1, 10, '').subscribe((data) => {
        this.users = data.data.results.users;
    });

  }

  onToggleChange(event: any): void {
    this.checked = !this.checked;
    if (this.checked) {
      this.btn_label = 'Schedule';
    } else {
      this.btn_label = 'Send';
    }
  }

  dateTimeChange(event: any): void {
    this.start_at = event.value;
  }

  sendText(value: any) {
    this.message = value.smsText;
    this.date_time = '';
    if (this.checked && value.scheduleDate) {
      this.date_time = value.scheduleDate;
    }

    const data = <any>[];

    if (this.selectGroups.length > 0) {
      for (const g of this.selectGroups) {
        data.push({'group': g.id, 'message': this.message, 'date_time': this.date_time});
      }
    }

    if (this.selectUsers.length ) {
      this.selectUsers.forEach((value) => {
        data.push({'phone_number': value.phone_number, 'message': this.message, 'date_time': this.date_time});
      });
    }

    if (data.length > 0 ) {
      this.postData(data);
    }

  }

  postData(data: any) {
    this.rest.sendSms(data).subscribe((res) => {
        this.notificationService.showSnackBar('SMS has been sent.', 'Dismiss', 3000);
        this.clearandRedirect();
      },
      err => {
        this.notificationService.showSnackBar(err.error.data.detail, 'Dismiss', 3000);
        this.error = err.error.data.detail;
        this.showError = true;
        if (err.statusText === 'Unauthorized' || err.status === false || err.message === 'Oops something went Wrong.') {
          this.userService.setIsLoggedOut();
        }
      });
  }


  groupSelected($event: MatSelectChange) {
    this.selectGroups = $event.value;
  }

  userSelected($event: MatSelectChange) {
    this.selectUsers = $event.value;
  }

  clearandRedirect() {
    this.sendTextForm.reset();
    this.sendTextForm.reset();
    if (this.type === 'respondent') {
      this.router.navigate([`/respondents`]);
    } else if (this.type === 'group') {
      this.router.navigate([`/groups`]);
    } else {
      this.selectGroups = [];
      this.selectUsers = [];
    }
  }


}

@Component({
  selector: 'app-send-sms-modal',
  templateUrl: './send-sms.component.html',
  styleUrls: ['./send-sms.component.scss']
})

export class SendSmsModalComponent extends  SendSmsComponent implements  OnInit {

  constructor (public  formBuilder: FormBuilder,
               public  route: ActivatedRoute,
               public router: Router,
               public  restClientService: RespondentService,
               public groupService: GroupService,
               public rest: RestclientService,
               public userService: UserService,
               public notificationService: NotificationService,
               @Inject(MAT_DIALOG_DATA) public dialogData: any,
                public dialogRef: MatDialogRef<SendSmsComponent>) {
    super(formBuilder, route, router, restClientService, groupService, rest, userService, notificationService);

  }

  ngOnInit() {
    this.isModal = true;
    this.users = [];
    this.groups = [];

    if (this.dialogData.hasOwnProperty('groups')) {

      this.selectGroups = this.dialogData.groups;
      this.type = 'groups';
    }

    if (this.dialogData.hasOwnProperty('users')) {
       this.selectUsers = this.dialogData.users;
       this.type = 'respondents';
    }

  }

  sendText(value: any) {
    super.sendText(value);
    this.dialogRef.close();
  }

  clearandRedirect() {
    this.dialogRef.close();
  }



}
