import { Tile } from './tile';

export const ALLTILES: Tile[] = [
  {
    icon: 'share',
    label: 'Send Survey',
    link: 'sendsurvey'
  },
  {
    icon: 'forum',
    label: 'Send SMS',
    link: 'bulk-sms'
  },
  {
    icon: 'cloud_upload',
    label: 'Import SMS',
    link: 'import/sms'
  },
  {
    icon: 'ballot',
    label: 'Survey Hub',
    link: 'surveys'
  },
  {
    icon: 'poll',
    label: 'Statistics',
    link: 'analytics'
  },
  {
    icon: 'group',
    label: 'Contacts',
    link: 'respondents'
  },
  /*  {
      icon: 'place',
      label: 'Mapping',
      link: 'mapping'
    },*/
  {
    icon: 'local_offer',
    label: 'Groups',
    link: 'groups'
  },
  /*{
    icon: 'list',
    label: 'Questions',
    link: 'questions'
  },
  {
    icon: 'check',
    label: 'Answers',
    link: 'answers'
  },*/
  {
    icon: 'search',
    label: 'Search',
    link: 'search'
  },
  {
    icon: 'archive',
    label: 'Reports',
    link: 'archive'
  },
  /*{
    icon: 'settings',
    label: 'Settings',
    link: 'settings'
  },*/
  {
    icon: 'person',
    label: 'Profile',
    link: 'profile'
  },
  {
    icon: 'contact_phone',
    label: 'Contact us',
    link: 'contact-us'
  }
];
