import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {GroupsResponse} from './interfaces/groups-response';
import {AgeGroupsResponse} from '../respondents/interfaces/groups-response';
import {GroupStatsResponseResponse} from './interfaces/group-stats-response';
import {UserGroupResponse} from './interfaces/user-group-response';
import {Group} from './interfaces/group';


@Injectable({
  providedIn: 'root'
})
export class GroupService {

  constructor(private httpClient: HttpClient) {

  }

  groups(page, size, search: string = null): Observable<any> {
    let letUrl = `${environment.baseUrl}/users/groups?page=${page}&page_size=${size}`;
    if (search !== null) {
      letUrl = letUrl + `&search=${search}`;
    }
    return this.httpClient.get<GroupsResponse>(letUrl);
  }

  groupsWithoutPagination(search: string = null): Observable<any> {
    let letUrl = `${environment.baseUrl}/users/groups?ispage=0`;
    if (search !== null) {
      letUrl = letUrl + `&search=${search}`;
    }
    return this.httpClient.get<GroupsResponse>(letUrl);
  }

  search(search: string): Observable<any> {
    return this.httpClient.get<GroupsResponse>(`${environment.baseUrl}/users/groups?search=${search}`);
  }

  ageGroups(): Observable<any> {
    return this.httpClient.get<AgeGroupsResponse>(`${environment.baseUrl}/users/agegroups`);
  }

  ageGroup(id: string): Observable<any> {
    return this.httpClient.get<AgeGroupsResponse>(`${environment.baseUrl}/users/agegroups/${id}`);
  }

  getGroupStats(id: string): Observable<any> {
    return this.httpClient.get<GroupStatsResponseResponse>(`${environment.baseUrl}/users/user?group=${id}`);
  }

  getGroup(id: string): Observable<any> {

    return this.httpClient.get<UserGroupResponse>(`${environment.baseUrl}/users/groups/${id}`);
  }

  createGroup(post: any): Observable<any> {
    return this.httpClient.post<UserGroupResponse>(`${environment.baseUrl}/users/groups`, post);
  }

  updateGroup(post: Group): Observable<any> {
    const id = post.id;
    return this.httpClient.put<UserGroupResponse>(`${environment.baseUrl}/users/groups/${id}`, post);
  }

  deleteGroup(id: string): Observable<any> {
    return this.httpClient.delete<UserGroupResponse>(`${environment.baseUrl}/users/groups/${id}`);
  }

  deleteGroups(data): Observable<any> {
    return this.httpClient.post(`${environment.baseUrl}/users/groups/removegroups`, data);
  }

}
