import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {UserService} from '../../user.service';
import {HttpClient} from '@angular/common/http';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import 'rxjs/add/operator/map';
import {CookieService} from 'ngx-cookie-service';
import {ForgotPasswordService} from './forgotupdate.service';
import {notEqual} from '../../validators/notEqual';

@Component({
  templateUrl: './forgotupdate.component.html',
  styleUrls: ['./forgotupdate.component.sass']
})


export class ForgotUpdateComponent implements OnInit {

  processing: boolean;
  showLoginError: boolean;
  forgotPass: FormGroup;

  code: string;
  password: string;
  confirm_password: string;

  ngOnInit() {
    this.forgotPass = new FormGroup({
      code: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required]),
      confirm_password: new FormControl('', [Validators.required, notEqual])
    });
  }

  constructor(private router: Router,
              private userService: UserService,
              private http: HttpClient,
              private formBuilder: FormBuilder,
              private service: ForgotPasswordService,
              private cookieService: CookieService) {
  }

  submit(post): void {

    const body = {
      'code': post.code,
      'password': post.password,
      'confirm_password': post.confirm_password
    };
    this.service.sendPassword(body).subscribe(
      res => {
        this.router.navigate(['login']);
      },
      err => {
        console.log(err);
      }
    );
  }
}
