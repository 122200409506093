import {Component, Input, OnInit, ElementRef, ViewChild} from '@angular/core';
import {Form, FormControl, FormGroup, Validators} from '@angular/forms';
import {Location} from '@angular/common';
import {UserService} from '../../user.service';
import {ActivatedRoute, Router} from '@angular/router';
import {NotificationService} from '../../notification.service';
import {RespondentService} from '../respondent.service';
import {GroupService} from '../../groups/group.service';
import {Respondent} from '../interfaces/respondent';
import {Group} from '../../groups/interfaces/group';
import {UserGroup} from '../../groups/interfaces/user-group';
import {AgeGroup} from '../interfaces/age-group';
import { finalize, debounceTime, switchMap, tap } from 'rxjs/operators';
import {MatOption, MatSelect} from '@angular/material';
import {maybeNodeArray} from 'codelyzer/util/utils';


@Component({
  selector: 'app-respondent-add-edit',
  templateUrl: './respondent-add-edit.component.html',
  styleUrls: ['./respondent-add-edit.component.sass'],
})
export class RespondentAddEditComponent implements OnInit {

  @Input() respondent: Respondent;
  respondentToEdit: Respondent;
  showuserError: boolean;
  addRespondent: boolean;
  userForm: FormGroup;
  id: string;
  groups: Group[];
  _groups: Group[];
  genders: string[];
  usergroups: Group[];
  group: UserGroup;
  agegroups: AgeGroup[];
  isgroupSearch: boolean;
  groupSearch: FormControl;
  groupMatSelect: FormControl;
  @ViewChild('selectEvent', {static: false}) groupSelectEvent: MatSelect;
  private selectedGroups: Group[];
  name = 'Angular';
  model: any = {
  };


  constructor(private restclientService: RespondentService,
              private groupservice: GroupService,
              private userService: UserService,
              private activatedRoute: ActivatedRoute,
              private location: Location,
              private router: Router,
              private notificationService: NotificationService) {
    this.group = <UserGroup>{};
    this.groups = [];
    this._groups = [];
    this.usergroups = [];
    this.selectedGroups = [];
    this.showuserError = false;
    this.addRespondent = true;
    this.genders = ['MALE', 'FEMALE', 'OTHER'];
    this.groupSearch = new FormControl();


  }

  ngOnInit() {
    this.initializeForm();
    this.fetchGroups();
    this.getRespondent();
    this.getAgeGroups();

    this.groupSearch.valueChanges.pipe(
      debounceTime(300),
      tap( () => this.isgroupSearch = true),
      switchMap(value =>  this.groupservice.groupsWithoutPagination(value).pipe(
        finalize(() => {
          this.isgroupSearch = false;
        })
      ))).subscribe((data) => {
         this.groups = data.data;
    });

  }

  groupSelected (event) {
    this.selectedGroups = event.value;
    this.selectedGroups.forEach((group) => {
       const usergroupselected = this.usergroups.filter((usergroup) => usergroup.id ===  group.id);
       if (usergroupselected.length <= 0) {
           this.usergroups.push(group);
       }
    });
    this.selectedGroups = [];

  }

  removeFromSelectedGroups(group: Group) {
     this.usergroups.splice(this.usergroups.indexOf(group), 1);
     this.restclientService.deleteUserUserGroup({'user': this.respondentToEdit.id, 'group': group.id}).subscribe(() => {
      // this.updateSelections()
    });
  }

  updateSelections( ) {

    /**let groupSelected = []

    this.groupSelectEvent.options.forEach((matOption: MatOption) => {
      if (matOption.value) {
        groupSelected = this.usergroups.filter((group) => matOption.value.id === group.id)
        if (groupSelected.length > 0) {
          matOption._selected = true
          this.groupSelectEvent.selected.push(matOption)
        } else {
          matOption._selected = false
        }
      }
      groupSelected = []
    })

    this.groupSelectEvent.value = this.usergroups **/

  }

  getRespondent(): void {
    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    if (this.id) {
      this.restclientService.getRespondent(this.id).pipe(
        finalize ( () => {
          // this.updateSelections()
        })
      ).subscribe(res => {
          this.addRespondent = false;
          this.respondentToEdit = res.data;
          this.usergroups = res.data.usergroups;
          console.log(res.data);
          console.log(this.usergroups);
          this.editRespondent(res.data);
        });
    }
  }

  private initializeForm() {
    this.userForm = new FormGroup({
      first_name: new FormControl(),
      last_name: new FormControl(),
      email: new FormControl(),
      phone_number: new FormControl('', Validators.required),
      gender: new FormControl(),
      location: new FormControl(),
      date_of_birth: new FormControl(),
      usergroups: new FormControl(),
      age_group: new FormControl(),
      mobileNo: new FormControl('', Validators.required),
    });
  }

  editRespondent(respondent: Respondent): void {
    this.userForm.get('first_name').setValue(respondent.first_name);
    this.userForm.get('last_name').setValue(respondent.last_name);
    this.userForm.get('email').setValue(respondent.email);
    this.userForm.get('phone_number').setValue(respondent.phone_number);
    this.userForm.get('gender').setValue(respondent.gender);
    this.userForm.get('location').setValue(respondent.location);
    this.userForm.get('date_of_birth').setValue(respondent.date_of_birth);
    this.userForm.get('usergroups').setValue(respondent.usergroups);
    this.userForm.get('age_group').setValue(respondent.age_group);

  }

  createRespondent(respondent: Respondent): void {
    this.respondent = respondent;
    this.respondent.usergroups = this.usergroups;
    if (!this.addRespondent) {
      this.respondent.id = this.respondentToEdit.id;
      this.restclientService.updateRespondent(this.respondent)
        .subscribe(
          (res) => {
            this.respondentToEdit = res.data;
            this.notificationService.showSnackBar('Respondent has been updated successfully.', 'Dismiss', 3000);
            /**if (this.usergroups.length > 0) {
              this.assignUserGroups();
            } else {
              this.goBack();
            }**/
            this.goBack();

          },
          err => {
            this.notificationService.showSnackBar(err.error.data.phone_number, 'Dismiss', 3000);
            if (err.statusText === 'Unauthorized' || err.status === false || err.message === 'Ooops something went Wrong.') {
              this.userService.setIsLoggedOut();
            }
          }
        );
    } else {
      this.restclientService.createRespondent(this.respondent)
        .subscribe(
          (res) => {
            this.respondentToEdit = res.data;
            this.notificationService.showSnackBar('Respondent has been created successfully.', 'Dismiss', 3000);
            this.goBack();
          },
          err => {
            let msg: string;
            if (err.error.data.phone_number !== undefined) {
              msg = err.error.data.phone_number;
            } else {
              msg = 'Something went wrong.';
            }

            this.notificationService.showSnackBar(msg, 'Dismiss', 3000);
            if (err.statusText === 'Unauthorized' || err.status === false || err.message === 'Oops something went Wrong.') {
              this.userService.setIsLoggedOut();
            }
          }
        );
    }
  }

  goBack(): void {
    this.location.back();
  }

  fetchGroups() {
    this.groupservice.groups(1, 10).subscribe(
      res => {
        this.groups = res.data.results;
      }
    );
  }

  assignUserGroup(group: UserGroup) {
    this.restclientService.createUserUserGroup(group)
      .subscribe(
        (res) => {}
        ,
        err => {
          if (err.statusText === 'Unauthorized' || err.status === false || err.message === 'Ooops something went Wrong.') {
            this.userService.setIsLoggedOut();
          }
        }
      );
  }

  assignUserGroups() {
    let g: Group;
    let x: number;
    for (x = 0; x < this.selectedGroups.length; x++) {
      g = this.selectedGroups[x];
      this.group.user = this.respondentToEdit.id;
      this.group.group = g.id;
      this.assignUserGroup(this.group);
    }
    if (!this.addRespondent) {
      this.location.back();
    } else {
      this.router.navigate(['users']);
    }
  }



  checkGroupSelection(group: Group, y: number) {
    let selectedGroup: Group;
    for (let x = 0; x < this.usergroups.length; x++) {
      selectedGroup = this.usergroups[x];
      if (group.id === selectedGroup.id) {
        this._groups.splice(y, 1);
      }
    }
  }

   getAgeGroups() {
    this.groupservice.ageGroups().subscribe(
      res => {
        this.agegroups = res.data.results;
      },
      err => {
        if (err.statusText === 'Unauthorized' || err.status === false || err.message === 'Ooops something went Wrong.') {
          this.userService.setIsLoggedOut();
        }
      }
    );
  }


}
