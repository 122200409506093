import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {AnswerResponse} from './interfaces/answer-response';
import {AnswersResponse} from './interfaces/answers-response';
import {QuestionResponse} from '../questions/interfaces/question-response';

@Injectable({
  providedIn: 'root'
})
export class AnswerService {

  constructor(private httpClient: HttpClient) {
  }


  answer(id: string): Observable<any> {

    return this.httpClient.get<QuestionResponse>(`${environment.baseUrl}/surveys/answers/${id}/`);
  }

  answers(): Observable<any> {

    return this.httpClient.get<AnswersResponse>(`${environment.baseUrl}/surveys/answers/ `);
  }

  createAnswer(post: any): Observable<any> {
    return this.httpClient.post<AnswerResponse>(`${environment.baseUrl}/surveys/answers/`, post);
  }

  updateAnswer(post: any): Observable<any> {
    return this.httpClient.patch<AnswerResponse>(`${environment.baseUrl}/surveys/answers/${post.id}/`, post);
  }

  deleteAnswer(id: string): Observable<any> {
    return this.httpClient.delete<AnswerResponse>(`${environment.baseUrl}/surveys/answers/${id}/`);
  }
}
