import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs/Observable';
import {UserService} from './user.service';

@Injectable()
export class AuthGuard implements CanActivate {

  private isLoggedIn: boolean;
  private redirectUrl: string;

  constructor(private userService: UserService, private router: Router) {

  }

  canActivate(next: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    this.redirectUrl = state.url;
    return this.checkLoggedIn(this.redirectUrl);
  }

  private checkLoggedIn(redirectUrl: string): boolean {
    if (this.userService.getIsLoggedIn()) {
      this.isLoggedIn = true;
    } else {
      this.isLoggedIn = false;
    }
    this.isLoggedIn = true;
    return this.isLoggedIn;
  }
}
