import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {User} from '../auth/interfaces/user';
import {SignupResponse} from '../auth/register/interfaces/signupResponse';
import {RespondentResponse} from './interfaces/respondent-response';
import {Respondent} from './interfaces/respondent';
import {UserGroupResponse} from '../groups/interfaces/user-group-response';
import {GroupStatsResponseResponse} from '../groups/interfaces/group-stats-response';
import {RespondentsResponse} from './interfaces/respondents-response';
import {UserService} from '../user.service';

@Injectable({
  providedIn: 'root'
})
export class RespondentService {
  headers: any;
  options: any;
  constructor(private httpClient: HttpClient, private userService: UserService) {
  }

  setHeaders() {
    this.headers = {
      // 'Authorization': 'Bearer ' + this.userService.token,
      'Authorization': 'Token ' + this.userService.token,
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      /**'Access-Control-Allow-Headers': '*'**/
    };
    this.options = {headers: this.headers};
  }



  updateUser(post: User): Observable<any> {
    const id = post.id;
    return this.httpClient.patch<SignupResponse>(`${environment.baseUrl}/users/user/${id}`, post);
  }

  deleteUser(id: string): Observable<any> {
    // this.setHeaders();
    return this.httpClient.delete<SignupResponse>(`${environment.baseUrl}/users/user/${id}`);
  }

  removeRespondent(id: string, data): Observable<any> {
    return this.httpClient.post(`${environment.baseUrl}/users/user/${id}/respondent`, data);
  }

  remmoveRespondents(data): Observable<any> {
    return this.httpClient.post(`${environment.baseUrl}/users/user/removerespondent`, data);
  }

  getRespondent(id: string): Observable<any> {
    return this.httpClient.get<RespondentResponse>(`${environment.baseUrl}/users/user/${id}`);
  }

  createRespondent(post: any): Observable<any> {
    return this.httpClient.post<RespondentResponse>(`${environment.baseUrl}/users/user`, post);
  }

  updateRespondent(post: Respondent): Observable<any> {
    // this.setHeaders();
    const id = post.id;
    return this.httpClient.put<RespondentResponse>(`${environment.baseUrl}/users/user/${id}`, post);
  }

  deleteRespondent(id: string): Observable<any> {
    return this.httpClient.delete<RespondentResponse>(`${environment.baseUrl}/users/user/${id}`);
  }

  createUserUserGroup(post: any): Observable<any> {
    // this.setHeaders();
    return this.httpClient.post<UserGroupResponse>(`${environment.baseUrl}/users/usergroup/`, post);
  }

  deleteUserUserGroup(post: any): Observable<any> {
    return this.httpClient.post<UserGroupResponse>(`${environment.baseUrl}/users/usergroupdelete/`, post);
  }

  getQuestionStats(id: string): Observable<any> {
    return this.httpClient.get<GroupStatsResponseResponse>(`${environment.baseUrl}/users/user?question=${id}`);
  }

  respondents(page = 10, size = 10, search = ''): Observable<any> {
    return this.httpClient.get<RespondentsResponse>(`${environment.baseUrl}/users/user?page=${page}&page_size=${size}&search=${search}`);
  }

  respondentsWithoutPagination(search = '', group = ''): Observable<any> {
    return this.httpClient.get<RespondentsResponse>(`${environment.baseUrl}/users/user?ispage=0&search=${search}`);
  }

  getRespondentsByGroup(id: any, page = 1, size = 10, search = ''): Observable<any> {
    return this.httpClient.get<RespondentsResponse>(`${environment.baseUrl}/users/user?group=${id}&page=${page}&page_size=${size}&search=${search}`);
  }
}
