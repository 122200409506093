import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {UserService} from '../../user.service';
import {HttpClient} from '@angular/common/http';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import 'rxjs/add/operator/map';
import {CookieService} from 'ngx-cookie-service';
import {ForgotService} from './fogort.service';
import {NotificationService} from '../../notification.service';

@Component({
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.sass']
})

export class ForgotComponent implements OnInit {

  processing: boolean;
  showLoginError: boolean;
  forgotPass: FormGroup;
  username: string;
  name = 'Angular';
  model: any = {
  };

  ngOnInit() {
    this.forgotPass = new FormGroup({
      username: new FormControl('', [Validators.required])
    });
  }

  constructor(private router: Router,
              private userService: UserService,
              private http: HttpClient,
              private formBuilder: FormBuilder,
              private service: ForgotService,
              private cookieService: CookieService,
              private notification: NotificationService) {
  }

  submit(post) {
    this.username = post.username;
    const body = {
      'username': this.username
    };

    this.service.requestCode(post).subscribe(
      res => {
        this.router.navigate(['reset']);
      },
      err => {
            if (err.error.data.user) {
               this.notification.showSnackBar(err.error.data.user, 'dismiss', 3000);
            }
      }
    );

  }
}
