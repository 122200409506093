//<reference path='../../node_modules/@angular/router/src/router.d.ts'/>
import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {CookieService} from 'ngx-cookie-service';
import {Router} from '@angular/router';
import {User} from './auth/interfaces/user';
import {Authenticatedata} from './auth/login/interfaces/authenticatedata';
import {Observable} from 'rxjs/Observable';
import {RestclientService} from './restclient.service';
import {HttpClient} from '@angular/common/http';
import {environment} from '../environments/environment';
@Injectable()
export class UserService {

  private isLoggedIn: boolean;
  authNavMenuVisibility: boolean;
  user: User;
  user_id: string;
  token: string;
  expires_in: number;

  authNavMenu: Subject<boolean> = new Subject<boolean>();
  userAuth: Subject<User>   = new Subject<User>();
  user_: Subject<User> = new Subject<User>();

  constructor(private cookieService: CookieService, private router: Router, private http: HttpClient) {
    this.isLoggedIn = false;
    this.authNavMenuVisibility = true;
  }

  setIsLoggedIn(data: Authenticatedata): void {
    this.user = data.user;
    this.user_id = data.user.id;
    this.token = data.token;
    this.expires_in = data.expires_in;
    const now = new Date();
    const time = now.getTime();
    now.setTime(time + this.expires_in * 1000);
    this.cookieService.set('user_id', this.user_id, now);
    this.cookieService.set('token', this.token, now);
    this.isLoggedIn = true;
    this.updateMenuVisibility(false);
    this.userAuth.next(this.user);
  }

  setIsLoggedOut(): void {
    this.cookieService.deleteAll();
    this.isLoggedIn = false;
     // this.updateMenuVisibility(true);
    this.router.navigate(['login']);
  }

  getIsLoggedIn(): boolean {
    if (this.cookieService.get('token') === null
      || this.cookieService.get('token') === undefined
      || this.cookieService.get('token') === '') {
      this.setIsLoggedOut();
    } else {
      this.user_id = this.cookieService.get('user_id');
      this.token = this.cookieService.get('token');
      this.isLoggedIn = true;
      this.updateMenuVisibility(false);
      this.http.get(environment.baseUrl + '/users/user/' + this.user_id).
      map((data: any)=> {
          return data.data
      }).subscribe((data) => {
        this.userAuth.next(data);

      })
    }
    return this.isLoggedIn;
  }

  updateMenuVisibility(visibility: boolean) {
    this.authNavMenuVisibility = visibility;
    this.authNavMenu.next(this.authNavMenuVisibility);
  }

  getUserId(): string {
    return this.cookieService.get('token');
  }



}
