import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {HttpClient} from '@angular/common/http';
import {UserService} from './user.service';
import {environment} from '../environments/environment';
import {getHeaders} from './interceptor';
import {SurveyResponse} from './surveys/interfaces/survey-response';
import {QuestionResponse} from './questions/interfaces/question-response';
import {AuthenticateResponse} from './auth/login/interfaces/authenticate-response';
import {SignupResponse} from './auth/register/interfaces/signupResponse';
import {VerificationRequest} from './profile/verification-request';
import {SurveysResponse} from './surveys/interfaces/surveys-response';
import {QuestionsResponse} from './questions/interfaces/questions-response';

@Injectable()
export class RestclientService {

  data: AuthenticateResponse;
  base_url: string;
  headers: any;
  options: any;
  private id: string;

  constructor(private httpClient: HttpClient) {
    this.base_url = environment.baseUrl;
    // this.base_url = 'https://f90a9649.ngrok.io';
    // this.headers = new Headers();
    // this.headers.append('Authorization', userService.token);
    // this.setHeaders();
  }

  /**etHeaders() {
    this.headers = {
      // 'Authorization': 'Bearer ' + this.userService.token,
      'Authorization': 'Token ' + this.userService.token,
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      /**'Access-Control-Allow-Headers': '**
    };
    this.options = {headers: this.headers};
  }**/

  authenticateUser(post: any): Observable<AuthenticateResponse> {
    return this.httpClient.post<AuthenticateResponse>(`${this.base_url}/authenticate/`, post);
    // .map((res: Response) => res.json());
  }

  getUser(id: string): Observable<any> {
    return this.httpClient.get<SignupResponse>(`${this.base_url}/users/user/${id}`);
  }

  deleteUser(id: string) {
    return this.httpClient.delete<SignupResponse>(`${this.base_url}/users/user/${id}/`);
  }

  verifyEmailOrMobile(post: VerificationRequest): Observable<any> {
    return this.httpClient.post(`${this.base_url}/users/verify`, post);
  }

  verifyEmailOrMobileResend(post: VerificationRequest): Observable<any> {
    return this.httpClient.post(`${this.base_url}/users/resend`, post);
  }

  signupUser(post: any): Observable<any> {
    return this.httpClient.post<SignupResponse>(`${this.base_url}/users/`, post);
  }

  createUser(post: any): Observable<any> {
    return this.httpClient.post<SignupResponse>(`${this.base_url}/users/signup`, post, {headers: getHeaders});
  }

  searchUser(phone_number: any): Observable<any> {
    return this.httpClient.get(`${this.base_url}/users/signup?search=${phone_number}`, {headers: getHeaders});
  }

  survey(id: string): Observable<any> {
    return this.httpClient.get<SurveysResponse>(`${this.base_url}/surveys/survey/${id}`);
  }

  inactiveSurveys(): Observable<any> {
    return this.httpClient.get<SurveysResponse>(`${this.base_url}/surveys/survey?is_active=0`, this.options);
  }

  inactiveSurveysWithoutPagination(search: string = ''): Observable<any> {
    return this.httpClient.get<SurveysResponse>(`${this.base_url}/surveys/survey?ispage=1&is_active=0&search=${search}`);
  }

  activeSurveys(user, page = 1, size = 100, search: string = ''): Observable<any> {
    return this.httpClient.get<SurveysResponse>(`${this.base_url}/surveys/survey?page=${page}&is_active=1&user=${user}&page_size=${size}&search=${search}`, this.options);
  }

  finishedSurveys(page = 1, size = 100): Observable<any> {
    return this.httpClient.get<SurveysResponse>(`${this.base_url}/surveys/survey?is_finished=1&page_size=${size}&page=${page}`, this.options);
  }

  finishedSurveysPerUser(user_id, page = 10, size = 10, search = ''): Observable<any> {
    return this.httpClient.get<SurveysResponse>(`${this.base_url}/surveys/survey?page=${page}&is_finished=1&user=${user_id}&page_size=${size}&search=${search}`, this.options);
  }

  surveyQuestions(id: string): Observable<any> {
    return this.httpClient.get<QuestionsResponse>(`${this.base_url}/surveys/questions?survey=${id}`, this.options);
  }

  deleteSurvey(id: string): Observable<any> {

    return this.httpClient.delete<SurveyResponse>(`${this.base_url}/surveys/survey/${id}`, this.options);
  }

  sendSurvey(post: any[]): Observable<any> {
    return this.httpClient.post<QuestionResponse>(`${this.base_url}/surveys/survey/send`, post, this.options);
  }

  scheduleSurvey(post: any): Observable<any> {
    return this.httpClient.post<QuestionResponse>(`${this.base_url}/surveys/schedule`, post, this.options);
  }

  responses(params: any): Observable<any> {
    return this.httpClient.get<any>(`${this.base_url}/surveys/responses/?${params.filter}=${params.id}&open=1`, this.options);
  }

  requestCode(post): Observable<any> {
    return this.httpClient.post(`${environment.baseUrl}/users/reset`, post, {headers: getHeaders});
  }

  sendCode(post): Observable<any> {
    return this.httpClient.post(`${environment.baseUrl}/users/sendcode`, post);
  }

  verifyCode(post): Observable<any> {
    return this.httpClient.post(`${environment.baseUrl}/users/verify`, post);
  }

  updatePayments(datakey, amount) {
    return this.httpClient.get(`${environment.paymentsUrl}/?token=${datakey}&amount=${amount}`, {headers: getHeaders});
  }

  topUp(data) {
    return this.httpClient.post(`${environment.baseUrl}/users/deposit`, data);
  }

  sendSms(data: any) {
    return this.httpClient.post(`${environment.baseUrl}/sms/sendsms`, data);
  }

  requestUnitTopup(data: any) {
    return this.httpClient.post(`${environment.baseUrl}/sms/topop/`, data);
  }

  requestGlobalCredit(data) {
    return this.httpClient.post(`${environment.baseUrl}/users/deposit`, data);
  }
}
