import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {UserService} from '../user.service';
import {Chart} from 'chart.js';
import {MatTabChangeEvent} from '@angular/material/tabs';
import {MatDialog} from '@angular/material/dialog';
import {DialogComponent} from '../shared/dialog/dialog.component';
import {SurveyService} from './survey.service';
import {QuestionService} from '../questions/question.service';
import {Survey} from './interfaces/survey';
import {finalize} from 'rxjs/operators';
import {NotificationService} from '../notification.service';

@Component({
  selector: 'app-surveys',
  templateUrl: './surveys.component.html',
  styleUrls: ['./surveys.component.sass']
})
export class SurveysComponent implements OnInit {
  surveys: Survey[];
  term: string;
  editing: boolean;
  fetchingSurveys: boolean;
  panelOpenState = false;
  surveyToEdit: Survey;
  addSurveyLabel: string;
  selectedTab: number;
  editMode: boolean;
  activeSurveys: Survey[];
  inActiveSurveys: Survey[];
  closedSurveys: Survey[];
  scheduledSurveys: Survey[];
  title: string;
  content: string;
  private survey: Survey;
  search: string;
  _search: string;
  next_page = 1;
  page = 1;
  total_page = 1;
  previous_page = 1;
  size = 10;
  allinActiveSelected: boolean;
  IsinActiveIndeterminate: boolean;
  allScheduledSelected: boolean;
  IsScheduledIndeterminate: boolean;
  inactiveSurveysCount: number;

  constructor(
    private restclientService: SurveyService,
    private router: Router,
    private userService: UserService,
    private questionService: QuestionService,
    private dialog: MatDialog,
    public notification: NotificationService
  ) {
    this.surveyToEdit = <Survey>{};
    this.addSurveyLabel = 'Add a Survey';
    this.editMode = false;
    this.initializeSurveys();
    this.survey = <Survey>{};
    this.search = '';
    this._search = '';

  }

  countInactive(count: number) {
    this.inactiveSurveysCount = count;
  }
  ngOnInit() {
    this.fetchSurveys();
  }

  onChange($e) {
    if ($e.length > 2) {
      this.page = 1;
      this.restclientService
        .allSurveys(this.page, this.size, this._search)
        .subscribe(
          res => {
            this.fetchingSurveys = false;
            this.closedSurveys = res.data.results;
            this.total_page = res.data.pagination.num_pages;
            this.page = res.data.pagination.current_page;
            this.getNextPage();
            this.getPreviousPage();
          },
          err => {
            this.fetchingSurveys = false;
            if (
              err.statusText === 'Unauthorized' ||
              err.status === false ||
              err.message === 'Oops something went Wrong.'
            ) {
              this.userService.setIsLoggedOut();
            }
          }
        );
    } else if ($e.length === 0) {
      this.fetchSurveys();
    }
  }

  fetchSurveys() {
    this.fetchingSurveys = true;
    this.initializeSurveys();
    this.restclientService.surveys().subscribe(
      res => {
        this.fetchingSurveys = false;
        this.surveys = res.data.results;
        this.filterSurveys(this.surveys);
      },
      err => {
        this.fetchingSurveys = false;

        if (
          err.statusText === 'Unauthorized' ||
          err.status === false ||
          err.message === 'Ooops something went Wrong.'
        ) {
          this.userService.setIsLoggedOut();
        }
      }
    );
  }

  getNextPage() {
    this.next_page = this.page + 1;
    if (this.next_page > this.total_page) {
      this.next_page = this.total_page;
    }
    return this.next_page;
  }

  getPreviousPage() {
    if (this.page === 1) {
      this.previous_page = this.page;
    } else {
      this.previous_page = this.page - 1;
    }
    return this.previous_page;
  }

  addSurvey(): void {
    this.selectedTab = 3;
    this.editMode = false;
  }

  editSurvey(survey: Survey) {
    this.updateSecondTabLabel(2);
    this.editMode = true;
    this.selectedTab = 2;
    this.editing = true;
    this.surveyToEdit = survey;
  }

  deleteSurvey(surveys: Survey[]): void {
    this.restclientService.deleteSurveys(surveys).subscribe(
      res => {
        this.fetchSurveys();
      },
      err => {
        if (
          err.statusText === 'Unauthorized' ||
          err.status === false ||
          err.message === 'Oops something went Wrong.'
        ) {
          this.userService.setIsLoggedOut();
        }
      }
    );
  }

  importSurveys() {
  }

  exportSurveys() {
  }

  exportTemplate() {
  }

  selectedTabChange(matTabChangeEvent: MatTabChangeEvent) {
    this.selectedTab = matTabChangeEvent.index;
    this.updateSecondTabLabel(this.selectedTab);
  }

  surveyUpdated(updated: boolean): void {
    this.editMode = false;
    this.fetchSurveys();
    this.selectedTab = 0;
  }

  updateSecondTabLabel(index: number) {
    if (index === 0) {
      this.addSurveyLabel = 'Add a Survey';
    } else if (index === 1 && this.editMode) {
      this.addSurveyLabel = 'Edit Survey';
    } else {
      this.addSurveyLabel = 'Add a Survey';
    }
  }

  filterSurveys(surveys: Survey[]) {
    let index: number;
    for (index = 0; index < surveys.length; index++) {
      const survey = surveys[index];
      if (survey.is_active) {
        this.activeSurveys.push(survey);
      } else if (survey.is_scheduled) {
        this.scheduledSurveys.push(survey);
      } else if (survey.is_finished) {
        this.closedSurveys.push(survey);
      } else {
        this.inActiveSurveys.push(survey);
      }
    }
  }

  openDeleteSurveyDialog(survey: Survey): void {
    this.title = 'Delete survey below?';
    this.content = survey.title;
    this.deleteModal([survey]);

  }

  deleteModal(surveys: Survey[]) {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '350px',
      data: {title: this.title, content: this.content}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.deleteSurvey(surveys);
      }
    });
  }

  openDeleteSurveys() {
    this.title = 'Delete Surveys';
    this.content = 'Are you sure you want to delete all this surveys';
    const surveysToDelete = this.surveys.filter((survey) => survey.isChecked);
    this.deleteModal(surveysToDelete);
  }

  initializeSurveys() {
    this.surveys = [];
    this.activeSurveys = [];
    this.inActiveSurveys = [];
    this.closedSurveys = [];
    this.scheduledSurveys = [];
  }

  openActivateSurveyDialog(survey: Survey): void {
    this.survey = survey;
    this.title = 'Initiate survey now...';
    this.content = this.survey.title;
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '350px',
      data: {
        title: this.title,
        content: this.content,
        label: 'Initiate',
        class: 'btn-green'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.activateSurvey();
      }
    });
  }

  activateSurvey() {
    this.survey.is_active = true;
    this.restclientService.activateSurvey({survey: this.survey.id}).subscribe(
      res => {
        // this.goBack();
      },
      err => {
      }
    );
  }

  inactivesurveyChanged(survey, event) {
    survey.isChecked = event.checked;

    const totalSelected = this.inActiveSurveys.filter(
      survey => survey.isChecked
    ).length;
    if (totalSelected === 0) {
      this.allinActiveSelected = false;
      this.IsinActiveIndeterminate = false;
    } else if (
      totalSelected > 0 &&
      totalSelected < this.inActiveSurveys.length
    ) {
      this.allinActiveSelected = false;
      this.IsinActiveIndeterminate = true;
    } else if (totalSelected === this.inActiveSurveys.length) {
      this.allinActiveSelected = true;
      this.IsinActiveIndeterminate = false;
    }
  }

  toggleinactiveSelectAll(event) {
    this.allinActiveSelected = event.checked;
    this.inActiveSurveys.forEach(survey => {
      survey.isChecked = event.checked;
    });
  }

  scheduledsurveyChanged(survey, event) {
    survey.isChecked = event.checked;

    const totalSelected = this.scheduledSurveys.filter(
      survey => survey.isChecked
    ).length;
    if (totalSelected === 0) {
      this.allScheduledSelected = false;
      this.IsScheduledIndeterminate = false;
    } else if (
      totalSelected > 0 &&
      totalSelected < this.scheduledSurveys.length
    ) {
      this.allScheduledSelected = false;
      this.IsScheduledIndeterminate = true;
    } else if (totalSelected === this.scheduledSurveys.length) {
      this.allScheduledSelected = true;
      this.IsScheduledIndeterminate = false;
    }
  }

  togglescheduledSelectAll(event) {
    this.allScheduledSelected = event.checked;
    this.scheduledSurveys.forEach(survey => {
      survey.isChecked = event.checked;
    });
  }

  openActivateSurveysDialog() {
    this.title = 'Initiate survey now...';
    this.content = 'Are you sure you want to initiate this surveys';
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '350px',
      data: {
        title: this.title,
        content: this.content,
        label: 'Initiate',
        class: 'btn-green'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const surveyToIntiate = [];
        this.surveys.filter((survey) => survey.isChecked).forEach((survey) => {
          surveyToIntiate.push({'survey': survey.id});
        });
        this.restclientService.activateSurvey(surveyToIntiate).pipe(
          finalize(() => {
            this.fetchSurveys();
          })).subscribe((data) => {
          this.notification.showSnackBar('Surveys initiated successfully', 'dismiss', 3000);
        });
      }
    });
  }

}
