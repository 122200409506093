import { NgModule } from "@angular/core";
import { AuthRegisterComponent } from "./auth/register/auth.register.component";
import { AuthComponent } from "./auth/auth.component";
import { RouterModule, Routes } from "@angular/router";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { AuthLoginComponent } from "./auth/login/auth.login.component";
import { AnswersComponent } from "./answers/answers.component";
import { AnalyticsComponent } from "./analytics/analytics.component";
import { MappingComponent } from "./mapping/mapping.component";
import { GroupsComponent } from "./groups/groups.component";
import { SendsurveyComponent } from "./sendsurvey/sendsurvey.component";
import { RespondentComponent } from "./respondents/respondent/respondent.component";
import { RespondentsComponent } from "./respondents/respondents.component";
import { SurveyComponent } from "./surveys/survey/survey.component";
import { SurveysComponent } from "./surveys/surveys.component";
import { QuestionsComponent } from "./questions/questions.component";
import { QuestionComponent } from "./questions/question/question.component";
import { RespondentAddEditComponent } from "./respondents/respondent-add-edit/respondent-add-edit.component";
import { SurveyAddEditComponent } from "./surveys/survey-add-edit/survey-add-edit.component";
import { AboutComponent } from "./about/about.component";
import { ProfileComponent } from "./profile/profile.component";
import { EditProfileComponent } from "./profile/edit-profile/edit-profile.component";
import {QuestionAddEditComponent, QuestionEditComponent} from './questions/question-add-edit/question-add-edit.component';
import { GroupAddEditComponent } from "./groups/group-add-edit/group-add-edit.component";
import { GroupComponent } from "./groups/group/group.component";
import { SearchComponent } from "./search/search.component";
import { SettingsComponent } from "./about/settings/settings.component";
import { ArchiveComponent } from "./archive/archive.component";
import { ContactUsComponent } from "./about/contact-us/contact-us.component";
import { AuthGuard } from "./auth.guard";
import { AddEditAnswerComponent } from "./answers/add-edit-answer/add-edit-answer.component";
import { AnswerComponent } from "./answers/answer/answer.component";
import { SearchdetailComponent } from "./search/searchdetail/searchdetail.component";
import { ForgotComponent } from "./auth/forgot/fogort.component";
import { ForgotUpdateComponent } from "./auth/forgotupdate/forgotupdate.component";
import { ChangepassComponent } from "./profile/changepass/changepass.component";
import { QuestionnaireComponent } from "./surveys/questionnaire/questionnaire.component";
import { ResponsesComponent } from "./surveys/responses/responses.component";
import { SurveyStatisticsComponent } from "./surveys/survey-statistics/survey-statistics.component";
import { QuestionStatisticsComponent } from "./questions/question-statistics/question-statistics.component";
import { SendSmsComponent } from "./send-sms/send-sms.component";
import { ImportComponent } from "./import/import.component";
import { GlobalSearchComponent } from "./search/global-search/global-search.component";
import { FaqComponent } from "./about/faq/faq.component";
import { HomeComponent } from "./about/home/home.component";
import { PricingComponent } from "./about/pricing/pricing.component";
import { FeaturesComponent } from "./about/features/features.component";
import { BenefitsComponent } from "./about/benefits/benefits.component";
import { TermsOfServiceComponent } from "./about/terms-of-service/terms-of-service.component";
import { PrivacyPolicyComponent } from "./about/privacy-policy/privacy-policy.component";
import { AntiSpamPolicyComponent } from "./about/anti-spam-policy/anti-spam-policy.component";
import { SmsStatisticsComponent } from "./about/sms-statistics/sms-statistics.component";

const ROUTES: Routes = [
  // {path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard]},
  {
    path: "",
    component: AuthComponent,
    children: [
      { path: "", component: AuthLoginComponent },
      { path: "login", component: AuthLoginComponent },
      { path: "signup", component: AuthRegisterComponent },
      { path: "forgot", component: ForgotComponent },
      { path: "reset", component: ForgotUpdateComponent }
    ]
  },
  {
    path: "dashboard",
    component: DashboardComponent,
    canActivate: [AuthGuard]
  },
  /*{
    path: 'users',
    loadChildren: './respondents/respondents.module#PollUsers'
  },
  {
    path: 'surveys',
    loadChildren: './surveys/surveys.module#SurveysModule'
  },
  {
    path: 'questions',
    loadChildren: './questions/questions.module#QuestionsModule'
  },*/
  { path: "about", component: AboutComponent },
  { path: "faq", component: FaqComponent },
  { path: "home", component: HomeComponent },
  { path: "features", component: FeaturesComponent },
  { path: "pricing", component: PricingComponent },
  { path: "profile", component: ProfileComponent, canActivate: [AuthGuard] },
  { path: "benefits", component: BenefitsComponent },
  { path: "terms-of-service", component: TermsOfServiceComponent },
  { path: "privacy-policy", component: PrivacyPolicyComponent },
  { path: "anti-spam-policy", component: AntiSpamPolicyComponent },
  { path: "sms-statistics", component: SmsStatisticsComponent },
  {
    path: "profile/edit/:id",
    component: EditProfileComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "changepass",
    component: ChangepassComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "respondents",
    canActivate: [AuthGuard],
    children: [
      { path: "", component: RespondentsComponent, canActivate: [AuthGuard] },
      {
        path: "add",
        component: RespondentAddEditComponent,
        canActivate: [AuthGuard]
      },
      {
        path: "edit/:id",
        component: RespondentAddEditComponent,
        canActivate: [AuthGuard]
      },
      {
        path: "respondent/:id",
        component: RespondentComponent,
        canActivate: [AuthGuard]
      }
    ]
  },
  {
    path: "surveys",
    canActivate: [AuthGuard],
    children: [
      { path: "", component: SurveysComponent },
      { path: "add", component: SurveyAddEditComponent },
      { path: "edit/:id", component: SurveyAddEditComponent },
      { path: "survey/:id", component: SurveyComponent }
    ]
  },
  {
    path: "survey-statistics",
    canActivate: [AuthGuard],
    children: [
      { path: ":id", component: SurveyStatisticsComponent },
      { path: "questionnaire/:id", component: QuestionnaireComponent },
      { path: "responses/:id", component: ResponsesComponent }
    ]
  },
  {
    path: "questions",
    canActivate: [AuthGuard],
    children: [
      { path: "", component: QuestionsComponent },
      { path: "add", component: QuestionAddEditComponent },
      { path: "edit/:id", component: QuestionEditComponent },
      { path: "question/:id", component: QuestionComponent }
    ]
  },
  {
    path: "questions-statistics",
    canActivate: [AuthGuard],
    children: [
      { path: ":id", component: QuestionStatisticsComponent },
      { path: "add", component: QuestionAddEditComponent },
      { path: "edit/:id", component: QuestionAddEditComponent },
      { path: "question/:id", component: QuestionComponent }
    ]
  },
  {
    path: "groups",
    canActivate: [AuthGuard],
    children: [
      { path: "", component: GroupsComponent },
      { path: "add", component: GroupAddEditComponent },
      { path: "edit/:id", component: GroupAddEditComponent },
      { path: "group/:id", component: GroupComponent }
    ]
  },
  {
    path: "answers",
    canActivate: [AuthGuard],
    children: [
      { path: "", component: AnswersComponent },
      { path: "add", component: AddEditAnswerComponent },
      { path: "edit/:id", component: AddEditAnswerComponent },
      { path: "answer/:id", component: AnswerComponent }
    ]
  },
  { path: "mapping", component: MappingComponent, canActivate: [AuthGuard] },
  {
    path: "analytics",
    component: AnalyticsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "sendsurvey",
    component: SendsurveyComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "global-search",
    component: GlobalSearchComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "search",
    canActivate: [AuthGuard],
    children: [
      { path: "", component: SearchComponent },
      { path: ":searchText", component: SearchComponent },
      { path: "details/:id", component: SearchdetailComponent }
    ]
  },
  {
    path: "send-sms",
    canActivate: [AuthGuard],
    children: [{ path: ":id", component: SendSmsComponent }]
  },
  { path: "bulk-sms", component: SendSmsComponent, canActivate: [AuthGuard] },
  { path: "settings", component: SettingsComponent, canActivate: [AuthGuard] },
  { path: "archive", component: ArchiveComponent, canActivate: [AuthGuard] },
  {
    path: "contact-us",
    component: ContactUsComponent
  },
  { path: "import/:id", component: ImportComponent, canActivate: [AuthGuard] }
];

@NgModule({
  imports: [RouterModule.forRoot(ROUTES)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
