import {AfterContentChecked, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {UserService} from '../../user.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {GroupService} from '../group.service';
import {Group} from '../interfaces/group';

@Component({
  selector: 'app-group-add-edit',
  templateUrl: './group-add-edit.component.html',
  styleUrls: ['./group-add-edit.component.sass']
})
export class GroupAddEditComponent implements OnInit, OnChanges, AfterContentChecked {

  @Output() groupSubmitted: EventEmitter<boolean> = new EventEmitter();

  @Input() group: Group;
  groupToEdit: Group;
  showGroupError: boolean;
  addGroup: boolean;
  groupForm: FormGroup;
  id: string;
  isChild = false;

  constructor(private restclientService: GroupService,
              private userService: UserService,
              private activatedRoute: ActivatedRoute,
              private location: Location,
              private router: Router) {
    this.group = <Group>{};
    this.showGroupError = false;
    this.addGroup = true;
    this.initializeForm();
  }

  ngOnInit() {
    console.log(this.id);
    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    if (this.id != null) {
      this.getGroup();
    } else {
      this.groupToEdit = this.group;
      this.isChild = true;
      this.editGroup(this.group);
    }
  }

  ngAfterContentChecked(): void {
    // this.groupToEdit = this.group;
    // this.editGroup(this.group);
  }

  ngOnChanges(changes: SimpleChanges): void {
    // throw new Error('Method not implemented.');
    this.groupToEdit = this.group;
    this.editGroup(this.group);
  }

  getGroup(): void {
    this.restclientService.getGroup(this.id)
      .subscribe(res => {
          this.addGroup = false;
          this.groupToEdit = res.data;
          this.editGroup(res.data);
        },
        err => {

          if (err.statusText === 'Unauthorized' || err.status === false || err.message === 'Ooops something went Wrong.') {
            this.userService.setIsLoggedOut();
          }
        });
  }


  private initializeForm() {
    this.groupForm = new FormGroup({
      name: new FormControl('', Validators.required),
      descriptions: new FormControl('', Validators.required),
    });
  }

  editGroup(group: Group): void {
    console.log(group);
    this.groupForm.get('name').setValue(group.name);
    this.groupForm.get('descriptions').setValue(group.descriptions);
  }

  createGroup(group: Group): void {
    console.log(group);
    this.group = group;
    this.group.id = this.groupToEdit.id;
    console.log(this.group.id);
    if (this.group.id != null || this.group.id !== undefined) {
      this.restclientService.updateGroup(this.group)
        .subscribe(
          (res) => {
            this.goBack();
          },
          err => {

            if (err.statusText === 'Unauthorized' || err.status === false || err.message === 'Ooops something went Wrong.') {
              this.groupSubmitted.emit(false);
              this.userService.setIsLoggedOut();
            }
          }
        );
    } else {
      this.restclientService.createGroup(this.group)
        .subscribe(
          (res) => {
            this.goBack();
          },
          err => {

            if (err.statusText === 'Unauthorized' || err.status === false || err.message === 'Ooops something went Wrong.') {
              this.groupSubmitted.emit(false);
              this.userService.setIsLoggedOut();
            }
          }
        );
    }
  }

  goBack(): void {
    if (this.isChild) {
      this.groupSubmitted.emit(true);
    } else {
      this.location.back();
    }
  }

  cancelEditGroup(): void {
    // this.location.back();
  }

}
