import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {RestclientService} from '../restclient.service';
import {FormControl, Validators} from '@angular/forms';

@Component({
  selector: 'app-send-sms',
  templateUrl: './sms.component.html'
})
export class SmsComponent implements OnInit {
  public group: any;
  public phone_number: any;
  public message: string;
  public user: any;
  model: any;
  control: FormControl;

  ngOnInit() {
  }

  constructor(
    public dialogRef: MatDialogRef<SmsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public rest: RestclientService) {
    if (this.data.type === 'phone_number') {
      this.user = this.data.user;
    }
    this.control = new FormControl('', [Validators.required]);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }


  sendSms() {
    const data = <any>{};
    data.message = this.message;
    if (this.data.type === 'phone_number') {
      data.phone_number = this.user.phone_number;
    }

    if (this.data.type === 'group') {
      data.group = this.data.group.id;
    }

    this.rest.sendSms(data).subscribe((res) => {
      this.dialogRef.close();
    });
  }

  messageIsNotEmpty() {
    if (this.message !== '') {
      return true;
    } else {
      return false;
    }
  }

}
