import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {UserService} from '../../user.service';
import {ActivatedRoute, Router} from '@angular/router';
import {DialogComponent} from '../../shared/dialog/dialog.component';
import {MatDialog, MatSelectChange} from '@angular/material';
import {NotificationService} from '../../notification.service';
import {Location} from '@angular/common';
import {RespondentService} from '../respondent.service';
import {GroupService} from '../../groups/group.service';
import {Respondent} from '../interfaces/respondent';
import {Group} from '../../groups/interfaces/group';
import {UserGroup} from '../../groups/interfaces/user-group';
import {AgeGroup} from '../interfaces/age-group';
import {FormControl} from '@angular/forms';
import { debounceTime, tap, switchMap, finalize } from 'rxjs/operators';

@Component({
  selector: 'app-respondent',
  templateUrl: './respondent.component.html',
  styleUrls: ['./respondent.component.sass']
})
export class RespondentComponent implements OnInit {
  @Input() user: Respondent;
  id: string;
  groups: Group[];
  _groups: Group[];
  usergroups: Group[];
  selectedGroups: Group[];
  private group: UserGroup;
  private _group: Group;
  addToGroup: boolean;
  fetchingRespondent: boolean;
  panelOpenState = false;
  private title: string;
  private content: string;
  private age_group: AgeGroup;
  private error: string;
  private showError: boolean;
  loadMsg = 'Loading Respondent information...';
  private userGroup: UserGroup;
  private i: number;
  private selectedGroup: Group;
  selectedGroupControl  = new FormControl();
  searchGroup = new FormControl();
  filteredGroups: any[]
  isLoading: Boolean

  @ViewChild('groupSearch', {static: false}) groupSearchElement;

  constructor(private activatedRoute: ActivatedRoute,
              private restclientService: RespondentService,
              private groupservice: GroupService,
              private router: Router,
              private userService: UserService,
              private dialog: MatDialog,
              private notificationService: NotificationService,
              private location: Location) {
    this.group = <UserGroup>{};
    this._group = <Group>{};
    this.addToGroup = false;
    this.user = <Respondent>{};
    this.age_group = <AgeGroup>{};
    this.groups = [];
    this.usergroups = [];
    this.selectedGroups = [];
    this.showError = false;
    this.userGroup = <UserGroup>{};
    this.selectedGroup = <Group>{};
    this.filteredGroups = [];
  }

  ngOnInit() {
    this.getUser();
    this.searchGroup.valueChanges.pipe(
      debounceTime(500),
      tap( () =>{
        this.isLoading = true
        this.filteredGroups = [];
      }),
      switchMap(value => this.groupservice.groupsWithoutPagination(value).
        pipe(
          finalize(() => {
            this.isLoading = false;
          })
      ))).subscribe((data) => {
          this.filteredGroups = data.data
    });

  }

  getUser(): void {
    this.fetchingRespondent = true;
    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    if (this.id != null) {
      this.restclientService.getRespondent(this.id)
        .subscribe(res => {
            this.fetchingRespondent = false;
            this.user = res.data;
            this.fetchGroups();
            if (this.user.age_group) {
              this.getAgeGroup(this.user.age_group);
            }
          },
          err => {
            this.fetchingRespondent = false;
            this.loadMsg = 'Something went wrong while fetching the respondent...';
            this.notificationService.showSnackBar(err.error.data.detail, 'Dismiss', 3000);
            this.error = err.error.data.detail;
            this.showError = true;
            if (err.statusText === 'Unauthorized' || err.status === false || err.message === 'Ooops something went Wrong.') {
              this.userService.setIsLoggedOut();
            }
          });
    }
  }

  private getAgeGroup(id: string) {
    this.groupservice.ageGroup(id).subscribe(
      res => {
        this.age_group = res.data.results;
      },
      err => {
        if (err.statusText === 'Unauthorized' || err.status === false || err.message === 'Ooops something went Wrong.') {
          this.userService.setIsLoggedOut();
        }
      }
    );
  }

  editUser(): void {
    this.router.navigate([`respondents/edit/${this.id}`]);
  }

  private fetchGroups() {
    this.groupservice.groups(1, 10).subscribe(
      res => {
        this.groups = res.data.results;
        this.filteredGroups = this.groups
        this.filterGroups();
      },
      err => {
        if (err.statusText === 'Unauthorized' || err.status === false || err.message === 'Ooops something went Wrong.') {
          this.userService.setIsLoggedOut();
        }
      }
    );
  }

  private assignUserGroup(userUroup: UserGroup, group: Group) {
    this.restclientService.createUserUserGroup(userUroup)
      .subscribe(
        (res) => {
          this.user.usergroups.push(group);
          this.groups = this.filterOutGroup(this.groups, group);
        },
        err => {
          if (err.statusText === 'Unauthorized' || err.status === false || err.message === 'Ooops something went Wrong.') {
            this.userService.setIsLoggedOut();
          }
        }
      );
  }

  filterOutGroup(groups: Group[], group: Group): Group[] {
    return groups.filter(function (ele) {
      return ele.id !== group.id;
    });
  }

  filterGroups() {
    this.user.usergroups.forEach(function (group) {
      this.groups = this.filterOutGroup(this.groups, group);
    }, this);
  }

  private checkGroupSelection(group: Group, index: number) {
    for (let x = 0; x < this.user.usergroups.length; x++) {
      this.selectedGroup = this.user.usergroups[x];
      if (group.name === this.selectedGroup.name) {
        this._groups.splice(index, 1);
      }
    }
  }

  actionAddGroups(): void {
    this.addToGroup = false;
    let g: Group;
    let x: number;
    for (x = 0; x < this.selectedGroups.length; x++) {
      g = this.selectedGroups[x];
      this.group.user = this.user.id;
      this.group.group = g.id;
      this.assignUserGroup(this.group, g);
    }
    this.selectedGroups = [];
  }

  private updateUserGroups(data: UserGroup) {
    let group: Group;
    const selectedGroup = data;
    for (let x = 0; x < this.groups.length; x++) {
      group = this.groups[x];
      if (group.id === selectedGroup.group) {
        this.groups.splice(x, 1);
        this.user.usergroups.push(group);
      }
    }
  }

  openRespondentDeleteDialog(group: any, index: number): void {
    this._group = group;
    this.userGroup.user = this.user.id;
    this.userGroup.group = group.id;
    this.i = index;
    this.title = 'Remove user from group?';
    this.content = 'Remove respondent from a group?';
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '350px',
      data: {title: this.title, content: this.content}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.removeUserGroup(this.userGroup, this.i);
      }
    });
  }

  private removeUserGroup(group: UserGroup, index: number) {
    this.restclientService.deleteUserUserGroup(group)
      .subscribe(
        (res) => {
          // this.getUser();
          this.user.usergroups.splice(index, 1);
          this.groups.push(this._group);
        },
        err => {
          if (err.statusText === 'Unauthorized' || err.status === false || err.message === 'Ooops something went Wrong.') {
            this.userService.setIsLoggedOut();
          }
        }
      );
  }

  openRespondentDialog(): void {
    this.title = 'Delete respondent below?';
    this.content = this.user.first_name + ' ' + this.user.last_name;
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '350px',
      data: {title: this.title, content: this.content}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.deleteUser();
      }
    });
  }

  deleteUser(): void {
    this.restclientService.deleteUser(this.user.id)
      .subscribe(
        (res) => {
          this.notificationService.showSnackBar('Respondent has been deleted successfully.', 'Dismiss', 3000);
          this.router.navigate(['respondents']);
        },
        err => {
          if (err.statusText === 'Unauthorized' || err.status === false || err.message === 'Ooops something went Wrong.') {
            this.userService.setIsLoggedOut();
          }
        }
      );
  }

  removeFromSelectedGroups(i: number) {
    this.selectedGroups.splice(i, 1);
  }

  private goBack() {
    this.location.back();
  }


  assignUserGroups($event: MatSelectChange) {
    this.selectedGroups = $event.value;
  }

  clearSearch(event) {
    event.stopPropagation();
    this.selectedGroupControl.patchValue('');
  }

  addGroups() {
    const groups  = []
    this.selectedGroupControl.value.forEach(function (item) {
        groups.push({'group': item.id, 'user': this.user.id});
    }, this)
    this.restclientService.createUserUserGroup(groups).pipe(
      finalize (() => {
        this.getUser()
        this.groupSearchElement.close();
      })).subscribe((data) =>  {

      });
  }
}
