import {Component, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith, switchMap} from 'rxjs/operators';
import {Survey} from '../../surveys/interfaces/survey';
import {SurveyService} from '../../surveys/survey.service';
import {UserService} from '../../user.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-global-search',
  templateUrl: './global-search.component.html',
  styleUrls: ['./global-search.component.sass']
})
export class GlobalSearchComponent implements OnInit {
  surveys: Survey[];
  size = 10;
  page = 1;
  search: string;
  searchControl: FormControl;
  constructor(private restclientService: SurveyService,
              private userService: UserService,
              private router: Router) {
    this.searchControl = new FormControl();
  }


  options: Survey[] = [];
  filteredOptions: Observable<string[]>;

  ngOnInit() {
    // this.filterOptions();
    this.searchControl.valueChanges
      .pipe(
        switchMap(value => this.restclientService.surveyWithoutPagination(value))
        // map(value => this.filterValues(value))
      ).subscribe((data) => {
         console.log(data)
         this.surveys = data.data
    });

  }



  onChange($e) {
    console.log($e)
    /**if ($e.length > 2) {
      this.page = 1;
      this.search = $e;
     // this.fetchSurveys(this.search);
    } else if ($e.length === 0) {
      this.fetchSurveys();
    }**/
  }

  displaySurvey(survey) {
      if (survey) {
        return survey.title;
      }
  }

  fetchSurveys(s: string = '') {
    this.restclientService.allSurveys(this.page, this.size, s).subscribe(
      res => {
        this.surveys = res.data.results;
        this.options = this.surveys.map(value => value);
        // this.filterOptions();
      },
      err => {
        if (err.statusText === 'Unauthorized' || err.status === false || err.message === 'Ooops something went Wrong.') {
          this.userService.setIsLoggedOut();
        }
      }
    );
  }



  searchText($event) {
   // this.router.navigate(['search', this.search]);

    this.router.navigateByUrl('/surveys/survey/' + $event.option.value.id);
  }

  clear() {
    this.searchControl.setValue('');
    this.search = '';
  }
}
