import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {UserService} from '../user.service';
import {RestclientService} from '../restclient.service';
import {Router} from '@angular/router';
import {MatDialog, MatDialogConfig} from '@angular/material';
import {VerifyDialogComponent} from './verify-dialog/verify-dialog.component';
import {RespondentService} from '../respondents/respondent.service';
import {PasswordCheckComponent} from '../shared/passwordcheck/passwordcheck.component';
import {User} from '../auth/interfaces/user';
import {VerificationRequest} from './verification-request';
import {DeleteComponent} from './delete/delete.component';
import {FormControl} from '@angular/forms';
import {finalize} from 'rxjs/operators';
import {NotificationService} from '../notification.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.sass']
})
export class ProfileComponent implements OnInit, AfterViewInit {
  minSMSorder = 100;
  maxSMSorder = 100000;
  minGlobalCredit = 1;
  maxGlobalCredit = 100;
  user: User;
  user_id: string;
  verification: VerificationRequest;
  code: string;
  toBeVerified: string;
  id: string;
  first_name: string;
  last_name: string;
  phone_number: string;
  sms_header: string;
  is_new_phone: string;
  is_new_email: string;
  phone_code: string;
  email_code: string;
  is_phone_verified: boolean;
  is_email_verified: boolean;
  account_top_up: boolean;
  email: string;
  balance: number;
  amount: number;
  addfunds: boolean;
  buy_units: boolean;
  private title: string;
  private content: string;
  is_mobile = false;
  is_email = false;
  units: FormControl;

  @ViewChild('iframe', {read: true, static: false}) iframe: ElementRef;
  @ViewChild('monerisResponse', {read: true, static: false}) monerisResponse: ElementRef;
  buy_sms: any;
  activate_global_account: any;
  private globalcredit: any;

  constructor(private userService: UserService,
              private restclientService: RestclientService,
              private respondent: RespondentService,
              private router: Router,
              private dialog: MatDialog,
              private notification: NotificationService) {
    this.units = new FormControl('');
    this.verification = <VerificationRequest>{};
    this.verification.code = this.code;
    if (this.userService.getIsLoggedIn()) {
      this.user_id = this.userService.user_id;
      this.restclientService.getUser(this.user_id).subscribe(
        res => {
          this.user = res.data;
          this.initializeUser();
        },
        err => {

        }
      );
    }
  }


  respMsg(event: any) {
    const response: any = event;
    if (response.data !== undefined) {
      const data: any = JSON.parse(response.data);
      if (data.dataKey) {
        const key = data.dataKey;
        this.restclientService.updatePayments(key, this.amount).subscribe(
          (rsp: any) => {
            if (rsp.status === 'success') {
              this.restclientService.topUp({'user': this.user.id, 'amount': this.amount}).subscribe(
                (response: any) => {
                  this.restclientService.getUser(this.user_id).subscribe(
                    res => {
                      this.user = res.data;
                      this.initializeUser();
                    },
                    err => {

                    }
                  );
                },
                (error) => {
                  console.log(error);
                }
              );
            }
          },
          (error) => {
            console.log(error);
          });
      }
    }
  }


  ngOnInit() {
    if (window.addEventListener) {
      window.addEventListener('message', this.respMsg.bind(this), false);
    } else {
      (<any>window).attachEvent('onmessage', this.respMsg.bind(this));
    }
  }

  submitMoneris() {
    const monFrameRef = this.iframe.nativeElement.contentWindow;
    monFrameRef.postMessage('', 'https://esqa.moneris.com/HPPtoken/index.php');
  }


  openPassworodCheckDialog() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    // dialogConfig.height = '250px';
    dialogConfig.width = '350px';

    const dialogRef = this.dialog.open(PasswordCheckComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        if (data.is_passsword_verified === true) {
          this.updateProfile();
        } else {
          alert('Invalid Password');
        }
      }
    });
  }

  initializeUser() {
    this.id = this.user.id;
    this.verification.user = this.id;
    this.first_name = this.user.first_name;
    this.last_name = this.user.last_name;
    this.phone_number = this.user.phone_number;
    this.sms_header = this.user.sms_header;
    this.is_new_phone = this.user.is_new_phone;
    this.is_new_email = this.user.is_new_email;
    this.phone_code = this.user.phone_code;
    this.email_code = this.user.email_code;
    this.is_phone_verified = this.user.is_phone_verified;
    this.is_email_verified = this.user.is_email_verified;
    this.email = this.user.email;
    this.balance = this.user.balance;
  }

  onEmailVerifiedChange(value) {
    this.verification.type = 'email';
    this.toBeVerified = this.email;
    this.is_email = true;
    this.openVerificationDialog();
  }

  onPhoneVerifiedChange(value) {
    this.verification.type = 'mobile';
    this.toBeVerified = this.phone_number;
    this.is_mobile = true;
    this.openVerificationDialog();
  }

  onAccountTopUp(value) {
    this.verification.type = 'number';
    this.toBeVerified = this.phone_number;
    this.openVerificationDialog();
  }

  verifyEmailOrMobile(contact: string): void {
    this.verification.code = contact;
    this.restclientService.verifyEmailOrMobileResend(this.verification).subscribe(
      res => {
        console.log(res);
      },
      err => {

      }
    );
  }

  verifyDetail(): void {
    this.verification.code = this.code;
    this.restclientService.verifyEmailOrMobile(this.verification).subscribe(
      res => {
        console.log(res);
      },
      err => {

      }
    );
  }

  cancelVerification(): void {
    if (this.verification.type === 'mobile') {
      this.is_phone_verified = !this.is_phone_verified;
    } else {
      this.is_email_verified = !this.is_email_verified;
    }
  }

  openVerificationDialog(): void {
    this.title = 'Verify contact';
    this.content = `You are about to verify ${this.toBeVerified}`;
    const dialogRef = this.dialog.open(VerifyDialogComponent, {
      width: '350px',
      data: {
        title: this.title,
        content: this.content,
        code: '',
        is_mobile: this.is_mobile,
        is_email: this.is_email
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.is_mobile === true) {
          this.is_phone_verified = true;
        } else {
          this.is_phone_verified = false;
        }

        if (result.is_email === true) {
          this.is_email_verified = true;
        } else {
          this.is_email_verified = false;
        }
      } else {
        this.cancelVerification();
      }
    });
  }


  openDeleteDialog(): void {
    this.title = 'Close Account';
    this.content = 'Confirm you want to permanently close this account';
    const dialogRef = this.dialog.open(DeleteComponent, {
      width: '350px',
      data: {
        title: this.title,
        content: this.content,
        code: this.user_id
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.is_deleted === true) {
          alert('Account closed successfully');
        } else {
          alert('Account closure  failed');
        }
      } else {

      }
    });
  }

  updateProfile(): void {
    this.router.navigate([`profile/edit/${this.user_id}`]);
  }

  changePassword() {
    this.userService.updateMenuVisibility(false);
    this.router.navigate(['/profile/change-password']);
    this.restclientService.requestCode(this.phone_number).subscribe(
      res => {
        console.log('Code requested successfully');
        // this.router.navigate(['/profile/change-password']);
      },
      err => {

      }
    );
  }

  onLoad() {
    /*const iframe   = document.getElementById('monerisFrame');
    const iWindow = (<HTMLIFrameElement>iframe).contentWindow
    const doc = (<HTMLIFrameElement>iframe).contentDocument || (<HTMLIFrameElement>iframe).contentWindow.document;
    // console.debug(doc);
    console.log('------------------------------------------------')
    console.log(doc.getElementById('monerisDataLabel').innerText);*/
  }

  ngAfterViewInit(): void {
    // this.onLoad();
  }

  userDelete() {
    this.restclientService.deleteUser(this.user_id).subscribe(
      (data) => {
        alert('Account closure successful');
      },
      (error) => {
        alert('Account closure not successful');
      }
    );
  }

  addUnits() {
    if (this.units.valid) {
      this.restclientService.requestUnitTopup({'amount': this.units.value}).subscribe((data) => {
        this.units.reset()
        this.notification.showSnackBar('Your top-up request for ' + this.units.value + ' bulk SMS units is being processed. Expect a call from the CharText sales team', 'Dismiss', 3000);
      });
    }
  }

  addGlobalcredit() {
    if (this.globalcredit.valid) {
      this.restclientService.requestGlobalCredit( {'amount': this.globalcredit.value}).subscribe((data) => {
        this.notification.showSnackBar('Your request for ' + this.units.value + ' Global credit is being processed. Expect a call from the CharText sales team', 'Dismiss', 3000);
      });
    }
  }
}
