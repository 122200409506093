import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../../user.service';
import {Location} from '@angular/common';
import {MatDialog} from '@angular/material';
import {DialogComponent} from '../../shared/dialog/dialog.component';
import {QuestionService} from '../question.service';
import {AnswerService} from '../../answers/answer.service';
import {AnalyticsService} from '../../analytics/analytics.service';
import {Question} from '../interfaces/question';
import {Answer} from '../../answers/interfaces/answer';
import {Stats} from '../../groups/interfaces/stats';
import {ChartyData} from '../../charty/charty-data';
import {ResponsesParams} from '../../surveys/responses/responses-params';

@Component({
  selector: 'app-question-statistics',
  templateUrl: './question-statistics.component.html',
  styleUrls: ['./question-statistics.component.sass']
})
export class QuestionStatisticsComponent implements OnInit {
  chart = [];
  @Input() question: Question;
  id: string;
  answers: Answer[];
  selectedAnswer: Answer;
  editing: boolean;
  answer: Answer;
  fetchingQuestion: boolean;

  title: string;
  content: string;

  selectedTab: number;
  private anlayticsData: any;

  private stats: Stats;
  public RespondenseData: ChartyData;
  public RespondentsPerQuestion: ChartyData;
  public RespondensePerChoice: ChartyData;
  public ResponsesByLocation: ChartyData;
  public ResponsesByGender: ChartyData;
  public ResponsesByAgeGroup: ChartyData;

  dataSet: boolean;
  rpqDataSet: boolean;
  rpcDataSet: boolean;
  rblDataSet: boolean;
  rbgDataSet: boolean;
  rbagDataSet: boolean;
  showAll: boolean;
  allToggle: string;
  showResponses: boolean;
  params: ResponsesParams;

  constructor(private activatedRoute: ActivatedRoute,
              private restclientService: QuestionService,
              private answerservice: AnswerService,
              private router: Router,
              private userService: UserService,
              private location: Location,
              private dialog: MatDialog,
              private analytics: AnalyticsService) {
    this.question = <Question>{};
    this.selectedAnswer = <Answer>{};
    this.answer = <Answer>{};
    this.answers = [];
    this.editing = false;
    this.selectedTab = 0;
    this.rpqDataSet = false;
    this.rpcDataSet = false;
    this.rblDataSet = false;
    this.rbgDataSet = false;
    this.rbagDataSet = false;
    this.dataSet = false;
    this.showResponses = false;
    this.RespondenseData = <ChartyData>{};
    this.params = <ResponsesParams>{};
    this.RespondentsPerQuestion = <ChartyData>{};
    this.RespondensePerChoice = <ChartyData>{};
    this.ResponsesByLocation = <ChartyData>{};
    this.ResponsesByGender = <ChartyData>{};
    this.ResponsesByAgeGroup = <ChartyData>{};
    this.allToggle = 'All';
  }

  ngOnInit() {
    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    if (this.id) {
      this.getQuestion(this.id);
      this.getQuestionStats(this.id);
      this.anlayticsData = this.analytics.getResponsePerQuestion(this.id);
    }
  }

  getQuestion(id: string): void {
    this.fetchingQuestion = true;
    this.restclientService.question(id)
      .subscribe(res => {
        this.fetchingQuestion = false;
        this.question = res.data;
        if (this.question.question_type === 'CLOSED') {
          this.getAnswers(this.id);
        } else {
          this.params.id = this.id;
          this.params.filter = 'question';
          this.showResponses = true;
        }
      }, err => {
        if (err.statusText === 'Unauthorized' || err.status === false || err.message === 'Ooops something went Wrong.') {
          this.userService.setIsLoggedOut();
        }
      });
  }

  editQuestion(): void {
    this.router.navigate([`questions/edit/${this.id}`]);
  }

  deleteQuestion(): void {
    this.restclientService.deleteQuestion(this.question.id)
      .subscribe(
        (res) => {
          this.router.navigate(['questions']);
        },
        err => {
          if (err.statusText === 'Unauthorized' || err.status === false || err.message === 'Ooops something went Wrong.') {
            this.userService.setIsLoggedOut();
          }
        }
      );
  }

  private getAnswers(id: string) {
    this.restclientService.questionAnswers(id).subscribe(
      res => {
        this.answers = res.data.results;
        this.answerView(this.answers);
      },
      err => {
        if (err.statusText === 'Unauthorized' || err.status === false || err.message === 'Ooops something went Wrong.') {
          this.userService.setIsLoggedOut();
        }
      }
    );
  }

  addAnswer(): void {
    this.selectedAnswer = <Answer>{};
    this.selectedAnswer.question = this.id;
    const choice_value = this.answers.length + 1;
    this.selectedAnswer.choice_value = choice_value.toString();
    this.editing = true;
  }

  answerSubmittedHandler(answer: Answer): void {
    this.editing = false;
    this.answers.push(answer);
    this.getAnswers(this.id);
  }

  deleteAnswer(answer: Answer): void {
    this.answerservice.deleteAnswer(answer.id)
      .subscribe(
        (res) => {
          this.getAnswers(this.id);
        },
        err => {
          if (err.statusText === 'Unauthorized' || err.status === false || err.message === 'Ooops something went Wrong.') {
            this.userService.setIsLoggedOut();
          }
        }
      );
  }

  goBack(): void {
    this.location.back();
  }

  openQuestionDialog(): void {
    this.title = 'Delete question below?';
    this.content = this.question.description;
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '350px',
      data: {title: this.title, content: this.content}
    });

    dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed');
      if (result) {
        this.deleteQuestion();
      }
    });
  }

  openAnswerDialog(answer: Answer): void {
    const ans = answer;
    this.title = 'Delete answer below?';
    this.content = ans.description;
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '350px',
      data: {title: this.title, content: this.content}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.deleteAnswer(ans);
      }
    });
  }


  private getQuestionStats(id: string) {
    this.restclientService.getQuestionStats(id)
      .subscribe(res => {
          this.stats = res.data.results;
          this.initializeDataVisualization();
        },
        err => {
          console.log(err);
          if (err.statusText === 'Unauthorized' || err.status === false || err.message === 'Ooops something went Wrong.') {
            this.userService.setIsLoggedOut();
          }
        });
  }

  private answerView(data) {
    const labels = [];
    const counts = [];

    for (const q of data) {
      labels.push(q.description);
      if (q.total_respondents.length > 0) {
        for (const t of q.total_respondents) {
          counts.push(t.total_respondent);
        }
      } else {
        counts.push(0);
      }
    }

    this.RespondensePerChoice.title = 'Respondense to all questions';
    this.RespondensePerChoice.type = 'pie';
    this.RespondensePerChoice.counts = counts;
    this.RespondensePerChoice.labels = labels;
    this.rpcDataSet = true;

    this.RespondenseData = this.RespondensePerChoice;
    this.dataSet = true;

  }

  private initializeDataVisualization() {
    let labels = [];
    let counts = [];

    for (const gender_stat of this.stats.count_by_gender) {
      labels.push(gender_stat.gender);
      counts.push(gender_stat.total);
    }

    this.ResponsesByGender.title = 'Responses By Gender';
    this.ResponsesByGender.type = 'pie';
    this.ResponsesByGender.counts = counts;
    this.ResponsesByGender.labels = labels;
    this.rbgDataSet = true;

    if (!this.dataSet) {
      this.RespondenseData = this.ResponsesByGender;
      // this.data_set = true;
    }

    labels = [];
    counts = [];

    for (const location_stat of this.stats.count_by_location) {
      labels.push(location_stat.location);
      counts.push(location_stat.total);
    }

    this.ResponsesByLocation.title = 'Responses By Location';
    this.ResponsesByLocation.type = 'pie';
    this.ResponsesByLocation.counts = counts;
    this.ResponsesByLocation.labels = labels;
    this.rblDataSet = true;

    labels = [];
    counts = [];

    for (const age_group_stat of this.stats.count_by_age_group) {
      labels.push(age_group_stat.age_group__name);
      counts.push(age_group_stat.total);
    }

    this.ResponsesByAgeGroup.title = 'Responses By Age Group';
    this.ResponsesByAgeGroup.type = 'pie';
    this.ResponsesByAgeGroup.counts = counts;
    this.ResponsesByAgeGroup.labels = labels;
    this.rbagDataSet = true;
  }

  toggleAll() {
    this.showResponses = true;
    if (this.showAll) {
      this.showAll = false;
      this.allToggle = 'All';
    } else {
      this.showAll = true;
      this.allToggle = 'Main';
    }
  }

  toggleView(view: string) {
    this.showAll = false;
    this.allToggle = 'All';
    if (view === 'by_location') {
      this.showResponses = false;
      this.dataSet = true;
      this.RespondenseData = this.ResponsesByLocation;
    } else if (view === 'by_gender') {
      this.showResponses = false;
      this.RespondenseData = this.ResponsesByGender;
      this.showResponses = false;
      this.dataSet = true;
    } else if (view === 'by_age_group') {
      this.showResponses = false;
      this.dataSet = true;
      this.RespondenseData = this.ResponsesByAgeGroup;
    } else {
      if (this.params.id) {
        this.showResponses = true;
        this.rpcDataSet = false;
        this.dataSet = false;
      }
      this.RespondenseData = this.RespondensePerChoice;
    }
  }

}
