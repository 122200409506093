import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {RestclientService} from '../restclient.service';
import {UserService} from '../user.service';
import {MatDialog, MatTabChangeEvent} from '@angular/material';
import {DialogComponent} from '../shared/dialog/dialog.component';
import {SurveyService} from '../surveys/survey.service';
import {AnalyticsService} from './analytics.service';
import {Survey} from '../surveys/interfaces/survey';
import {finalize} from 'rxjs/operators';
import {NotificationService} from '../notification.service';

@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.sass']
})
export class AnalyticsComponent implements OnInit {
  surveys: Survey[];
  editing: boolean;
  fetchingSurveys: boolean;
  surveyToEdit: Survey;
  addSurveyLabel: string;
  selectedTab: number;
  editMode: boolean;
  activeSurveys: Survey[];
  inActiveSurveys: Survey[];
  closedSurveys: Survey[];
  scheduledSurveys: Survey[];
  title: string;
  content: string;
  search: string;
  _search: string;
  private survey: Survey;
  size = 10;
  page_number = [10, 20, 50, 100];
  page = 1;
  user: string;
  is_closed_surveys_previous: boolean;
  is_closed_surveys_next: boolean;
  is_active_surveys_previous: boolean;
  is_active_surveys_next: boolean;
  is_finished_surveys_previous: boolean;
  is_finished_surveys_next: boolean;
  search_finished: string = '';
  search_active: string = '';
  size_active = 10;
  next_page_active = 0;
  previous_page_active = 0;
  current_page_active = 1;
  total_page_active = 0;
  previous_page = 0;
  next_page = 0;
  total_page = 0;
  allSelected: boolean;
  allActiveSelected: boolean;
  allinActiveSelected: boolean;
  allScheduledSelected: boolean;
  IsIndeterminate: boolean;
  IsActiveIndeterminate: boolean;
  IsChecked: boolean;
  LabelAlign: string;
  IsDisabled: boolean;
  selectedSurvey: Survey;

  constructor(
    private restclientService: SurveyService,
    private router: Router,
    private userService: UserService,
    private dialog: MatDialog,
    private analytics: AnalyticsService,
    private restService: RestclientService,
    public  notification: NotificationService
  ) {
    this.surveyToEdit = <Survey>{};
    this.survey = <Survey>{};
    this.addSurveyLabel = 'Add a Survey';
    this.editMode = false;
    this.search = '';
    this._search = '';
    this.initializeSurveys();
    this.IsChecked = false;
    this.IsIndeterminate = false;
    this.IsActiveIndeterminate = false;
    this.LabelAlign = 'after';
    this.IsDisabled = false;
    this.allSelected = false;
    this.allActiveSelected = false;
    this.allinActiveSelected = false;
    this.allScheduledSelected = false;
  }

  ngOnInit() {
    this.user = this.userService.getUserId();
    //this.fetchSurveys();
    this.getFinishedSurveys();
    this.getActiveSurveys();
  }

  private fetchSurveys() {
    this.fetchingSurveys = true;
    this.initializeSurveys();
    this.restclientService.surveys(this.page, this.size).subscribe(
      res => {
        console.log(res)
        this.fetchingSurveys = false;
        this.surveys = res.data.results;
        this.page = res.pagination.current_page;
        this.filterSurveys(this.surveys);
      },
      err => {
        this.fetchingSurveys = false;
        if (
          err.statusText === 'Unauthorized' ||
          err.status === false ||
          err.message === 'Ooops something went Wrong.'
        ) {
          this.userService.setIsLoggedOut();
        }
      }
    );
  }

  private getFinishedSurveys(page?: number, search: string = '') {
    if (page) {
      this.page = page;
    }
    this.restService
      .finishedSurveysPerUser(this.user, this.page, this.size, search)
      .subscribe(
        res => {
          this.closedSurveys = res.data.results;
          this.page = res.data.pagination.current_page;
          this.total_page = res.data.pagination.num_pages;
          this.is_finished_surveys_next = false;
          this.is_finished_surveys_previous = false;
          if (this.total_page == this.page) {
            this.next_page = this.total_page;
          } else {
            this.next_page = this.page + 1;
          }

          this.next_page = this.page + 1;

          if (this.page > 1) {
            this.previous_page = this.page - 1;
          } else {
            this.previous_page = 1;
          }
          if (this.page > 1) {
            this.is_finished_surveys_previous = true;
          }

          if (this.page <= this.total_page) {
            this.is_finished_surveys_next = true;
          }
        },
        err => {
          console.log(err);
        }
      );
  }

  changeSizeFinished($event) {
    this.size = $event.target.value;
    this.page = 1;
    this.getFinishedSurveys(this.page);
  }

  public getMoreFinishedPreviousPage() {
    this.page = this.previous_page;
    this.getFinishedSurveys(this.page);
  }

  public getMoreNextPreviousPage() {
    this.page = this.next_page;
    this.getFinishedSurveys(this.page);
  }

  onChangeFinished($event) {
    this.getFinishedSurveys(1, $event);
  }

  private getActiveSurveys(page?: number, search = '') {
    if (page) {
      this.current_page_active = page;
    }
    this.restService
      .activeSurveys(
        this.user,
        this.current_page_active,
        this.size_active,
        search
      )
      .subscribe(
        res => {
          this.activeSurveys = res.data.results;
          this.current_page_active = res.data.pagination.current_page;
          this.total_page_active = res.data.pagination.num_pages;
          this.is_active_surveys_previous = false;
          this.is_active_surveys_next = false;

          if (this.current_page_active <= 1) {
            this.previous_page_active = 1;
          } else {
            this.previous_page_active = this.current_page_active - 1;
          }
          if (this.current_page_active >= this.total_page_active) {
            this.next_page_active = this.total_page_active;
          } else {
            this.next_page_active = this.current_page_active + 1;
          }

          if (this.current_page_active > 1) {
            this.is_active_surveys_previous = true;
          }
          if (this.current_page_active < this.total_page_active) {
            this.is_active_surveys_next = true;
          }
        },
        err => {
          console.log(err);
        }
      );
  }

  changeSizeActive($event) {
    this.size_active = $event.target.value;
    this.current_page_active = 1;
    this.getActiveSurveys(this.current_page_active);
  }

  public getMoreActivePreviousPage() {
    this.current_page_active = this.previous_page_active;
    this.getActiveSurveys(this.current_page_active);
  }

  public getMoreActiveNextPage() {
    this.current_page_active = this.next_page_active;
    this.getActiveSurveys(this.current_page_active);
  }

  onChangeActive($event) {
    this.getActiveSurveys(1, $event);
  }

  openDeleteSurveyDialog(survey: Survey): void {
    this.title = 'Delete survey below?';
    this.content = survey.title;
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '350px',
      data: {title: this.title, content: this.content}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.deleteSurvey(survey);
      }
    });
  }

  addSurvey(): void {
    this.selectedTab = 3;
    this.editMode = false;
  }

  editSurvey(survey: Survey) {
    this.updateSecondTabLabel(1);
    this.editMode = true;
    this.selectedTab = 1;
    this.editing = true;
    this.surveyToEdit = survey;
  }

  deleteSurvey(survey: Survey): void {
    this.restclientService.deleteSurvey(survey.id).subscribe(
      res => {
        this.fetchSurveys();
      },
      err => {
        if (
          err.statusText === 'Unauthorized' ||
          err.status === false ||
          err.message === 'Ooops something went Wrong.'
        ) {
          this.userService.setIsLoggedOut();
        }
      }
    );
  }

  importSurveys() {
  }

  exportSurveys() {
  }

  exportTemplate() {
  }

  selectedTabChange(matTabChangeEvent: MatTabChangeEvent) {
    this.selectedTab = matTabChangeEvent.index;
    this.updateSecondTabLabel(this.selectedTab);
  }

  surveyUpdated(updated: boolean): void {
    this.editMode = false;
    this.fetchSurveys();
    this.selectedTab = 0;
  }

  updateSecondTabLabel(index: number) {
    if (index === 0) {
      this.addSurveyLabel = 'Add a Survey';
    } else if (index === 1 && this.editMode) {
      this.addSurveyLabel = 'Edit Survey';
    } else {
      this.addSurveyLabel = 'Add a Survey';
    }
  }

  private filterSurveys(surveys: Survey[]) {
    let index: number;
    for (index = 0; index < surveys.length; index++) {
      const survey = surveys[index];
      if (survey.is_active) {
        //this.activeSurveys.push(survey);
      } else if (survey.is_scheduled) {
        this.scheduledSurveys.push(survey);
      }
    }
  }

  openSurveyDialog(survey: Survey): void {
    this.title = 'Delete survey...';
    this.content = survey.title;
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '350px',
      data: {title: this.title, content: this.content}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.deleteSurvey(survey);
      }
    });
  }

  private initializeSurveys() {
    this.surveys = [];
    this.activeSurveys = [];
    this.inActiveSurveys = [];
    this.closedSurveys = [];
    this.scheduledSurveys = [];
  }

  openRecycleSurveyDialog(survey: Survey) {
    this.survey = survey;
    this.title = 'Clone survey to the hub...';
    this.content = this.survey.title;
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '350px',
      data: {
        title: this.title,
        content: this.content,
        label: 'Clone',
        class: 'btn-green'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.recycleSurvey();
        this.getFinishedSurveys();
      }
    });
  }

  private recycleSurvey() {
    this.restclientService.recycleSurvey({survey: this.survey.id}).subscribe(
      (data) => {
        this.notification.showSnackBar('Cloned suvery successfully','dismiss', 300)
      }
    );
  }

  openRecyleandIntiate(survey: Survey) {
    this.survey = survey;
    this.title = 'Initiate survey now...';
    this.content = this.survey.title;
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '350px',
      data: {
        title: this.title,
        content: this.content,
        label: 'Initiate',
        class: 'btn-green'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.recycleActivateSurvey();
      }
    });
  }

  private recycleActivateSurvey() {
    this.restclientService
      .recycleSurvey({survey: this.survey.id, is_initiate: 1})
      .subscribe(
        res => {
          this.getFinishedSurveys();
          this.getActiveSurveys();
          this.notification.showSnackBar('Survey Intiated successfully', 'dismiss', 300);
        });
  }

  MoreSurveys(event) {
    this.size = event.target.value;
    this.page = 1;
    console.log('more survey');
  }

  getMoreSurveys(type: string) {
    if (type === 'previous') {
      this.page = this.getPreviousPage();
      this.fetchSurveys();
    } else {
      this.page = this.getNextPage();
      console.log(this.page);
      this.fetchSurveys();
    }
  }

  getNextPage() {
    this.next_page = this.page + 1;
    if (this.next_page > this.total_page) {
      this.next_page = this.total_page;
    }
    return this.next_page;
  }

  getPreviousPage() {
    if (this.page === 1) {
      this.previous_page = this.page;
    } else {
      this.previous_page = this.page - 1;
    }

    return this.previous_page;
  }

  surveyChanged(survey, event) {
    survey.isChecked = event.checked;

    const totalSelected = this.closedSurveys.filter(survey => survey.isChecked)
      .length;
    if (totalSelected === 0) {
      this.allSelected = false;
      this.IsIndeterminate = false;
    } else if (totalSelected > 0 && totalSelected < this.closedSurveys.length) {
      this.allSelected = false;
      this.IsIndeterminate = true;
    } else if (totalSelected === this.closedSurveys.length) {
      this.allSelected = true;
      this.IsIndeterminate = false;
    }
  }

  toggleSelectAll(event) {
    this.allSelected = event.checked;
    this.closedSurveys.forEach(survey => {
      survey.isChecked = event.checked;
    });
  }

  activesurveyChanged(survey, event) {
    survey.isChecked = event.checked;

    const totalSelected = this.activeSurveys.filter(survey => survey.isChecked)
      .length;
    if (totalSelected === 0) {
      this.allActiveSelected = false;
      this.IsActiveIndeterminate = false;
    } else if (totalSelected > 0 && totalSelected < this.activeSurveys.length) {
      this.allActiveSelected = false;
      this.IsActiveIndeterminate = true;
    } else if (totalSelected === this.activeSurveys.length) {
      this.allActiveSelected = true;
      this.IsActiveIndeterminate = false;
    }
  }

  toggleactiveSelectAll(event) {
    this.allActiveSelected = event.checked;
    this.activeSurveys.forEach(survey => {
      survey.isChecked = event.checked;
    });
  }

  openActivateSurveysDialog() {
    this.title = 'Initiate survey now...';
    this.content = 'Are you sure you want to initiate this surveys';
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '350px',
      data: {
        title: this.title,
        content: this.content,
        label: 'Initiate',
        class: 'btn-green'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const surveyToIntiate = [];
        this.closedSurveys.filter((survey) => survey.isChecked).forEach((survey) => {
          surveyToIntiate.push({'survey': survey.id});
        });
        this.restclientService.activateSurvey(surveyToIntiate).pipe(
          finalize(() => {
            this.getFinishedSurveys();
          })).subscribe((data) => {
          this.notification.showSnackBar('Surveys intiated successfully', 'dismiss', 3000);
        });
      }
    });
  }

  CopySurveys() {
    this.title = 'Clone surveys to the hub...';
    this.content = 'Are you sure you want to clone this surveys';
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '350px',
      data: {
        title: this.title,
        content: this.content,
        label: 'Clone',
        class: 'btn-green'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const surveyToCopy = [];
        this.closedSurveys.filter((survey) => survey.isChecked).forEach((survey) => {
          surveyToCopy.push({'survey': survey.id});
        });
        this.restclientService.recycleSurvey(surveyToCopy).pipe(
          finalize(() => {
            this.getFinishedSurveys();
          })
        ).subscribe((data) => {
          this.notification.showSnackBar('Copied successfully', 'dismiss', 3000);
        });
      }
    });
  }


  RecycleandIntiateSurveys() {
    this.title = 'Initiate survey now...';
    this.content = 'Are you sure you want to initiate this surveys';
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '350px',
      data: {
        title: this.title,
        content: this.content,
        label: 'Initiate',
        class: 'btn-green'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const surveyToIntiate = [];
        this.closedSurveys.filter((survey) => survey.isChecked).forEach((survey) => {
          surveyToIntiate.push({'survey': survey.id, is_initiate: 1});
        });
        this.restclientService
          .recycleSurvey(surveyToIntiate)
          .subscribe(
            res => {
              this.getFinishedSurveys();
              this.getActiveSurveys();
              this.notification.showSnackBar('Survey Intiated successfully', 'dismiss', 300);
            });
      }
    });
  }

}
