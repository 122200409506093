import {Component, OnInit} from '@angular/core';
import {UserService} from '../../user.service';
import {RestclientService} from '../../restclient.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Location} from '@angular/common';
import {RespondentService} from '../../respondents/respondent.service';
import {User} from '../../auth/interfaces/user';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.sass']
})
export class EditProfileComponent implements OnInit {

  processing: boolean;
  user_id: string;
  user: User;
  profileForm: FormGroup;
  showdError: boolean;
  name = 'Angular';
  model: any = {
  };

  constructor(private userService: UserService,
              private restclientService: RestclientService,
              private respondents: RespondentService,
              private location: Location) {
    this.processing = false;
    this.user = <User>{};
    this.user.password = null;
    if (this.userService.getIsLoggedIn()) {
      this.user_id = this.userService.user_id;
      this.restclientService.getUser(this.user_id).subscribe(
        res => {
          this.user = res.data;
          this.editUser(this.user);
        },
        err => {

        }
      );
    }

    this.profileForm = new FormGroup({
      first_name: new FormControl('', Validators.required),
      last_name: new FormControl('', Validators.required),
      phone_number: new FormControl('', Validators.required),
      email: new FormControl('', Validators.required),
      smsHeader: new FormControl('')
    });
  }

  ngOnInit() {
  }

  editUser(user: User): void {
    this.profileForm.get('first_name').setValue(user.first_name);
    this.profileForm.get('last_name').setValue(user.last_name);
    this.profileForm.get('phone_number').setValue(user.phone_number);
    this.profileForm.get('email').setValue(user.email);
    this.profileForm.get('smsHeader').setValue(user.sms_header);
  }

  updateUser(user: User): void {
    console.log(user)
    this.processing = true;
    this.user.first_name = user.first_name;
    this.user.last_name = user.last_name;
    this.user.phone_number = user.phone_number;
    this.user.email = user.email;
    this.user.password = user.password;
    this.user.sms_header = user.smsHeader;
    this.respondents.updateUser(this.user)
      .subscribe(
        (res) => {
          this.processing = false;
          this.location.back();
        },
        err => {
          this.processing = false;
          this.showdError = true;

          if (err.statusText === 'Unauthorized' || err.status === false || err.message === 'Ooops something went Wrong.') {
            this.userService.setIsLoggedOut();
          }
        }
      );
  }

}
