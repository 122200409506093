import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {SurveyResponse} from './interfaces/survey-response';
import {SurveysResponse} from './interfaces/surveys-response';
import {QuestionResponse} from '../questions/interfaces/question-response';

@Injectable({
  providedIn: 'root'
})
export class SurveyService {

  constructor(private httpClient: HttpClient) {

  }


  surveys(page = 1, size = 10, search = ''): Observable<any> {
    return this.httpClient.get<SurveysResponse>(`${environment.baseUrl}/surveys/survey?page_size=${size}&page=${page}`);
  }

  surveyWithoutPagination(search: any): Observable<any> {
    return this.httpClient.get<SurveysResponse>(`${environment.baseUrl}/surveys/survey?search=${search}&ispage=1&is_finished=1`);
  }

  inactiveSurveys(page = 1, size = 10, search = ''): Observable<any> {
    const params  = {'page': page, size: size}
    if (search !== '') {
      params['search'] = search
    }
    const query = new  HttpParams().set('page', page.toString()).set('size', size.toString()).set('in_active', '1').set('search', search)

    return this.httpClient.get<SurveysResponse>(`${environment.baseUrl}/surveys/survey`,{ params: query });
  }

  survey(id: string): Observable<any> {
    return this.httpClient.get<SurveysResponse>(`${environment.baseUrl}/surveys/survey/${id}`);
  }

  bills(id: string): Observable<any> {
    return this.httpClient.get(`${environment.baseUrl}/surveys/bills?survey=${id}`);
  }

  updateSurvey(post: any): Observable<any> {
    return this.httpClient.put<SurveyResponse>(`${environment.baseUrl}/surveys/survey/${post.id}`, post);
  }

  deleteSurvey(id: string): Observable<any> {
    return this.httpClient.delete<SurveyResponse>(`${environment.baseUrl}/surveys/survey/${id}`);
  }

  deleteSurveys(data: any[]): Observable<any> {
    return this.httpClient.post<SurveyResponse>(`${environment.baseUrl}/surveys/survey/delete`, data);
  }

  createSurvey(post: any): Observable<any> {
    return this.httpClient.post<SurveyResponse>(`${environment.baseUrl}/surveys/survey`, post);
  }

  recycleSurvey(post: any | any[] ): Observable<any> {
    return this.httpClient.post<SurveysResponse>(`${environment.baseUrl}/surveys/recycle/`, post);
  }

  allSurveys(page = 1, size = 10, search = ''): Observable<any> {
    return this.httpClient.get<SurveysResponse>(`${environment.baseUrl}/surveys/survey?is_finished=1&page_size=${size}&page=${page}&is_all=1&search=${search}`);
  }

  activateSurvey(post: any[] | any): Observable<any> {
    return this.httpClient.post<QuestionResponse>(`${environment.baseUrl}/surveys/survey/send`, post);
  }

}
