import {Component, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.sass']
})
export class ContactUsComponent implements OnInit {

  contactForm: FormControl;
  disabledSubmitButton: Boolean;
  onSubmit: Boolean;

  constructor() {
  }

  ngOnInit() {
  }

}
