import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PasswordCheckService {

  constructor(private httpClient: HttpClient) {
  }


  check(password): Observable<any> | any {
    const data = {'password': password};
    return this.httpClient.post(`${environment.baseUrl}/users/check`, data);
  }
}
