import {Component, OnInit} from '@angular/core';
import {Respondent} from '../../respondents/interfaces/respondent';
import {ChartyData} from '../../charty/charty-data';
import {RestclientService} from '../../restclient.service';
import {ActivatedRoute, Router} from '@angular/router';
import {GroupService} from '../group.service';
import {UserService} from '../../user.service';

@Component({
  selector: 'app-groups-stats',
  templateUrl: './groups-stats.component.html',
  styleUrls: ['./groups-stats.component.sass']
})
export class GroupsStatsComponent implements OnInit {

  public respondents: Respondent[];
  private term: string;

  public RespondenseData: ChartyData;
  public RespondensePerChoice: ChartyData;
  public RespondentsPerQuestion: ChartyData;
  public ResponsesByLocation: ChartyData;
  public ResponsesByGender: ChartyData;
  public ResponsesByAgeGroup: ChartyData;

  data_set: boolean;
  rbl_data_set: boolean;
  rbg_data_set: boolean;
  rbag_data_set: boolean;
  show_all: boolean;
  all_toggle = 'All';
  private id: string;
  groupStats: any;

  constructor(private restclientService: RestclientService,
              private router: Router,
              private groupService: GroupService,
              private userService: UserService,
              private activatedRoute: ActivatedRoute) {
    this.respondents = [];
    this.term = '';

    this.RespondenseData = <ChartyData>{};
    this.RespondentsPerQuestion = <ChartyData>{};
    this.RespondensePerChoice = <ChartyData>{};
    this.ResponsesByLocation = <ChartyData>{};
    this.ResponsesByGender = <ChartyData>{};
    this.ResponsesByAgeGroup = <ChartyData>{};
  }

  ngOnInit() {
    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    this.getGroupStats(this.id);
  }

  private getGroupStats(id: string) {
    this.groupService.getGroupStats(id)
      .subscribe(res => {
          this.groupStats = res.data.results;
          this.initializeDataVisualization();
        },
        err => {
          console.log(err);
          if (err.statusText === 'Unauthorized' || err.status === false || err.message === 'Ooops something went Wrong.' || err.message === 'Ooops something went Wrong.') {
            this.userService.setIsLoggedOut();
          }
        });
  }

  toggleAll() {
    if (this.show_all) {
      this.show_all = false;
      this.all_toggle = 'All';
    } else {
      this.show_all = true;
      this.all_toggle = 'Main';
    }
  }

  toggleView(view: string) {
    this.show_all = false;
    this.all_toggle = 'All';
    if (view === 'by_location') {
      this.RespondenseData = this.ResponsesByLocation;
    } else if (view === 'by_gender') {
      this.RespondenseData = this.ResponsesByGender;
    } else if (view === 'by_age_group') {
      this.RespondenseData = this.ResponsesByAgeGroup;
    } else {
      this.RespondenseData = this.RespondensePerChoice;
    }
  }

  private initializeDataVisualization() {
    this.respondents = this.groupStats.users;
    const count_by_gender = this.groupStats.count_by_gender;
    const count_by_location = this.groupStats.count_by_location;
    const count_by_age_group = this.groupStats.count_by_age_group;
    const gender_labels = [];
    const gender_counts = [];
    const labels_location = [];
    const counts_location = [];
    const labels_age_group = [];
    const counts_age_group = [];

    for (const c of count_by_gender) {
      gender_labels.push(c.gender);
      gender_counts.push(c.total);
    }

    this.ResponsesByGender.title = 'By Title';
    this.ResponsesByGender.type = 'pie';
    this.ResponsesByGender.labels = gender_labels;
    this.ResponsesByGender.counts = gender_counts;

    for (const c of count_by_location) {
      labels_location.push(c.location);
      counts_location.push(c.total);
    }

    this.ResponsesByLocation.title = 'By Location';
    this.ResponsesByLocation.type = 'pie';
    this.ResponsesByLocation.labels = labels_location;
    this.ResponsesByLocation.counts = counts_location;

    this.RespondenseData = this.ResponsesByLocation;
    this.data_set = true;

    for (const c of count_by_age_group) {
      labels_age_group.push(c.age_group__name);
      counts_age_group.push(c.total);
    }

    this.ResponsesByAgeGroup.title = 'By Location';
    this.ResponsesByAgeGroup.type = 'pie';
    this.ResponsesByAgeGroup.labels = labels_age_group;
    this.ResponsesByAgeGroup.counts = counts_age_group;

    this.rbl_data_set = true;
    this.rbg_data_set = true;
    this.rbag_data_set = true;
  }
}
